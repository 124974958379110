<template>
  <HelloHome />
</template>

<script>
  import HelloHome from '../components/HelloHome'

  export default {
    components: {
      HelloHome,
    },
  }
</script>
