import Vue from 'vue';
import Vuetify from 'vuetify/lib/framework';
import es from 'vuetify/es5/locale/es';

Vue.use(Vuetify);

export default new Vuetify({
  theme: {
    options: {
      customProperties: true,
    },
    themes: {
      light: {
        primary: '#00ACC1',
        secondary: '#2B2B2B',
        accent: '#752B7E',
        error: '#D32F2F',
        info: '#2D91C2',
        success: '#368D39',
        warning: '#D47F00',
        internal: '#1E2C6D'
      },
      dark: {
        primary: '#29B6F6',
        secondary: '#555555',
        accent: '#966BAC',
        error: '#FF3333',
        info: '#3F81C8',
        success: '#4CAF50',
        warning: '#FFA100',
        internal: '#33713E'
      }
    },
  },
  lang: {
    locales: { es },
    current: 'es',
  },
  icons: {
    iconfont: 'md',
  },
});
