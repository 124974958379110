<template>
  <v-container fluid fill-height>
    <v-layout alig-start>
      <v-flex>
        <!-- Inicio de barra de alerta -->
        <v-snackbar v-model="alerta.snackbar" :color="alerta.color" @click="alerta.snackbar = false">{{ alerta.text }}
        </v-snackbar>
        <!-- Fin inicio de barra de alerta -->

        <!-- Listado de bien -->
        <v-data-table :loading="loading" :headers="cabBienes" :items="bienes" :items-per-page="15" class="elevation-3"
          dense v-if="verNuevaTraslado == 0">
          <template v-slot:top>
            <v-toolbar flat>
              <v-toolbar-title>TRASLADO DE BIENES</v-toolbar-title>
              <v-divider class="mx-4 hidden-sm-and-down" inset vertical></v-divider>
              <v-spacer />
              <template>
                <v-btn small dark class="ml-2" color="primary" @click="verNuevaTraslado = 1">Nuevo Traslado</v-btn>
              </template>
              <!-- Fin Modal activar, desactivar area -->
            </v-toolbar>
          </template>
          <!-- Opciones editar, eliminar area -->
          <template v-slot:item.codbien="{ item }">
            <template v-if='item.codbien.length == 12'>
              <span>{{ item.codbien.substr(0, 8) }}</span><strong>{{ item.codbien.substr(8, 12) }}</strong>
            </template>
            <template v-else-if="item.codbien.length == 8">
              <span>{{ item.codbienint + "-" + item.codinterno }}</span>
            </template>
          </template>

          <template v-slot:item.fec_act="{ item }">
            <span>{{ item.fec_act | formatearFecha }}</span>
          </template>
          <!-- Fin Opciones editar, eliminar area -->
        </v-data-table>
        <!-- Fin Listado de bien -->

        <!-- Ventana crear traslado -->
        <v-card v-if="verNuevaTraslado == 1" class="elevation-3">
          <v-toolbar dark dense class="primary">
            <v-toolbar-title>Nuevo Traslado de Bienes</v-toolbar-title>
            <v-spacer />
            <v-btn icon darkx @click="crearPDF" :loading="desabilitar" :disabled="desabilitar || !isValid">
              <v-icon>feed</v-icon>
            </v-btn>
            <v-btn icon darkx @click="crearPDFSimi" :loading="desabilitar" :disabled="desabilitar || !isValid">
              <v-icon>article</v-icon>
            </v-btn>
            <v-btn icon darkx @click="guardar" :loading="desabilitar" :disabled="desabilitar || !isValid">
              <v-icon>save</v-icon>
            </v-btn>
            <v-btn icon dark @click="close">
              <v-icon>close</v-icon>
            </v-btn>
          </v-toolbar>

          <v-card-text grid-list-sm v-if="verNuevaTraslado">
            <v-form v-model="isValid">
              <v-row class="mt-2">
                <v-col cols="11" sm="5">
                  <v-autocomplete dense @keyup="selectAsignacionOrigen()" :search-input.sync="buscar_personal_origen"
                    v-model.trim="idasignacion_origen" :items="asignacionOrigenArray"
                    label="Selecciona Origen: Personal | Local > Area - Oficina" :rules="[v => !!v || 'Requerido']"
                    @change="detalle_origen = [], detalle_destino = [], detalleAsignacionOri(idasignacion_origen), listarOrigen()"
                    clearable hide-details></v-autocomplete>
                </v-col>
                <v-col cols="1" sm="1">
                  <v-icon color="primary" @click="agregarDetalleDestinoTodo"
                    :disabled="detalle_origen.length >= 1 ? false : true || desabilitar || !isValid">
                    keyboard_double_arrow_right</v-icon>
                </v-col>
                <v-col cols="1" sm="1">
                  <v-icon color="primary" @click="agregarDetalleOrigenTodo"
                    :disabled="detalle_destino.length >= 1 ? false : true">keyboard_double_arrow_left</v-icon>
                </v-col>
                <v-col cols="11" sm="5">
                  <v-autocomplete dense @keyup="selectAsignacionDestino()" :search-input.sync="buscar_personal_destino"
                    v-model.trim="idasignacion_destino" :items="asignacionDestinoArray"
                    label="Selecciona Destino: Personal | Local > Area - Oficina" :rules="[v => !!v || 'Requerido']"
                    @change="detalle_destino = [], detalleAsignacionDest(idasignacion_destino), listarOrigen()"
                    :disabled="detalle_origen.length > 0 ? false : true" clearable hide-details></v-autocomplete>
                </v-col>

                <v-col cols="12" md="6">
                  <v-toolbar-title class="mb-3 primary--text">Origen</v-toolbar-title>
                  <v-data-table :loading="loading" :headers="cabeceraOrigen" :items="detalle_origen" dense
                    class="elevation-1">
                    <template v-slot:item.num="{ item }">
                      <td>{{ detalle_origen.indexOf(item) + 1 }}</td>
                    </template>
                    <template v-slot:item.agregar="{ item }">
                      <v-icon @click="agregarDetalleDestino(item), eliminarItemDetalle(detalle_origen, item)"
                        color="primary">keyboard_arrow_right</v-icon>
                    </template>

                    <template slot="no-data">
                      <h3>No hay bienes con detalle origen.</h3>
                    </template>
                  </v-data-table>
                </v-col>
                <v-col cols="12" md="6">
                  <v-toolbar-title class="mb-3 primary--text">Destino</v-toolbar-title>
                  <v-data-table :headers="cabeceraDestino" :items="detalle_destino" dense class="elevation-1">
                    <template v-slot:item.num="{ item }">
                      <td>{{ detalle_destino.indexOf(item) + 1 }}</td>
                    </template>
                    <template v-slot:item.quitar="{ item }">
                      <v-icon @click="agregarDetalleOrigen(item), eliminarItemDetalle(detalle_destino, item)"
                        color="primary">keyboard_arrow_left</v-icon>
                    </template>

                    <template slot="no-data">
                      <h3>No hay bienes agregados al detalle destino.</h3>
                    </template>
                  </v-data-table>
                </v-col>
                <!-- Mensaje error bien -->
                <v-col cols="12" v-if="mensajeBien != ''">
                  <v-alert text dense type="error">{{ mensajeBien }}</v-alert>
                </v-col>
                <!-- Mensaje error bien -->
              </v-row>
            </v-form>
          </v-card-text>
        </v-card>
        <!-- Fin Ventana crear traslado -->
      </v-flex>
    </v-layout>
  </v-container>
</template>

<script>
import axios from "axios";
import { mapState } from "vuex";
import jsPDF from "jspdf";
import moment from "moment";

export default {
  data() {
    return {
      bienes: [],
      detalle_origen: [],
      detalle_destino: [],
      // Cabecera Bienes
      cabBienes: [
        { text: "C. Patrimonial", value: "codbien", sortable: false },
        { text: "Tipo Bien", value: "tbdescripcion", sortable: false },
        { text: "Local", value: "nomlocales", sortable: false },
        { text: "Area", value: "nomareas", sortable: false },
        { text: "Oficina", value: "nomoficinas", sortable: false },
        { text: "Personal", value: "pnombres", sortable: false },
        { text: "Fec. Actualizado", value: "fec_act", sortable: false },
      ],

      // Cabecera detalle de repuestos para compra
      cabeceraOrigen: [
        { text: "#", value: "num", sortable: false },
        { text: "Código", align: "center", value: "codbien", sortable: false },
        { text: "Descripción", value: "tbdescripcion", sortable: false },
        { text: "", value: "agregar", align: "center", sortable: false },
      ],
      cabeceraDestino: [
        { text: "", value: "quitar", align: "center", sortable: false },
        { text: "#", value: "num", sortable: false },
        { text: "Código", align: "center", value: "codbien", sortable: false },
        { text: "Descripción", value: "tbdescripcion", sortable: false },
      ],

      loading: false,
      buscar: "",
      verNuevaTraslado: 0,

      desabilitarIdDestino: true,

      mensajeBien: "",

      idasignacion_origen: null, // asignacionOrigenArray
      asignacionOrigenArray: [],
      buscar_personal_origen: "",

      idasignacion_destino: null, // asignacionDestinoArray
      asignacionDestinoArray: [],
      buscar_personal_destino: "",

      desabilitar: false,
      isValid: true,
      alerta: { snackbar: false, color: "", text: "" },

      onompersonal: "",
      onrodocpersonal: "",
      onomlocal: "",
      onomarea: "",
      onomoficina: "",
      odireclocal: "",
      omodalidad: "",
      dmodalidad: "",

      dnompersonal: "",
      dnrodocpersonal: "",
      dnomlocal: "",
      dnomarea: "",
      dnomoficina: "",
      ddireclocal: "",

      fechaActual: moment().startOf("day").format("YYYY-MM-DD"),

      Rules: {
        unespacio: (v) =>
          (v || "").indexOf("  ") < 0 || "No se permite mas de 1 espacio",
        sinespacio: (v) =>
          (v || "").indexOf(" ") < 0 || "No se permite espacios",
      },
    };
  },

  computed: {
    ...mapState(["usuarioState", "empresaState"]),
  },

  watch: {},

  created() {
    this.listar();
  },

  methods: {
    formatFecha(date) {
      if (!date) return null;
      const [year, month, day] = date.split("-");
      return `${day}/${month}/${year}`;
    },

    // Seleccionar motivos
    detalleAsignacionOri(valor) {
      let me = this;
      if (valor != null) {
        const selectId = me.asignacionOrigenArray.find(
          (elemento) => elemento.value === valor
        );
        me.onompersonal = selectId.nom;
        me.onrodocpersonal = selectId.nrodoc;
        me.onomlocal = selectId.local;
        me.onomarea = selectId.area;
        me.onomoficina = selectId.oficina;
        me.odireclocal = selectId.direccion;
        me.omodalidad = selectId.modcont;
      }
    },

    detalleAsignacionDest(valor) {
      let me = this;
      if (valor != null) {
        const selectId = me.asignacionDestinoArray.find(
          (elemento) => elemento.value === valor
        );
        me.dnompersonal = selectId.nom;
        me.dnrodocpersonal = selectId.nrodoc;
        me.dnomlocal = selectId.local;
        me.dnomarea = selectId.area;
        me.dnomoficina = selectId.oficina;
        me.ddireclocal = selectId.direccion;
        me.dmodalidad = selectId.modcont;
      }
    },

    selectAsignacionOrigen(id) {
      let me = this;
      var origenArray = [];

      // Select asignacion para area de la empresa
      axios
        .get("api/Asignaciones/SelectAsignacionFiltro", {
          params: {
            idempresa: me.usuarioState.idempresa,
            buscar: me.buscar_personal_origen == "" ? "-" : me.buscar_personal_origen == null ? "-" : me.buscar_personal_origen,
          },
        })

        .then(function (response) {
          origenArray = response.data;
          origenArray.map(function (x) {
            me.asignacionOrigenArray.push({
              text: x.nompersonal + " | " + x.nomlocal.substring(0, 20) + " > " + x.nomarea.substring(0, 20) + " - " + x.nomoficina.substring(0, 20),
              nom: x.nompersonal,
              nrodoc: x.nrodocpersonal,
              local: x.nomlocal,
              area: x.nomarea,
              oficina: x.nomoficina,
              direccion: x.direclocal,
              modcont: x.modcont,
              value: x.idasignacion,
            });
          });
        })
        .catch((err) => {
          me.alerta.snackbar = true;
          me.alerta.color = "error";
          me.alerta.text = "Hubo un error";
          me.loading = false;
          //console.log(err.response);
        });
    },

    selectAsignacionDestino(id) {
      let me = this;
      var DestinoArray = [];

      // Select asignacion para area de la empresa
      axios
        .get("api/Asignaciones/SelectAsignacionFiltro", {
          params: {
            idempresa: me.usuarioState.idempresa,
            buscar: me.buscar_personal_destino == "" ? "-" : me.buscar_personal_destino == null ? "-" : me.buscar_personal_destino
          },
        })

        .then(function (response) {
          DestinoArray = response.data;
          DestinoArray.map(function (x) {
            me.asignacionDestinoArray.push({
              text: x.nompersonal + " | " + x.nomlocal.substring(0, 20) + " > " + x.nomarea.substring(0, 20) + " - " + x.nomoficina.substring(0, 20),
              nom: x.nompersonal,
              nrodoc: x.nrodocpersonal,
              local: x.nomlocal,
              area: x.nomarea,
              oficina: x.nomoficina,
              direccion: x.direclocal,
              modcont: x.modcont,
              value: x.idasignacion,
            });
          });
        })
        .catch((err) => {
          //console.log(err.response);
        });
    },

    /* Listar bienes */
    listar() {
      let me = this;
      me.loading = true;

      axios
        .get("api/Bienes/ListarBienEmpresaFiltro", {
          params: {
            idempresa: me.usuarioState.idempresa,
            buscar: me.buscar,
          },
        })

        .then(function (response) {
          me.bienes = response.data;
          me.loading = false;
        })
        .catch((err) => {
          //console.log(err.response);
        });
    },

    /* Listar bienes activos */
    listarOrigen() {
      let me = this;
      me.loading = true;
      me.desabilitar = true;
      me.desabilitarIdDestino = false;

      axios
        .get("api/Bienes/ListarTrasladoBienOrigenFiltro", {
          params: {
            idempresa: me.usuarioState.idempresa,
            idasignacion: me.idasignacion_origen
          },
        })
        .then(function (response) {
          me.detalle_origen = response.data;
          me.desabilitar = false;
          me.loading = false;
          //console.log(me.detalle_origen);
        })
        .catch((err) => {
          me.desabilitar = false;
          me.loading = false;
          me.alerta.snackbar = true;
          me.alerta.color = "error";
          me.alerta.text = "Ocurrio un error";
          //console.log(err.response);
        });
    },
    /* Fin Listar bienes activos */

    guardar() {
      let me = this;
      if (me.detalle_destino.length <= 0) {
        me.alerta.snackbar = true;
        me.alerta.color = "error";
        return (me.alerta.text = "Ingrese al menos un bien al detalle destino.");
      }
      me.desabilitar = true;

      //Código para actualizar todo el detalle del destino
      axios
        .put("api/bienes/TrasladoBien", {
          idusuario: parseInt(me.usuarioState.idusuario),
          idasignacion_destino: me.idasignacion_destino,
          // Detalle destino
          detalle_destino: me.detalle_destino,
        })
        .then(function (response) {
          me.desabilitar = false;
          me.alerta.snackbar = true;
          me.alerta.color = "success";
          me.alerta.text = response.data;
          me.close();
          me.limpiar();
          me.listar();
        })
        .catch(function (error) {
          me.desabilitar = false;
          me.mensajeBien = error.response.data;
          me.limpiarAlertaError();
        });
    },

    // Agregar a detalle a destino
    agregarDetalleDestino(data = []) {
      let me = this;

      if (me.encuentra(data["idbien"])) {
        me.alerta.snackbar = true;
        me.alerta.color = "error";
        me.alerta.text = "El bien ya existe.";
      } else {
        me.detalle_destino.push({
          idbien: data["idbien"],
          codbien: data["codbien"],
          tbdescripcion: data["tbdescripcion"],
          marca: data["marca"],
          modelo: data["modelo"],
          color: data["color"],
          serie: data["serie"],
          otras_caract: data["otras_caract"],
          flg_estado: data["flg_estado"],
          observacion: data["observacion"],
        });
      }
    },

    // Agregar a detalle a origen
    agregarDetalleOrigen(data = []) {
      let me = this;

      me.detalle_origen.push({
        idbien: data["idbien"],
        codbien: data["codbien"],
        tbdescripcion: data["tbdescripcion"],
        marca: data["marca"],
        modelo: data["modelo"],
        color: data["color"],
        serie: data["serie"],
        otras_caract: data["otras_caract"],
        flg_estado: data["flg_estado"],
        observacion: data["observacion"],
      });
    },

    // Busca en detalle de pedido si existe el repuesto
    encuentra(id) {
      var sw = 0;
      for (var i = 0; i < this.detalle_destino.length; i++) {
        if (this.detalle_destino[i].idbien == id) {
          sw = 1;
        }
      }
      return sw;
    },

    // Eliminar item detalle destino
    eliminarItemDetalle(arr, item) {
      var i = arr.indexOf(item);
      if (i !== -1) {
        arr.splice(i, 1);
      }
    },

    // Todo
    // Agregar a detalle a destino
    agregarDetalleDestinoTodo(data = []) {
      let me = this;
      me.detalle_destino = Object.values(me.detalle_origen);
      me.detalle_origen = [];
    },
    agregarDetalleOrigenTodo(data = []) {
      let me = this;
      me.detalle_origen = Object.values(me.detalle_destino);
      me.detalle_destino = [];
    },

    /* Cerrar ventana traslado bien */
    close() {
      this.verNuevaTraslado = 0;
      this.limpiar();
    },
    /* Fin Cerrar ventana traslado bien */

    /* Limpiar traslado bien item */
    limpiar() {
      this.idasignacion = "";
      this.idasignacion_origen = null;
      this.idasignacion_destino = null;
      this.asignacionOrigenArray = [];
      this.detalle_origen = [];
      this.detalle_destino = [];
      this.omodalidad = "";
      this.dmodalidad = "";
    },
    /* Fin Limpiar traslado bien item */

    /* Limpiar mensaje bien */
    limpiarAlertaError() {
      setTimeout(() => {
        this.mensajeBien = "";
      }, 3000);
    },
    /* Fin Limpiar mensaje bien */

    /* Crear PDF asignacion */
    crearPDF() {
      let me = this;

      var columns = [
        { header: "Nº", dataKey: "item" },
        { header: "C.PATRIMONIAL", dataKey: "codbien" },
        { header: "DENOMINACIÓN", dataKey: "tbdescripcion" },
        { header: "MARCA", dataKey: "marca" },
        { header: "MODELO", dataKey: "modelo" },
        //{ header: "TIPO", dataKey: "tipo" },
        { header: "COLOR", dataKey: "color" },
        { header: "SERIE", dataKey: "serie" },
        { header: "OTROS", dataKey: "otras_caract" },
        //{ header: "DIMENSION", dataKey: "dimension" },
        { header: "ESTADO", dataKey: "estado" },
        { header: "OBSERVACIONES", dataKey: "observacion" },
      ];
      var body = [];

      for (var i = 0; i < me.detalle_destino.length; i++) {
        var f_estado = "";
        for (var e = 0; e < me.cm.flg_estados.length; e++) {
          f_estado = f_estado + (me.detalle_destino[i].flg_estado == me.cm.flg_estados[e].value ? me.cm.flg_estados[e].text : "");
        }

        body.push({
          item: i + 1,
          codbien: me.detalle_destino[i].codbien,
          tbdescripcion: me.detalle_destino[i].tbdescripcion,
          marca: me.detalle_destino[i].marca,
          modelo: me.detalle_destino[i].modelo,
          //tipo: me.detalle_destino[i].tipo,
          color: me.detalle_destino[i].color,
          serie: me.detalle_destino[i].serie,
          otras_caract: me.detalle_destino[i].otras_caract,
          //dimension: me.detalle_destino[i].dimension,
          estado: f_estado,
          observacion: me.detalle_destino[i].observacion,
        });
      }

      // Agregar Encabezado: titulo y logo
      const addHeaders = (doc) => {
        const pageCount = doc.internal.getNumberOfPages();
        for (i = 1; i <= pageCount; i++) {
          doc.setPage(i);
          doc.addImage(imgData, "JPEG", 20, 6, 11, 14); // Logo
          doc.setFont("helvetica", "bold");
          doc.setFontSize(11);
          doc.text("ORDEN DE SALIDA, REINGRESO Y DESPLAZAMIENTO INTERNO DE BIENES MUEBLES PATRIMONIALES", doc.internal.pageSize.width / 2, 15, { align: "center" });
          // doc.text(
          //   "Mobiliario, Equipo y Maquinaria",
          //   doc.internal.pageSize.width / 2,
          //   15,
          //   {
          //     align: "center",
          //   }
          // );
          doc.setFontSize(10);
          doc.text(me.empresaState.nombre, doc.internal.pageSize.width / 2, 19, { align: "center" });

          doc.setFont("helvetica", "bold");
          doc.setFontSize(9);
          doc.rect(40, 22, 15, 5)// y,x, ancho, alto
          doc.text("TIPO", 41, 26);
          doc.rect(40, 28, 15, 5);
          doc.text("MOTIVO", 41, 32);

          doc.setFont("helvetica", "normal");
          // TIPO
          doc.rect(55, 22, 15, 5);
          doc.text("SALIDA", 56, 26);
          doc.rect(70, 22, 5, 5);
          doc.rect(75, 22, 22, 5);
          doc.text("REINGRESO", 76, 26);
          doc.rect(97, 22, 5, 5);
          doc.rect(102, 22, 46, 5);
          doc.text("DESPLAZAMIENTO INTERNO", 103, 26);
          doc.rect(148, 22, 5, 5);
          // MOTIVO
          doc.rect(55, 28, 29, 5);
          doc.text("MANTENIMIENTO", 56, 32);
          doc.rect(84, 28, 5, 5);
          doc.rect(89, 28, 42, 5);
          doc.text("COMISION DE SERVICIOS", 90, 32);
          doc.rect(131, 28, 5, 5);
          doc.rect(136, 28, 46, 5);
          doc.text("CAPACITACION Y/O EVENTO", 137, 32);
          doc.rect(182, 28, 5, 5);
          doc.text("DOCUMENTO QUE AUTORIZA EL TRASLADO: _______________________________________________", 40, 38);

          doc.setFont("helvetica", "bold");
          doc.setFontSize(10);
          doc.text("DATOS DEL RESPONSABLE", 40, 45);
          doc.text("DESTINO", 160, 45);

          doc.setFont("helvetica", "normal");
          doc.setFontSize(9);
          //ORIGEN
          doc.text("Nombres y apellidos: " + me.onompersonal, 40, 49);
          //doc.text("Nº DNI: " + me.onrodocpersonal, 160, 30);
          doc.text("Correo electrónico: ", 40, 54);
          doc.text("Local: " + me.onomlocal, 40, 59);
          doc.text("Area: " + me.onomarea, 40, 64);
          doc.text("Oficina: " + me.onomoficina, 40, 69);

          //DESTINO
          //doc.text("Dirección: " + me.odireclocal, 40, 50);
          doc.text("Nombres y apellidos: " + me.dnompersonal, 160, 49);
          //doc.text("Nº DNI: " + me.dnrodocpersonal, 160, 30);
          doc.text("Correo electrónico: ", 160, 54);
          doc.text("Local: " + me.dnomlocal, 160, 59);
          doc.text("Area: " + me.dnomarea, 160, 64);
          doc.text("Oficina: " + me.dnomoficina, 160, 69);

          doc.setFontSize(6);
          doc.text("Pag. " + i + " de " + pageCount, 250, 13);
          doc.setFont("helvetica", "bold");
          doc.text("Fecha: " + me.formatFecha(me.fechaActual), 250, 17);
        }
      };
      // Agregar pie de pagina: numeración
      const addFooters = (doc) => {
        const pageCount = doc.internal.getNumberOfPages();
        doc.setFont("helvetica", "normal");
        doc.setFontSize(8);
        for (i = 1; i <= pageCount; i++) {
          doc.setPage(i);
          doc.setDrawColor(0, 0, 0);
          //doc.setLineWidth(0.5)
          doc.line(30, 196, 90, 196);
          doc.text(44, 200, "Responsable del traslado");

          doc.line(120, 196, 180, 196);
          doc.text(128, 200, "Vº Bº Oficina de Control Patrimonial");

          doc.line(210, 196, 270, 196);
          doc.text(230, 200, "Vº Bº de Seguridad");
        }
      };

      var imgData = "data:image/png;base64," + this.empresaState.logo;
      var doc = new jsPDF("l", "mm");

      doc.autoTable({
        theme: "grid",
        columns,
        body,
        margin: { top: 72, bottom: 40, left: 10, right: 10 },
        styles: { cellPadding: 1, overflow: "ellipsize", cellWidth: "wrap" },
        headStyles: {
          fillColor: [46, 79, 120],
          fontSize: 6,
          halign: "center",
          fontStyle: "bold",
        },
        bodyStyles: { textColor: 0, fontSize: 6 },
        columnStyles: {
          item: { cellWidth: 8, halign: "center" },
          codbien: { cellWidth: 20, halign: "center" },
          tbdescripcion: { cellWidth: 44 },
          marca: { cellWidth: 35 },
          modelo: { cellWidth: 30 },
          //tipo: { cellWidth: 28 },
          color: { cellWidth: 28 },
          serie: { cellWidth: 28 },
          otras_caract: { cellWidth: 33 },
          //dimension: { cellWidth: 28 },
          estado: { cellWidth: 16 },
          observacion: { cellWidth: 35 },
        },
      });
      // 515.28 ancho hoja
      addHeaders(doc);
      addFooters(doc);
      // Exportar
      doc.save("Traslado.pdf");
      // me.stringPdf = doc.output("datauristring");
      // me.blobPdf = doc.output("blob");

      // // Para visualizar todas las paginas del pdf
      // this.srcPdf = pdf.createLoadingTask(this.stringPdf);
      // this.srcPdf.promise.then((pdf) => {
      //   this.numPages = pdf.numPages;
      // });
    },

    crearPDFSimi() {
      let me = this;

      var columns = [
        { header: "Nº", dataKey: "item" },
        { header: "C.PATRIMONIAL", dataKey: "codbien" },
        { header: "DENOMINACIÓN", dataKey: "tbdescripcion" },
        { header: "MARCA", dataKey: "marca" },
        { header: "MODELO", dataKey: "modelo" },
        //{ header: "TIPO", dataKey: "tipo" },
        { header: "COLOR", dataKey: "color" },
        { header: "SERIE", dataKey: "serie" },
        { header: "OTROS", dataKey: "otras_caract" },
        //{ header: "DIMENSION", dataKey: "dimension" },
        { header: "ESTADO", dataKey: "estado" },
        { header: "OBSERVACIONES", dataKey: "observacion" },
      ];
      var body = [];

      var o_modalidad = "";
      for (var e = 0; e < me.cm.selectModconts.length; e++) {
        o_modalidad = o_modalidad + (me.cm.selectModconts[e].value == me.omodalidad ? me.cm.selectModconts[e].text : "");
      }
      var d_modalidad = "";
      for (var e = 0; e < me.cm.selectModconts.length; e++) {
        d_modalidad = d_modalidad + (me.cm.selectModconts[e].value == me.dmodalidad ? me.cm.selectModconts[e].text : "");
      }


      for (var i = 0; i < me.detalle_destino.length; i++) {
        var f_estado = "";
        for (var e = 0; e < me.cm.flg_estados.length; e++) {
          f_estado = f_estado + (me.detalle_destino[i].flg_estado == me.cm.flg_estados[e].value ? me.cm.flg_estados[e].text : "");
        }

        body.push({
          item: i + 1,
          codbien: me.detalle_destino[i].codbien,
          tbdescripcion: me.detalle_destino[i].tbdescripcion,
          marca: me.detalle_destino[i].marca,
          modelo: me.detalle_destino[i].modelo,
          //tipo: me.detalle_destino[i].tipo,
          color: me.detalle_destino[i].color,
          serie: me.detalle_destino[i].serie,
          otras_caract: me.detalle_destino[i].otras_caract,
          //dimension: me.detalle_destino[i].dimension,
          estado: f_estado,
          observacion: me.detalle_destino[i].observacion,
        });
      }

      // Agregar Encabezado: titulo y logo
      const addHeaders = (doc) => {
        const pageCount = doc.internal.getNumberOfPages();
        for (i = 1; i <= pageCount; i++) {
          doc.setPage(i);
          doc.addImage(imgData, "JPEG", 20, 6, 11, 14); // Logo
          doc.setFont("helvetica", "bold");
          doc.setFontSize(11);
          doc.text("FORMATO DE DESPLAZAMIENTO INTERNO Y EXTERNO DE BIENES PATRIMONIALES", doc.internal.pageSize.width / 2, 15, { align: "center", });
          // doc.text(
          //   "Mobiliario, Equipo y Maquinaria",
          //   doc.internal.pageSize.width / 2,
          //   15,
          //   {
          //     align: "center",
          //   }
          // );
          doc.setFontSize(10);
          doc.text(me.empresaState.nombre, doc.internal.pageSize.width / 2, 19, { align: "center" });
          doc.text("DATOS DEL TRANSFERENTE", 40, 25);
          doc.text("DATOS DEL RECEPTOR", 160, 25);

          doc.setFont("helvetica", "normal");
          doc.setFontSize(9);
          doc.text("NOMBRE: " + me.onompersonal, 40, 30);
          //doc.text("Nº DNI: " + me.onrodocpersonal, 160, 30);
          //doc.text("Correo electrónico: ", 40, 35);
          doc.text("LOCAL: " + me.onomlocal, 40, 35);
          doc.text("AREA: " + me.onomarea, 40, 40);
          doc.text("OFICINA: " + me.onomoficina, 40, 45);
          doc.text("MODALIDAD: " + o_modalidad, 40, 50);
          //doc.text("Dirección: " + me.odireclocal, 40, 50);
          doc.text("NOMBRE: " + me.dnompersonal, 160, 30);
          //doc.text("Nº DNI: " + me.dnrodocpersonal, 160, 30);
          //doc.text("Correo electrónico: ", 160, 35);
          doc.text("LOCAL: " + me.dnomlocal, 160, 35);
          doc.text("AREA: " + me.dnomarea, 160, 40);
          doc.text("OFICINA: " + me.dnomoficina, 160, 45);
          doc.text("MODALIDAD: " + d_modalidad, 160, 50);

          doc.setFontSize(6);
          doc.text("Pag. " + i + " de " + pageCount, 250, 13);
          doc.setFont("helvetica", "bold");
          doc.text("Fecha: " + me.formatFecha(me.fechaActual), 250, 17);
        }
      };
      // Agregar pie de pagina: numeración
      const addFooters = (doc) => {
        const pageCount = doc.internal.getNumberOfPages();
        doc.setFont("helvetica", "normal");
        doc.setFontSize(8);
        for (i = 1; i <= pageCount; i++) {
          doc.setPage(i);
          doc.setDrawColor(0, 0, 0);
          //doc.setLineWidth(0.5)
          doc.setDrawColor(0, 0, 0);
          var pageWidth = doc.internal.pageSize.width; // Ancho de la hoja
          var line = 188;
          var linlong = 50;
          var linMargin = 10;

          var startL1 = (pageWidth - linlong * 4 - linMargin * 3) / 2;
          var startL2 = startL1 + linlong + linMargin;
          var startL3 = startL2 + linlong + linMargin;
          var startL4 = startL3 + linlong + linMargin;

          doc.line(startL1, line, startL1 + linlong, line);
          doc.text(startL1 + 6, 192, "FIRMA DEL TRANSFERENTE");

          doc.line(startL2, line, startL2 + linlong, line);
          doc.text(startL2 + 11, 192, "FIRMA DEL RECEPTOR");

          doc.line(startL3, line, startL3 + linlong, line);
          doc.text(startL3 + 10, 192, "CONTROL PATRIMONIAL");

          doc.line(startL4, line, startL4 + linlong, line);
          doc.text(startL4 + 11, 192, "REGISTRO SIME OCP");

          doc.setFontSize(7);
          doc.text(10, 196, "NOTA: El trabajador es responsable directo de la existencia, permanencia, conservacion y buen uso de cada uno de los bienes descritos. Por lo que se recomienda tomar las providencias del caso para evitar perdida, sustracciones, deterioros, etc.");
          doc.text(10, 199, "que luego podria ser considerado como descuido o negligencia. Cualquier movimiento dentro o fuera de la entidad, debera ser comunicado al encargado de Control Patrimonial, bajo responsabilidad.");
        }
      };

      var imgData = "data:image/png;base64," + this.empresaState.logo;
      var doc = new jsPDF("l", "mm");

      doc.autoTable({
        theme: "grid",
        columns,
        body,
        margin: { top: 53, bottom: 33, left: 10, right: 10 },
        styles: { cellPadding: 1, overflow: "ellipsize", cellWidth: "wrap" },
        headStyles: {
          fillColor: [46, 79, 120],
          fontSize: 6,
          halign: "center",
          fontStyle: "bold",
        },
        bodyStyles: { textColor: 0, fontSize: 6 },
        columnStyles: {
          item: { cellWidth: 8, halign: "center" },
          codbien: { cellWidth: 20, halign: "center" },
          tbdescripcion: { cellWidth: 44 },
          marca: { cellWidth: 35 },
          modelo: { cellWidth: 30 },
          //tipo: { cellWidth: 28 },
          color: { cellWidth: 28 },
          serie: { cellWidth: 28 },
          otras_caract: { cellWidth: 33 },
          //dimension: { cellWidth: 28 },
          estado: { cellWidth: 16 },
          observacion: { cellWidth: 35 },
        },
      });
      // 515.28 ancho hoja
      addHeaders(doc);
      addFooters(doc);
      // Exportar
      doc.save("Traslado.pdf");
      // me.stringPdf = doc.output("datauristring");
      // me.blobPdf = doc.output("blob");

      // // Para visualizar todas las paginas del pdf
      // this.srcPdf = pdf.createLoadingTask(this.stringPdf);
      // this.srcPdf.promise.then((pdf) => {
      //   this.numPages = pdf.numPages;
      // });
    },
    /* Crear PDF asignacion */
  },
};
</script>
