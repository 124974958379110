<template>
  <v-container fluid fill-height>
    <v-layout alig-start>
      <v-flex>
        <!-- Inicio de barra de alerta -->
        <v-snackbar v-model="alerta.snackbar" :color="alerta.color" @click="alerta.snackbar = false">{{ alerta.text }}
        </v-snackbar>
        <!-- Fin inicio de barra de alerta -->

        <v-data-table :loading="loading"
          :headers="usuarioState.rol == 'Verificador' ? encabezadoUsuario : encabezadoAdminSuper" :items="reporteUsuario"
          :items-per-page="15" class="elevation-3" dense>
          <template v-slot:top>
            <v-form v-model="isValid">
              <v-toolbar flat>
                <!-- Formulario busqueda de venta usuario -->
                <v-toolbar-title class="hidden-sm-and-down">REPORTE USUARIO</v-toolbar-title>
                <v-divider class="mx-4 hidden-sm-and-down" inset vertical></v-divider>
                <v-spacer />
                <v-autocomplete dense @keyup="selectUsuario()" :search-input.sync="buscar_usuario" v-model="idusuario"
                  :items="usuarios" label="Nombres" :rules="[v => !!v || 'Nombres es requerido']" clearable hide-details
                  class="mr-1" @change="reporteUsuario = []" :disabled="!desabilitarBuscar"></v-autocomplete>
                <v-spacer />
                <v-menu v-model="menuFecha" :close-on-content-click="false" :nudge-right="40"
                  transition="scale-transition" offset-y min-width="290px">
                  <template v-slot:activator="{ on }">
                    <v-text-field dense v-model="dateRangeText" readonly v-on="on" hide-details class="mr-1">
                    </v-text-field>
                  </template>
                  <v-date-picker v-model="dates" @input="dates.length == 1 ? menuFecha = true : menuFecha = false" range>
                  </v-date-picker>
                </v-menu>
                <v-spacer />
                <v-btn x-small fab color="info" @click="listar" :loading="desabilitar" :disabled="desabilitar || !isValid"
                  class="mr-1">
                  <v-icon>search</v-icon>
                </v-btn>
                <v-btn fab x-small color="success" @click="crearXLSX()" :disabled="desabilitar || !isValid">
                  <v-icon>description</v-icon>
                </v-btn>
                <!-- Fin Formulario Busqueda de venta usuario-->
              </v-toolbar>
            </v-form>
          </template>
          <!-- Formatear detalle -->
          <template v-slot:item.codbien="{ item }">
            <template v-if='item.codbien.length == 12'>
              <span>{{ item.codbien.substr(0, 8) }}</span><strong>{{ item.codbien.substr(8, 12) }}</strong>
            </template>
            <template v-else-if="item.codbien.length == 8">
              <span>{{ item.codbienint + "-" + item.codinterno }}</span>
            </template>
          </template>

          <template v-slot:item.fec_act="{ item }">
            <span>{{ item.fec_act | formatearFecha }}</span>
          </template>
          <!-- Fin Formatear detalle -->
        </v-data-table>
      </v-flex>
    </v-layout>
  </v-container>
</template>

<script>
import axios from "axios";
import { mapState } from "vuex"; //Importamos para llamar a los elementos
import moment from "moment";
import XLSX from "xlsx";

export default {
  data() {
    return {
      reporteUsuario: [],
      dialog: false,

      encabezadoUsuario: [
        { text: "C. Patrimonial", value: "codbien", sortable: false },
        { text: "C/Int.", value: "codinterno", sortable: false },
        { text: "Local", value: "nomlocales", sortable: false },
        { text: "Area", value: "nomareas", sortable: false },
        { text: "Tipo Bien", value: "tbdescripcion", sortable: false },
        { text: "Personal", value: "pnombres", sortable: false },
        { text: "FEC. ACTUALIZADO", value: "fec_act", sortable: false },
      ],

      encabezadoAdminSuper: [
        { text: "C. Patrimonial", value: "codbien", sortable: false },
        { text: "C/Int.", value: "codinterno", sortable: false },
        { text: "Tipo Bien", value: "tbdescripcion", sortable: false },
        { text: "Local", value: "nomlocales", sortable: false },
        { text: "Area", value: "nomareas", sortable: false },
        { text: "Oficina", value: "nomoficinas", sortable: false },
        { text: "Personal", value: "pnombres", sortable: false },
        { text: "FEC. ACTUALIZADO", value: "fec_act", sortable: false },
      ],
      buscar_usuario: "",
      usuarios: [],

      dates: [
        moment().startOf("day").format("YYYY-MM-DD"),
        moment().endOf("day").format("YYYY-MM-DD"),
      ],
      menuFecha: false,

      desabilitarBuscar: true,
      desabilitar: false,
      isValid: true,
      loading: false,
      alerta: { snackbar: false, color: "", text: "" },

      idempresa: "",
    };
  },

  computed: {
    ...mapState(["usuarioState", "empresaState"]), // Llamamos a state

    dateRangeText() {
      let fechaIni;
      let fechaFin;
      for (var i = 0; i < this.dates.length; i++) {
        fechaIni = this.formatFecha(this.dates[0]);
        fechaFin = this.formatFecha(this.dates[1]);
      }
      let arrayFechas = [fechaIni, fechaFin];
      return arrayFechas.join(" ~ ");
    },
  },

  created() {
    this.idusuario = parseInt(this.usuarioState.idusuario);
    this.idempresa = parseInt(this.usuarioState.idempresa);
    this.selectUsuario(parseInt(this.usuarioState.idusuario));
    this.listar();
  },

  methods: {
    formatFecha(date) {
      if (!date) return null;
      const [year, month, day] = date.split("-");
      return `${day}/${month}/${year}`;
    },

    /* Listar venta usuario */

    listar() {
      let me = this;
      me.desabilitar = true;
      me.loading = true;

      axios
        .get("api/Bienes/ReporteBienUsuario", {
          params: {
            idempresa: parseInt(me.idempresa),
            idusuario: parseInt(me.idusuario),
            fechaInicio: me.dates[0],
            fechaFin: me.dates[1] + " 23:59:59",
          },
        })
        .then(function (response) {
          me.reporteUsuario = response.data;
          me.desabilitar = false;
          me.loading = false;
          //console.log(me.reporteUsuario);
        })
        .catch((err) => {
          me.desabilitar = false;
          me.loading = false;
          me.alerta.snackbar = true;
          me.alerta.color = "error";
          me.alerta.text = "Ocurrio un error";
          //console.log(err.response);
        });
    },
    /* Fin Listar venta usuario */

    // Seleccionar usuario por busqueda
    selectUsuario(id) {
      let me = this;
      var usuariosArray = [];

      // Habilitamos buscar usuario por nombre para Administrador y Supervisor  !desabilitarBuscar
      if (this.usuarioState.rol == "Verificador") {
        me.desabilitarBuscar = false;
      }

      let url = "";
      if (id) {
        url = "api/Usuarios/SelectUsuario/" + id;
      } else {
        url = "api/Usuarios/SelectUsuarioFiltro?buscar=" + (me.buscar_usuario == "" ? "-" : me.buscar_usuario);
      }

      axios.get(url)
        .then(function (response) {
          usuariosArray = response.data;
          usuariosArray.map(function (x) {
            me.usuarios.push({
              text: x.nombres + " " + x.apellidos,
              value: x.idusuario,
            });
          });
        })
        .catch(function (error) {
          //console.log(error);
        });
    },

    /* Crear excel stock */
    crearXLSX() {
      // On Click Excel download button
      var rows = [];

      for (var i = 0; i < this.reporteUsuario.length; i++) {
        var t_cta = "";
        var f_condicion = "";
        var f_estado = "";
        var f_situacion = "";

        for (var t = 0; t < this.cm.tipo_ctas.length; t++) {
          t_cta = t_cta + (this.reporteUsuario[i].tipo_cta == this.cm.tipo_ctas[t].value ? this.cm.tipo_ctas[t].text : "");
        }

        for (var c = 0; c < this.cm.flg_conds.length; c++) {
          f_condicion = f_condicion + (this.reporteUsuario[i].flg_cond == this.cm.flg_conds[c].value ? this.cm.flg_conds[c].text : "");
        }

        for (var e = 0; e < this.cm.flg_estados.length; e++) {
          f_estado = f_estado + (this.reporteUsuario[i].flg_estado == this.cm.flg_estados[e].value ? this.cm.flg_estados[e].text : "");
        }

        for (var e = 0; e < this.cm.flg_situaciones.length; e++) {
          f_situacion = f_situacion + (this.reporteUsuario[i].situacion == this.cm.flg_situaciones[e].value ? this.cm.flg_situaciones[e].text : "");
        }

        rows.push({
          "Código patrimonial": this.reporteUsuario[i].codbien.length == 12 ? this.reporteUsuario[i].codbien : this.reporteUsuario[i].codbienint + "-" + this.reporteUsuario[i].codinterno,
          Denominación: this.reporteUsuario[i].tbdescripcion,
          Locales: this.reporteUsuario[i].nomlocales,
          Areas: this.reporteUsuario[i].nomareas,
          Personal: this.reporteUsuario[i].pnombres,
          Marca: this.reporteUsuario[i].marca,
          Modelo: this.reporteUsuario[i].modelo,
          Tipo: this.reporteUsuario[i].tipo,
          Color: this.reporteUsuario[i].color,
          Serie: this.reporteUsuario[i].serie,
          Dimensión: this.reporteUsuario[i].dimension,
          Motor: this.reporteUsuario[i].nummotor,
          Chasis: this.reporteUsuario[i].numchasis,
          Placa: this.reporteUsuario[i].placa,
          Año: this.reporteUsuario[i].ano,

          Carroceria: this.reporteUsuario[i].carroceria,
          "Nro Ejes": this.reporteUsuario[i].nro_ejes,
          Combustible: this.reporteUsuario[i].combustible,
          Transmision: this.reporteUsuario[i].transmision,
          Cilindrada: this.reporteUsuario[i].cilindrada,
          Kilometraje: this.reporteUsuario[i].kilometraje,
          "Tarjeta Propiedad": this.reporteUsuario[i].tarjeta_propiedad,

          Asegurado: this.reporteUsuario[i].asegurado == "S" ? "SI" : "NO",
          "Fec. Reg.": moment(this.reporteUsuario[i].fec_reg).format("DD/MM/YYYY"),
          "Doc. Alta": this.reporteUsuario[i].doc_alta,
          "Tipo Cta": "USO " + this.usuarioState.tipoempresa + " - " + t_cta,
          Cuenta: this.reporteUsuario[i].cuenta,
          "Detalle Cta": this.reporteUsuario[i].cdenominacion,
          "Valor Adq.": this.reporteUsuario[i].valordq,
          "Valor Nto": this.reporteUsuario[i].valorlibro,
          Motor: this.reporteUsuario[i].nummotor,
          "Otras Caracteristicas": this.reporteUsuario[i].otras_caract,
          Observación: this.reporteUsuario[i].observacion,
          Situación: f_situacion,
          Condición: f_condicion,
          Estado: f_estado,
          "Fecha Actualizado": moment(this.reporteUsuario[i].fec_act).format("DD/MM/YYYY HH:mm"),

          USUARIO: this.reporteUsuario[i].nickname,
          SEGUIMIENTO: this.reporteUsuario[i].seguimiento == true ? "Verificado" : "No verficado",
          "Código interno": this.reporteUsuario[i].codinterno
        });
      }
      // export json to Worksheet of Excel
      // only array possible
      var Detalle = XLSX.utils.json_to_sheet(rows);

      // A workbook is the name given to an Excel file
      var wb = XLSX.utils.book_new(); // make Workbook of Excel

      // add Worksheet to Workbook
      // Workbook contains one or more worksheets
      XLSX.utils.book_append_sheet(wb, Detalle, "Reporte Usuario"); // sheetAName is name of Worksheet

      // export Excel file
      XLSX.writeFile(wb, "Reporte Usuario.xlsx"); // name of the file is 'book.xlsx'
    },
    /* Fin Crear excel stock */
  },
};
</script>

