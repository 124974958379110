<template>
  <v-layout justify-center>
    <v-flex>
      <template>
        <v-img
          :src="require('../assets/web/otro.jpg')"
          height="180"
          class="white--text align-center"
        >
          <v-card-title align="center">
            <div class="text-h3 font-weight-light">NOSOTROS</div>
          </v-card-title>
        </v-img>
      </template>

      <v-item-group class="mt-10 mb-10">
        <v-container >
          <v-row class="mb-10">
            <!-- Imagen 1 -->
            <v-card class="mx-auto" max-width="344">
              <v-img :src="require('../assets/web/nosotros2.jpeg')" height="200px"></v-img>
            </v-card>
            <!-- Servicio 1 -->
            <v-card flat class="mx-auto" max-width="688">
              <v-card-title class="text-h4 justify-center">What is Lorem Ipsum</v-card-title>
              <v-card-subtitle>
                Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard
                dummy text ever since the 1500s, when an unknown printer took a galley.
              </v-card-subtitle>
            </v-card>
          </v-row>
          <v-row>
            <!-- Servicio 2 -->
            <v-card flat class="mx-auto" max-width="688">
              <v-card-title class="text-h4 justify-center">What is Lorem Ipsum</v-card-title>
              <v-card-subtitle>
                It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout. The point
                of using Lorem Ipsum is that it has a more-or-less normal distribution of letters, as opposed to using 'Content here, content here', making
                it look like readable English. Many desktop publishing packages and web page editors now use Lorem Ipsum as their default model text, and a search for.
              </v-card-subtitle>
            </v-card>
            <!-- Imagen 2 -->
            <v-card class="mx-auto" max-width="344">
              <v-img :src="require('../assets/web/nosotros.jpg')" height="200px"></v-img>
            </v-card>
          </v-row>
        </v-container>
      </v-item-group>
    </v-flex>
  </v-layout>
</template>

<script>
export default {
  data() {
    return {
      items: [
        {
          src: require("../assets/web/carousel.jpg"),
        },
        {
          src: require("../assets/web/carousel2.jpeg"),
        },
        {
          src: require("../assets/web/carousel3.jpeg"),
        },
      ],

      slides: [
        "Inventario de Bienes",
        "Bien Patrimonial Estatal",
        "Bien Patrimonial Privado",
      ],

      avances: "",
      avanceBienes: [],
      totalBienes: [],

      avancesUsuarios: "",
      usuarios: [],
      numerobienes: [],
    };
  },
};
</script>
