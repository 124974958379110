<template>
  <HelloServicios />
</template>

<script>
  import HelloServicios from '../components/HelloServicios'

  export default {
    components: {
      HelloServicios,
    },
  }
</script>