const flg_asegurados = [
    { text: "SI", value: "S" },
    { text: "NO", value: "N" },
]

const tipo_ctas = [
    { text: "ACTIVO FIJO", value: "A" },
    { text: "CUENTA DE ORDEN", value: "O" },
]

const flg_situaciones = [
    { text: "USO", value: "U" },
    { text: "DESUSO", value: "D" },
    { text: "FALTANTE", value: "F" },
]

const flg_conds = [
    { text: "COMPRA", value: "C" },
    { text: "ACEPTACIÓN DE DONACIÓN", value: "D" },
    { text: "FABRICA DE BIENES", value: "F" },
    { text: "PERMUTA DE BIENES", value: "P" },
    { text: "REPOSICIÓN DE BIENES", value: "R" },
    { text: "SANEAMIENTO DE BIENES SOBRANTES", value: "S" },
    { text: "DISPOSICIÓN LEGAL", value: "L" },
    { text: "MANDATO JUDICIAL O ARBITRAL", value: "J" },
    { text: "OPINION FAVORABLE DE ALTA OTORGADO", value: "X" },
]

const flg_estados = [
    { text: "NUEVO", value: "N" },
    { text: "BUENO", value: "B" },
    { text: "REGULAR", value: "R" },
    { text: "MALO", value: "M" },
    { text: "RAEE", value: "X" },
    { text: "CHATARRA", value: "Y" },
]

const flg_causales = [
    { text: "ESTADO DE EXCEDENCIA", value: "E" },
    { text: "OBSOLESCENCIA TÉCNICA", value: "O" },
    { text: "MANTENIMIENTO O REPARACIÓN ONEROSA", value: "M" },
    { text: "REEMBOLSO", value: "R" },
    { text: "REPOSICIÓN", value: "C" },
    { text: "PERDIDA", value: "P" },
    { text: "HURTO", value: "H" },
    { text: "ROBO", value: "B" },
    { text: "RESIDUOS DE APARATOS ELECTRICOS Y ELECTRONICOS - RAEE", value: "X" },
    { text: "ESTADO DE CHATARRA", value: "Y" },
    { text: "SINIESTRO", value: "Z" },
    { text: "DESTRUCCIÓN ACCIDENTAL", value: "D" },
    //{ text: "CASO DE SEMOVIENTE", value: "V" },
    { text: "SANEAMIENTO DE BIEN FALTANTE", value: "F" },
    { text: "OPINION FAVORABLE DE BAJA OTORGADO POR LA SBN", value: "W" },
]

const selectModconts = [
    { text: "CAS", value: "C" },
    { text: "FUNCIONARIO", value: "F" },
    { text: "PRACTICANTE", value: "P" },
    { text: "SECIGRISTA", value: "S" },
    { text: "SERVIDOR PUBLICO", value: "E" },
    { text: "TERCERO", value: "T" },
]

function removeComa(valor) {
    return valor.replace(/\,/g, "");
}

function formatFecha(date) {
    if (!date) return null;
    const [year, month, day] = date.split("-");
    return `${day}/${month}/${year}`;
  }

export default {
    flg_asegurados,
    tipo_ctas,
    flg_situaciones,
    flg_conds,
    flg_estados,
    flg_causales,
    selectModconts,
    removeComa,
    formatFecha
}