<template>
  <v-container fluid fill-height>
    <v-layout align-start>
      <v-flex>
        <!-- Inicio de barra de alerta -->
        <v-snackbar
          v-model="alerta.snackbar"
          :color="alerta.color"
          @click="alerta.snackbar = false"
          right
        >{{ alerta.text }}</v-snackbar>
        <!-- Fin inicio de barra de alerta -->

        <!-- Listado de Orden -->
        <v-data-table
          :loading="loading"
          :headers="cabeceraListarOrdenes"
          :items="ordenes"
          :items-per-page="15"
          class="elevation-3"
          dense
          v-if="verNuevoPedido == 0"
        >
          <template v-slot:top>
            <!-- Titulo y busqueda orden -->
            <v-toolbar flat v-if="verNuevoPedido == 0">
              <v-toolbar-title class="hidden-sm-and-down">ORDEN DE COMPRAS</v-toolbar-title>
              <v-divider class="mx-2 hidden-sm-and-down" inset vertical></v-divider>
              <v-btn
                fab
                x-small
                color="success"
                @click="crearXLSX()"
                :loading="desabilitar"
                :disabled="desabilitar || !isValid"
              >
                <v-icon>description</v-icon>
              </v-btn>
              <v-btn
                fab
                x-small
                color="success"
                @click="listarOD()"
                :loading="desabilitar"
                :disabled="desabilitar || !isValid"
                class="ml-1"
              >
                <v-icon>list_alt</v-icon>
              </v-btn>
              <v-spacer />
              <!-- Formulario busqueda de orden -->
              <v-text-field
                dense
                class="mr-1"
                v-model.trim="buscar_orden"
                label="Búsqueda"
                @keyup="listar()"
                single-line
                hide-details
              ></v-text-field>
              <v-spacer />
              <v-menu
                v-model="menuFecha"
                :close-on-content-click="!menuFecha"
                transition="scale-transition"
                offset-y
                min-width="290px"
                :close-on-click="dates.length == 2 ? true :  false"
              >
                <template v-slot:activator="{ on }">
                  <v-text-field
                    dense
                    v-model="dateRangeText"
                    readonly
                    v-on="on"
                    hide-details
                    append-icon="update"
                    @click:append="listar()"
                  ></v-text-field>
                </template>
                <v-date-picker
                  v-model="dates"
                  @input="dates.length == 2 ? menuFecha = false : menuFecha = true"
                  @change="listar()"
                  range
                ></v-date-picker>
              </v-menu>
              <v-spacer />
              <!-- Fin Formulario busqueda de orden -->
              <v-btn
                @click="mostrarNuevaOrden"
                color="primary"
                class="ma-2"
                small
                v-if="esAlmacenero"
              >Nuevo</v-btn>
            </v-toolbar>
            <!-- Fin Titulo y busqueda orden -->
          </template>
          <!-- Opciones editar, eliminar orden -->

          <template v-slot:item.importe_total="{ item }">
            <div class="text-right">
              <span>{{ item.importe_total | formatearNumero }}</span>
            </div>
          </template>

          <template v-slot:item.fecha_orden="{ item }">
            <span>{{ item.fecha_orden | formatearFecha }}</span>
          </template>

          <template v-slot:item.estado="{ item }" v-if="esAdministrador || esSuperadministrador">
            <template v-if="item.estado=='Aceptado'">
              <v-btn text small color="success" @click="activarDesactivarMostrar(2,item)">Aceptado</v-btn>
            </template>
            <template v-else>
              <v-btn text small color="warning" @click="activarDesactivarMostrar(1,item)">Pendiente</v-btn>
            </template>
          </template>

          <template v-slot:item.opciones="{ item }">
            <template>
              <v-icon color="purple darken-2" @click="mostrarOrden(item)">print</v-icon>
            </template>
            <template v-if="esAlmacenero && item.estado=='Pendiente'">
              <v-icon color="primary" @click="verDetalles(item)">edit</v-icon>
            </template>
            <template v-if="item.estado == 'Pendiente' && esAlmacenero">
              <v-icon color="error" @click="deleteItemPedido(item)">delete_forever</v-icon>
            </template>
          </template>
          <!-- Opciones Fin editar, eliminar orden -->
        </v-data-table>
        <!--Fin Listado de Orden -->

        <!-- Ventana crear y modificar orden -->
        <v-card v-if="verNuevoPedido == 1" class="elevation-3">
          <v-toolbar dark dense class="primary">
            <v-toolbar-title>{{ formTitle }}</v-toolbar-title>
          </v-toolbar>
          <v-card-text grid-list-sm v-if="verNuevoPedido">
            <v-form v-model="isValid" class="mt-3">
              <v-row>
                <v-col cols="6" md="2" sm="3">
                  <v-menu
                    v-model="menuFecha"
                    :close-on-content-click="false"
                    :nudge-right="40"
                    transition="scale-transition"
                    offset-y
                    min-width="290px"
                  >
                    <template v-slot:activator="{ on }">
                      <v-text-field
                        dense
                        v-model="fecha_orden_formateada"
                        label="Fecha Orden"
                        :rules="[v => !!v || 'Fecha Requerido']"
                        readonly
                        v-on="on"
                        prepend-inner-icon="event"
                        hide-details
                      ></v-text-field>
                    </template>
                    <v-date-picker
                      v-model="fecha_orden"
                      @input="menuFecha = false"
                      @change="fechaseleccionada(fecha_orden)"
                    ></v-date-picker>
                  </v-menu>
                </v-col>
                <v-col cols="6" md="2" sm="3">
                  <v-text-field
                    dense
                    v-model="nro_orden"
                    label="Nro Orden"
                    maxlength="6"
                    v-mask-number
                    hide-details
                  ></v-text-field>
                </v-col>
                <v-col cols="6" md="4" sm="6">
                  <v-text-field
                    dense
                    v-model.trim="descripcion"
                    label="Descripción"
                    :rules="[v => !!v || 'Requerido', v => (!!v && v.length >= 3 && v.length <= 254) || 'Descripción debe tener min. 3 y max. 254 caracteres', Rules.unespacio]"
                    maxlength="254"
                    v-uppercase
                    hide-details
                  ></v-text-field>
                </v-col>
                <v-col cols="6" md="4" sm="6">
                  <v-text-field
                    dense
                    v-model.trim="proveedor"
                    label="Proveedor"
                    :rules="[v => !!v || 'Requerido', v => (!!v && v.length >= 3 && v.length <= 254) || 'Proveedor debe tener min. 3 y max. 254 caracteres', Rules.unespacio]"
                    maxlength="254"
                    v-uppercase
                    hide-details
                  ></v-text-field>
                </v-col>
                <v-col cols="6" md="4" sm="6">
                  <v-text-field
                    dense
                    v-model.trim="observacion"
                    label="Observación"
                    :rules="[v => v.length <= 254 || 'Observación no debe exceder los 254 caracteres', Rules.unespacio]"
                    maxlength="254"
                    v-uppercase
                    hide-details
                  ></v-text-field>
                </v-col>

                <v-col cols="6" md="2" sm="3">
                  <v-menu
                    v-model="menuFechaIni"
                    :close-on-content-click="false"
                    :nudge-right="40"
                    transition="scale-transition"
                    offset-y
                    min-width="290px"
                  >
                    <template v-slot:activator="{ on }">
                      <v-text-field
                        dense
                        v-model="fecha_inicio_formateada"
                        label="Fecha Inicio"
                        :rules="[v => !!v || 'Fecha Requerido']"
                        readonly
                        v-on="on"
                        prepend-inner-icon="event"
                        hide-details
                      ></v-text-field>
                    </template>
                    <v-date-picker
                      v-model="fecha_inicio"
                      @input="menuFechaIni = false"
                      @change="fechaseleccionadaIni(fecha_inicio)"
                    ></v-date-picker>
                  </v-menu>
                </v-col>
                <v-col cols="6" md="2" sm="3">
                  <v-menu
                    v-model="menuFechaFin"
                    :close-on-content-click="false"
                    :nudge-right="40"
                    transition="scale-transition"
                    offset-y
                    min-width="290px"
                  >
                    <template v-slot:activator="{ on }">
                      <v-text-field
                        dense
                        v-model="fecha_fin_formateada"
                        label="Fecha Fin"
                        :rules="[v => !!v || 'Fecha Requerido']"
                        readonly
                        v-on="on"
                        prepend-inner-icon="event"
                        hide-details
                      ></v-text-field>
                    </template>
                    <v-date-picker
                      v-model="fecha_fin"
                      @input="menuFechaFin = false"
                      @change="fechaseleccionadaFin(fecha_fin)"
                    ></v-date-picker>
                  </v-menu>
                </v-col>

                <v-col cols="12">
                  <v-data-table
                    :headers="cabeceraDetalleOrdenes"
                    :items="detalles"
                    disable-pagination
                    hide-default-footer
                    dense
                    class="elevation-3"
                  >
                    <template v-slot:item.num="{ item }">
                      <td>{{detalles.indexOf(item) + 1}}</td>
                    </template>
                    <template v-slot:item.borrar="{ item }">
                      <v-icon
                        color="error"
                        class="mr-2"
                        small
                        @click="eliminarDetallePedido(detalles, item)"
                      >delete_forever</v-icon>
                    </template>
                    <template v-slot:item.cantidad="{ item }">
                      <v-text-field
                        dense
                        type="text"
                        v-model="item.cantidad"
                        :rules="[v => !!v || 'Requerido']"
                        maxlength="10"
                        class="center-input"
                        v-mask-number
                        hide-details
                      ></v-text-field>
                    </template>
                    <template v-slot:item.unidad_medida="{ item }">
                      <v-text-field
                        dense
                        type="text"
                        v-model.trim="item.unidad_medida"
                        :rules="[v => !!v || 'Requerido']"
                        maxlength="10"
                        v-uppercase
                        hide-details
                      ></v-text-field>
                    </template>
                    <template v-slot:item.descripcion="{ item }">
                      <v-text-field
                        dense
                        type="text"
                        v-model.trim="item.descripcion"
                        :rules="[v => !!v || 'Requerido']"
                        maxlength="100"
                        v-uppercase
                        hide-details
                      ></v-text-field>
                    </template>

                    <template v-slot:item.precio_unitario="{ item }">
                      <v-text-field
                        dense
                        type="text"
                        v-model="item.precio_unitario"
                        :rules="[v => !!v || 'Requerido']"
                        maxlength="12"
                        v-mask-decimal.us="2"
                        hide-details
                      ></v-text-field>
                    </template>

                    <template v-slot:item.subtotal="{ item }">
                      <div class="text-right">
                        <span>{{ redondear(parseInt(item.cantidad == '' ? 0 : item.cantidad) * parseFloat(item.precio_unitario == '' ? 0 : cm.removeComa(item.precio_unitario))) | formatearNumero }}</span>
                      </div>
                    </template>
                  </v-data-table>
                  <v-container>
                    <v-row no-gutters align="end" justify="end">
                      <v-col cols="2">
                        <v-btn color="primary" @click="agregarDetalle()" x-small>
                          <v-icon left>check</v-icon>Agregar Item
                        </v-btn>
                      </v-col>
                      <v-col cols="10" align="end">
                        <strong>Total:</strong>
                        <strong>{{ calcularTotal| formatearNumero }}</strong>
                      </v-col>
                    </v-row>
                  </v-container>
                  <!-- Mensaje error bien -->
                  <v-col cols="12" v-if="mensaje !=''">
                    <v-alert text dense type="error">{{mensaje}}</v-alert>
                  </v-col>
                  <!-- Mensaje error bien -->
                </v-col>
              </v-row>
            </v-form>
          </v-card-text>
          <v-card-actions class="py-0 pb-4 justify-center">
            <v-btn color="error" @click="ocultarNuevo()" small>
              <v-icon left>close</v-icon>Cancelar
            </v-btn>
            <v-btn
              color="primary"
              @click="guardarOrden()"
              :loading="desabilitar"
              :disabled="desabilitar || !isValid"
              small
            >
              <v-icon left>check</v-icon>Guardar
            </v-btn>
          </v-card-actions>
        </v-card>
        <!-- Fin Ventana crear y modificar orden -->

        <!-- Modal activar, desactivar local -->
        <v-dialog v-model="adModal" max-width="300">
          <v-card>
            <v-card-title class="headline justify-center" v-if="adAccion==1">¿Orden Aceptada?</v-card-title>
            <v-card-title class="headline justify-center" v-if="adAccion==2">¿Orden Pendiente?</v-card-title>
            <v-card-text>
              Estás a punto de cambiar el estado de la orden {{ adNombre }}
              <span
                v-if="adAccion==1"
              >a aceptado.</span>
              <span v-if="adAccion==2">a pendiente.</span>
            </v-card-text>
            <v-card-actions class="py-0 pb-4 justify-center">
              <v-btn text small color="error" @click="activarDesactivarCerrar">Cancelar</v-btn>
              <v-btn v-if="adAccion==1" text small color="success" @click="activar">Aceptado</v-btn>
              <v-btn v-if="adAccion==2" text small color="warning" @click="desactivar">Pendiente</v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
        <!-- Fin Modal activar, desactivar local -->

        <!-- Mostrar Modal Comprobante impresión -->
        <v-dialog v-model="ordenModal" max-width="600px" scrollable>
          <v-card>
            <v-toolbar dark dense class="primary">
              <v-toolbar-title>Orden Nº {{nro_orden}}</v-toolbar-title>
              <v-spacer />
              <v-btn icon @click="printExternal()">
                <v-icon>print</v-icon>
              </v-btn>
              <v-btn icon @click="savePdf()">
                <v-icon>download</v-icon>
              </v-btn>
              <v-btn icon @click="ocultarComprobante()">
                <v-icon>close</v-icon>
              </v-btn>
            </v-toolbar>
            <v-card-text style="height: 400px;" class="px-0">
              <pdf v-for="i in numPages" :key="i" :src="srcPdf" :page="i"></pdf>
            </v-card-text>
          </v-card>
        </v-dialog>
        <!-- Fin Mostrar Modal Comprobante impresión -->
      </v-flex>
    </v-layout>
  </v-container>
</template>

<script>
import axios from "axios";
import moment from "moment"; // Formatear Fechas
import numeral from "numeral"; // Formater numeros
import jsPDF from "jspdf";
import pdf from "vue-pdf"; // Visor de pdf
import XLSX from "xlsx";

import { mapGetters, mapState } from "vuex"; //Importamos para llamar a los elementos usuario y acceso al usuario segun rol

export default {
  components: {
    pdf,
  },
  data() {
    return {
      ordenes: [],
      detalles: [], // Detalles de orden
      detalle_ordenes: [],
      dordenes: [],

      // Cabecera listado de ordenes
      cabeceraListarOrdenes: [
        { text: "CdOd", value: "idorden", sortable: false },
        { text: "Nro Orden", value: "nro_orden", sortable: false },
        { text: "Proveedor", value: "proveedor", sortable: false },
        { text: "Descripcion", value: "descripcion", sortable: false },
        { text: "Importe", value: "importe_total", sortable: false },
        { text: "Fecha Orden", value: "fecha_orden", sortable: false },
        { text: "Usuario", value: "usuario", sortable: false },
        { text: "Estado", value: "estado", sortable: false },
        { text: "Opción", value: "opciones", sortable: false },
      ],

      // Cabecera detalle de orden
      cabeceraDetalleOrdenes: [
        { text: "", value: "borrar", sortable: false },
        { text: "#", value: "num", sortable: false },
        { text: "Descripción", value: "descripcion", sortable: false },
        { text: "U/M", value: "unidad_medida", sortable: false },
        { text: "Cantidad", value: "cantidad", sortable: false },
        { text: "Precio Unit.", value: "precio_unitario", sortable: false },
        { text: "SubTotal", value: "subtotal", sortable: false },
      ],

      buscar_orden: "",

      productos: [],

      idorden: "",
      idempresa: "",
      nro_orden: "",
      proveedor: "",
      descripcion: "",
      observacion: "",
      importe_total: 0,
      fecha_orden: "",
      fecha_inicio: "",
      fecha_fin: "",

      fecha_orden_formateada: "",
      fecha_inicio_formateada: "",
      fecha_fin_formateada: "",

      verNuevoPedido: 0, // Para mostrar ventana orden

      ordenModal: 0,

      mensaje: "",

      desabilitar: false, // Desabilitamos boton guardar
      isValid: true, // validamos que todos lo campos sean correctos

      stringPdf: "",
      srcPdf: "",
      blobPdf: "",
      numPages: undefined,

      editedIndex: -1, // Titulo de ventana ordenes

      adModal: 0,
      adAccion: 0,
      adNombre: "",
      adId: "",

      dates: [
        moment().startOf("year").format("YYYY-MM-DD"),
        moment().endOf("year").format("YYYY-MM-DD"),
      ],
      menuFecha: false,
      menuFechaIni: false,
      menuFechaFin: false,

      alerta: { snackbar: false, color: "", text: "" },

      /* Validaciones */
      Rules: {
        sinespacio: (v) =>
          (v || "").indexOf(" ") < 0 || "No se permite espacios",
        unespacio: (v) =>
          (v || "").indexOf("  ") < 0 || "No se permite mas de 1 espacio",
      },
    };
  },

  computed: {
    ...mapState(["usuarioState", "empresaState"]),

    dateRangeText() {
      let fechaIni;
      let fechaFin;
      for (var i = 0; i < this.dates.length; i++) {
        fechaIni = this.formatFecha(this.dates[0]);
        fechaFin = this.formatFecha(this.dates[1]);
      }
      let arrayFechas = [fechaIni, fechaFin];
      return arrayFechas.join(" ~ ");
    },

    // Titulo de ordenes
    formTitle() {
      return this.editedIndex === -1
        ? "Nueva Orden de Compra"
        : "Actualizar Orden de Compra";
    },

    calcularTotal: function () {
      let me = this;
      var resultado = 0;
      for (var i = 0; i < me.detalles.length; i++) {
        resultado =
          resultado +
          parseInt(
            me.detalles[i].cantidad == "" ? 0 : me.detalles[i].cantidad
          ) *
            parseFloat(
              me.detalles[i].precio_unitario == "" ? 0 : me.cm.removeComa(me.detalles[i].precio_unitario)
            );
      }
      return me.redondear(resultado);
    },

    ...mapGetters(["esSuperadministrador", "esAdministrador", "esAlmacenero"]),
  },

  watch: {
    ordenModal(val) {
      val || this.ocultarComprobante();
    },
  },

  created() {
    this.listar();
  },

  methods: {
    // Descargar pdf de blob
    savePdf() {
      var link = document.createElement("a");
      link.href = window.URL.createObjectURL(this.blobPdf);
      var fileName = "Orden Nro " + this.nro_orden;
      link.download = fileName;
      link.click();
    },

    // Llamamos de main.js printJS
    printExternal() {
      printJS({
        printable: this.stringPdf.replace(
          "data:application/pdf;filename=generated.pdf;base64,",
          ""
        ),
        type: "pdf",
        base64: true,
      });
    },

    formatFecha(date) {
      if (!date) return null;
      const [year, month, day] = date.split("-");
      return `${day}/${month}/${year}`;
    },

    // Redondear a 2 decimales
    redondear: function (numero) {
      let entero = Math.round(Number(numero + "e+" + 2));
      let redondear = Number(entero + "e-" + 2);
      return redondear;
    },

    /* LISTAR ORDEN */
    listar() {
      let me = this;
      me.loading = true;

      axios
        .get("api/Ordenes/ListarFiltroOrdenes", {
          params: {
            buscar: me.buscar_orden == "" ? "-" : me.buscar_orden,
            fechaInicio: me.dates[0],
            fechaFin:
              me.dates[1] == undefined
                ? me.dates[0] + " 23:59:59"
                : me.dates[1] + " 23:59:59",
          },
        })
        .then(function (response) {
          me.ordenes = response.data;
          me.loading = false;
        })
        .catch(function (error) {
          //console.log(error);
        });
    },
    /* FIN LISTAR ORDEN */

    /* LISTAR DETALLE ORDEN */
    listarOD() {
      let me = this;
      me.dordenes = [];
      me.desabilitar = true;
      axios
        .get("api/Ordenes/ListarOrdenesDetalle", {
          params: {
            fechaInicio: me.dates[0],
            fechaFin:
              me.dates[1] == undefined
                ? me.dates[0] + " 23:59:59"
                : me.dates[1] + " 23:59:59",
          },
        })
        .then(function (response) {
          me.dordenes = response.data;
          me.crearXLSXdet();
        })
        .catch(function (error) {
          //console.log(error);
          me.desabilitar = false;
        });
    },
    /* FIN LISTAR DETALLE ORDEN */

    /* PEDIDO GUARDAR, EDITAR, MOSTRAR VENTANA Y ELIMINAR DETALLE */

    guardarOrden() {
      let me = this;
      // Validamos si el detalle de orden esta vacio

      if (me.detalles.length <= 0) {
        me.loading = false;
        me.alerta.snackbar = true;
        me.alerta.color = "warning";
        return (me.alerta.text = "Ingrese al menos un item al detalle.");
      }

      me.desabilitar = true; // Desabilitamos boton guardar

      me.detalle_ordenes = []; // Limpiamos array para llenarlo
      me.convertirDetalle();

      if (me.editedIndex > -1) {
        // Editar orden
        axios
          .put("api/Ordenes/ActualizarOrden", {
            idorden: me.idorden,
            idempresa: me.idempresa,
            idusuario: parseInt(me.usuarioState.idusuario),
            fecha_orden: me.fecha_orden,
            nro_orden: ("000000" + me.nro_orden).slice(-6),
            descripcion: me.descripcion,
            proveedor: me.proveedor,
            observacion: me.observacion,
            fecha_inicio: me.fecha_inicio,
            fecha_fin: me.fecha_fin,

            importe_total: parseFloat(me.calcularTotal),

            detalle_ordenes: me.detalle_ordenes,
          })
          .then(function (response) {
            me.alerta.snackbar = true;
            me.alerta.color = "success";
            me.alerta.text = response.data;
            me.desabilitar = false;
            me.ocultarNuevo();
            me.listar();
          })
          .catch(function (error) {
            me.mensaje = error.response.data;
            me.desabilitar = false;
            me.limpiarAlertaError();
            //console.log(error);
          });
      } else {
        // Guardar orden
        axios
          .post("api/Ordenes/CrearOrden", {
            idempresa: me.usuarioState.idempresa,
            idusuario: parseInt(me.usuarioState.idusuario),
            fecha_orden: me.fecha_orden,
            nro_orden: ("000000" + me.nro_orden).slice(-6),
            descripcion: me.descripcion,
            proveedor: me.proveedor,
            observacion: me.observacion,
            fecha_inicio: me.fecha_inicio,
            fecha_fin: me.fecha_fin,

            importe_total: parseFloat(me.calcularTotal),

            detalle_ordenes: me.detalle_ordenes,
          })
          .then(function (response) {
            me.alerta.snackbar = true;
            me.alerta.color = "success";
            me.alerta.text = response.data;
            me.desabilitar = false;
            me.ocultarNuevo();
            me.listar();
          })
          .catch(function (error) {
            me.mensaje = error.response.data;
            me.desabilitar = false;
            me.limpiarAlertaError();
            //console.log(error);
          });
      }
    },

    convertirDetalle() {
      let me = this;
      for (var i = 0; i < me.detalles.length; i++) {
        me.detalle_ordenes.push({
          cantidad: parseInt(me.detalles[i].cantidad),
          unidad_medida: me.detalles[i].unidad_medida,
          descripcion: me.detalles[i].descripcion,
          precio_unitario: parseFloat(me.cm.removeComa(me.detalles[i].precio_unitario)),
          sub_total: me.redondear(parseInt(me.detalles[i].cantidad) * parseFloat(me.cm.removeComa(me.detalles[i].precio_unitario))),
        });
      }
    },

    fechaseleccionada(dia) {
      let arrayFecha = dia.split(["-"]);
      let ddmmyy = arrayFecha[2] + "/" + arrayFecha[1] + "/" + arrayFecha[0];
      this.fecha_orden_formateada = ddmmyy;
    },

    fechaseleccionadaIni(dia) {
      let arrayFecha = dia.split(["-"]);
      let ddmmyy = arrayFecha[2] + "/" + arrayFecha[1] + "/" + arrayFecha[0];
      this.fecha_inicio_formateada = ddmmyy;
    },

    fechaseleccionadaFin(dia) {
      let arrayFecha = dia.split(["-"]);
      let ddmmyy = arrayFecha[2] + "/" + arrayFecha[1] + "/" + arrayFecha[0];
      this.fecha_fin_formateada = ddmmyy;
    },

    // Mostrar ventana nuevo orden
    mostrarNuevaOrden() {
      this.agregarDetalle();
      this.verNuevoPedido = 1;
    },

    deleteItemPedido(item) {
      const respta = confirm(
        "¿Estás seguro de Eliminar la orden Nº " + item.idorden + " ?"
      );
      let me = this;

      if (respta) {
        axios
          .delete("api/Ordenes/Eliminar/" + item.idorden)
          .then((response) => {
            me.alerta.snackbar = true;
            me.alerta.color = "success";
            me.alerta.text = response.data;
            this.listar();
          })
          .catch(function (error) {
            me.alerta.snackbar = true;
            me.alerta.color = "error";
            me.alerta.text = error.response.data;
          });
      }
    },

    eliminarDetallePedido(arr, item) {
      var i = arr.indexOf(item);
      if (i !== -1) {
        arr.splice(i, 1);
      }
    },

    agregarDetalle() {
      let me = this;
      me.detalles.push({
        cantidad: 1,
        unidad_medida: "",
        descripcion: "",
        precio_unitario: (0).toFixed(2),
        sub_total: (0).toFixed(2),
      });
    },

    /* FIN PEDIDO GUARDAR, EDITAR, MOSTRAR VENTANA Y ELIMINAR DETALLE */

    /* MOSTRAR COMPROBANTE PARA IMPRESIÓN */
    mostrarOrden(item) {
      this.limpiar();
      this.idorden = item.idorden;
      this.fecha_orden = item.fecha_orden;
      this.nro_orden = item.nro_orden;
      this.descripcion = item.descripcion;
      this.proveedor = item.proveedor;
      this.observacion = item.observacion;
      this.fecha_inicio = item.fecha_inicio;
      this.fecha_fin = item.fecha_fin;
      this.importe_total = item.importe_total;

      this.ordenModal = 1;
      this.listarDetalle(item.idorden);
    },

    ocultarComprobante() {
      this.ordenModal = 0;
      this.limpiar();
    },
    /* FIN MOSTRAR COMPROBANTE PARA IMPRESIÓN */

    /* VER DETALLES ORDENES */
    verDetalles(item) {
      this.limpiar();
      this.idorden = item.idorden;
      this.idempresa = item.idempresa;
      this.fecha_orden = item.fecha_orden;
      this.fechaseleccionada(item.fecha_orden);
      this.nro_orden = item.nro_orden;
      this.descripcion = item.descripcion;
      this.proveedor = item.proveedor;
      this.observacion = item.observacion;
      this.fecha_inicio = item.fecha_inicio;
      this.fechaseleccionadaIni(item.fecha_inicio);
      this.fecha_fin = item.fecha_fin;
      this.fechaseleccionadaFin(item.fecha_fin);

      this.listarDetalle(item.idorden);

      this.verNuevoPedido = 1;
      this.editedIndex = 1;
    },

    limpiarAlertaError() {
      setTimeout(() => {
        this.mensaje = "";
      }, 5000);
    },

    listarDetalle(id) {
      let me = this;
      var detallesArray = [];

      axios
        .get("api/Ordenes/ListarDetalleOrdenes/" + id)
        .then(function (response) {
          detallesArray = response.data;
          detallesArray.map(function (x) {
            me.detalles.push({
              cantidad: x.cantidad,
              unidad_medida: x.unidad_medida,
              descripcion: x.descripcion,
              precio_unitario: x.precio_unitario.toFixed(2),
              sub_total: x.sub_total.toFixed(2),
            });
          });
          me.ordenModal == 1 ? me.crearPDF() : "";
        })
        .catch(function (error) {
          //console.log(error);
        });
    },
    /* FIN VER DETALLES ORDENES */

    ocultarNuevo() {
      this.verNuevoPedido = 0;
      this.desabilitar = false;
      this.limpiar();
    },

    limpiar() {
      this.idorden = "";
      this.idempresa = "";
      this.nro_orden = "";
      this.proveedor = "";
      this.descripcion = "";
      this.observacion = "";
      this.importe_total = 0;
      this.fecha_orden = "";
      this.fecha_orden_formateada = "";
      this.fecha_inicio = "";
      this.fecha_inicio_formateada = "";
      this.fecha_fin = "";
      this.fecha_fin_formateada = "";
      this.detalles = [];
      this.dordenes = [];
      this.stringPdf = "";
      this.srcPdf = "";
      this.blobPdf = "";
      this.editedIndex = -1;
    },

    /* Desactivar, activar orden */
    desactivar() {
      let me = this;

      axios
        .put("api/Ordenes/Desactivar/" + this.adId)
        .then(function (response) {
          me.adModal = 0;
          me.adAccion = 0;
          me.adNombre = "";
          me.adId = "";
          me.listar();
        })
        .catch(function (error) {
          //console.log(error);
        });
    },

    activarDesactivarMostrar(accion, item) {
      this.adModal = 1;
      this.adNombre = item.descripcion;
      this.adId = item.idorden;
      if (accion == 1) {
        this.adAccion = 1;
      } else if (accion == 2) {
        this.adAccion = 2;
      } else {
        this.adModal = 0;
      }
    },

    activarDesactivarCerrar() {
      this.adModal = 0;
    },

    activar() {
      let me = this;
      axios
        .put("api/Ordenes/Activar/" + this.adId)
        .then(function (response) {
          me.adModal = 0;
          me.adAccion = 0;
          me.adNombre = "";
          me.adId = "";
          me.listar();
        })
        .catch(function (error) {
          //console.log(error);
        });
    },
    /* Fin Desactivar, activar orden */

    crearPDF() {
      let me = this;

      var columns = [
        { header: "Nº", dataKey: "item" },
        { header: "Descripción", dataKey: "descripcion" },
        { header: "Unidad", dataKey: "unidad_medida" },
        { header: "Cantidad", dataKey: "cantidad" },
        { header: "Precio Unit.", dataKey: "precio_unitario" },
        { header: "Sub Total", dataKey: "sub_total" },
      ];
      var body = [];
      var foot = [];

      for (var i = 0; i < me.detalles.length; i++) {
        body.push({
          item: i + 1,
          descripcion: me.detalles[i].descripcion,
          unidad_medida: me.detalles[i].unidad_medida,
          cantidad: me.detalles[i].cantidad,
          precio_unitario: numeral(this.detalles[i].precio_unitario).format(
            "###,##0.00"
          ),
          sub_total: numeral(this.detalles[i].sub_total).format("###,##0.00"),
        });

        if (i === this.detalles.length - 1) {
          foot.push([
            {
              content: "Total: ",
              colSpan: 5,
            },
            numeral(this.importe_total).format("###,##0.00"),
          ]);
        }
      }

      // Agregar Encabezado: titulo y logo
      const addHeaders = (doc) => {
        const pageCount = doc.internal.getNumberOfPages();

        for (var i = 1; i <= pageCount; i++) {
          doc.setPage(i);
          doc.addImage(imgData, "JPEG", 20, 9, 13, 16);
          // Datos empresa
          doc.setFont("helvetica", "bold");
          doc.setFontSize(12);
          doc.text("ORDEN DE COMPRA", doc.internal.pageSize.width / 2, 18, {
            align: "center",
          });
          doc.setFontSize(9);
          doc.text(
            me.empresaState.nombre,
            doc.internal.pageSize.width / 2,
            25,
            {
              align: "center",
            }
          );

          doc.text(161, 18, "NRO");
          doc.setFont("helvetica", "bold");
          doc.roundedRect(160, 20, 15, 4, 1, 1);
          doc.text(162, 23, me.nro_orden);

          doc.text(177, 18, "FECHA");
          doc.setFont("helvetica", "bold");
          doc.roundedRect(176, 20, 20, 4, 1, 1);
          doc.text(178, 23, moment(me.fecha_orden).format("DD/MM/YYYY"));

          doc.setFont("helvetica", "normal");

          doc.text("SEÑORES: " + me.proveedor, 20, 35);
          doc.text("DESCRIPCIÓN: " + me.descripcion.substring(0, 85), 20, 40);
          doc.text("OBSERVACIÓN: " + me.observacion, 20, 45);

          doc.text(
            "FECHA INICIO: " + moment(me.fecha_inicio).format("DD/MM/YYYY"),
            20,
            50
          );
          doc.text(
            "FECHA FIN: " + moment(me.fecha_fin).format("DD/MM/YYYY"),
            70,
            50
          );
        }
      };
      // Agregar pie de pagina: numeración
      const addFooters = (doc) => {
        const pageCount = doc.internal.getNumberOfPages();

        doc.setFont("helvetica", "italic");
        doc.setFontSize(7);
        for (var i = 1; i <= pageCount; i++) {
          doc.setPage(i);
          doc.text(
            "Pagina " + String(i) + " de " + String(pageCount),
            doc.internal.pageSize.width / 2,
            290,
            "center"
          );
        }
      };

      var imgData = "data:image/png;base64," + me.empresaState.logo;
      var doc = new jsPDF("p", "mm", [210, 297]);

      doc.autoTable({
        theme: "grid",
        columns,
        body,
        foot,
        margin: { top: 53, bottom: 45, left: 10, right: 10 },
        styles: { cellPadding: 1, overflow: "ellipsize", cellWidth: "wrap" },
        headStyles: {
          fillColor: [46, 79, 120],
          fontSize: 6,
          halign: "center",
          fontStyle: "bold",
        },
        bodyStyles: { textColor: 0, fontSize: 6 },
        footStyles: {
          fillColor: [255, 255, 255],
          textColor: 0,
          fontSize: 6,
          halign: "right",
        },
        columnStyles: {
          item: { cellWidth: 7, halign: "center" },
          descripcion: { cellWidth: "auto" },
          unidad_medida: { cellWidth: 20, halign: "center" },
          cantidad: { cellWidth: 15, halign: "center" },
          precio_unitario: { cellWidth: 20, halign: "right" },
          sub_total: { cellWidth: 25, halign: "right" },
        },
      });

      doc.setProperties({
        title: "Orden Nro " + me.nro_orden + ".pdf",
      });

      addHeaders(doc);
      addFooters(doc);

      me.stringPdf = doc.output("datauristring");
      me.blobPdf = doc.output("blob");
      // Para visualizar todas las paginas del pdf
      me.srcPdf = pdf.createLoadingTask(me.stringPdf);
      me.srcPdf.promise.then((pdf) => {
        me.numPages = pdf.numPages;
      });
    },

    crearXLSX() {
      var rows = [];

      for (var i = 0; i < this.ordenes.length; i++) {
        rows.push({
          "Fecha Orden": moment(this.ordenes[i].fecha_orden).format(
            "DD/MM/YYYY"
          ),
          "Nro Orden": this.ordenes[i].nro_orden,
          Proveedor: this.ordenes[i].proveedor,
          Descripcion: this.ordenes[i].descripcion,
          Obsrevación: this.ordenes[i].observacion,
          "Fecha Inicio": moment(this.ordenes[i].fecha_inicio).format(
            "DD/MM/YYYY"
          ),
          "Fecha Fin": moment(this.ordenes[i].fecha_fin).format("DD/MM/YYYY"),
          Usuario: this.ordenes[i].usuario,
          Estado: this.ordenes[i].estado,
        });
      }

      var Detalle = XLSX.utils.json_to_sheet(rows);

      var wb = XLSX.utils.book_new();

      XLSX.utils.book_append_sheet(wb, Detalle, "Reporte Ordenes");

      XLSX.writeFile(wb, "Reporte Ordenes.xlsx");
    },

    crearXLSXdet() {
      var rows = [];

      for (var i = 0; i < this.dordenes.length; i++) {
        rows.push({
          "Fecha Orden": moment(this.dordenes[i].fecha_orden).format(
            "DD/MM/YYYY"
          ),
          "Nro Orden": this.dordenes[i].nro_orden,
          Proveedor: this.dordenes[i].proveedor,
          Descripcion: this.dordenes[i].descripcion,
          Obsrevación: this.dordenes[i].observacion,
          "Fecha Inicio": moment(this.dordenes[i].fecha_inicio).format(
            "DD/MM/YYYY"
          ),
          "Fecha Fin": moment(this.dordenes[i].fecha_fin).format("DD/MM/YYYY"),
          //Usuario: this.dordenes[i].usuario,
          Estado: this.dordenes[i].estado,
          Cantidad: this.dordenes[i].cantidad,
          "Unidad Medida": this.dordenes[i].unidad_medida,
          Detalle: this.dordenes[i].detalle,
          "Precio Unitario": this.dordenes[i].precio_unitario,
          "Sub total": this.dordenes[i].sub_total,
        });
      }

      var Detalle = XLSX.utils.json_to_sheet(rows);

      var wb = XLSX.utils.book_new();

      XLSX.utils.book_append_sheet(wb, Detalle, "Ordenes Detallado");

      // export Excel file
      XLSX.writeFile(wb, "Reporte Ordenes Detallado.xlsx");
      me.desabilitar = false;
    },
  },
};
</script>
<style scoped>
.right-input >>> input {
  text-align: right;
}
.center-input >>> input {
  text-align: center;
}
</style>