<template>
  <v-container class="fill-height" fluid>
    <v-layout align-center justify-center>
      <v-row align="center" justify="center">
        <v-col cols="12" sm="8" md="4">
          <v-card class="elevation-12">
            <v-toolbar dark dense class="primary">
              <v-toolbar-title>Acceso al Sistema</v-toolbar-title>
            </v-toolbar>
            <v-card-text>
              <v-form v-model="isValid">
                <v-text-field
                  label="Usuario"
                  v-model.trim="nickname"
                  :rules="[v => (v || '').indexOf(' ') < 0 || 'No se permite espacios']"
                  autofocus
                  prepend-icon="person"
                  right
                  required
                  maxlength="30"
                  v-lowercase
                ></v-text-field>
                <v-text-field
                  label="Contraseña"
                  v-model="password"
                  :rules="[v => v.length >= 5 || 'La Contraseña debe tener mas de 5 caracteres']"
                  prepend-icon="lock"
                  :append-icon="mostrarpassword ? 'visibility' : 'visibility_off'"
                  :type="mostrarpassword ? 'text' : 'password'"
                  @click:append="mostrarpassword = !mostrarpassword"
                  @keypress.enter="ingresar"
                  maxlength="30"
                  required
                ></v-text-field>
                <!-- Mensaje error login -->
                <template cols="12" v-if="mensajeLogin !=''">
                  <v-alert text dense type="error">{{ mensajeLogin }}</v-alert>
                </template>
                <!-- Mensaje error login -->
              </v-form>
            </v-card-text>
            <v-card-actions class="py-0 pb-4 px-4">
              <v-btn
                @click="ingresar"
                block
                text
                color="primary"
                :loading="desabilitar"
                :disabled="desabilitar || !isValid"
              >Acceder</v-btn>
            </v-card-actions>
          </v-card>
        </v-col>
      </v-row>
    </v-layout>
  </v-container>
</template>

<script>
import axios from "axios";
import { mapActions } from "vuex";

export default {
  data() {
    return {
      nickname: "",
      password: "",

      mensajeLogin: "",
      desabilitar: false,
      isValid: true,
      mostrarpassword: false,
      alerta: { snackbar: false, color: "", text: "" },
    };
  },

  methods: {
    ...mapActions(["guardarToken", "guardarRefreshToken", "cargarEmpresa"]),

    ingresar() {
      let me = this;
      me.desabilitar = true;
      me.error = null;

      // Encriptar password
      var CryptoJS = require("crypto-js");

      var key = CryptoJS.enc.Utf8.parse("8080808080808080");
      var iv = CryptoJS.enc.Utf8.parse("8080808080808080");

      var encryptedpassword = CryptoJS.AES.encrypt(
        CryptoJS.enc.Utf8.parse(me.password.trim()),
        key,
        {
          keySize: 128 / 8,
          iv: iv,
          mode: CryptoJS.mode.CBC,
          padding: CryptoJS.pad.Pkcs7,
        }
      ).toString();
      // Fin Encriptar password

      axios
        .post("api/Usuarios/Login", {
          nickname: me.nickname,
          password: encryptedpassword,
        })
        .then((respuesta) => {
          me.desabilitar = false;
          return respuesta.data;
        })
        .then((data) => {
          //console.log(data);
          const token = data.token;
          const refreshtoken = data.refreshtoken;
          me.guardarToken(token);
          me.guardarRefreshToken(refreshtoken);
          me.cargarEmpresa();
          me.$router.push({ name: "Dashboard" });
        })
        .catch((err) => {
          //console.log(err.response);
          me.desabilitar = false;
          if (err.response.status == 400) {
            me.mensajeLogin = "No es un nickname válido";
          } else if (err.response.status == 404) {
            me.mensajeLogin =
              "No existe el usuario o sus datos son incorrectos";
          } else {
            me.mensajeLogin = "Ocurrió un error";
          }
          me.limpiarAlertaError();
          //console.log(err)
        });
    },

    limpiarAlertaError() {
      setTimeout(() => {
        this.mensajeLogin = "";
      }, 4000);
    },
  },
};
</script>

