<template>
  <HelloContacto />
</template>

<script>
  import HelloContacto from '../components/HelloContacto'

  export default {
    components: {
      HelloContacto,
    },
  }
</script>