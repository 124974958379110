<template>
  <v-container fluid fill-height>
    <v-layout alig-start>
      <v-flex>
        <!-- Inicio de barra de alerta -->
        <v-snackbar v-model="alerta.snackbar" :color="alerta.color" @click="alerta.snackbar = false">{{ alerta.text
        }}</v-snackbar>
        <!-- Fin inicio de barra de alerta -->

        <!-- Listado de local y opciones de titulo, busqueda, nuevo, etc -->
        <v-data-table :loading="loading" :headers="cabLocal" :items="ltLocales" :items-per-page="15" :search="buscar"
          class="elevation-3" dense>
          <template v-slot:top>
            <v-toolbar flat>
              <v-toolbar-title class="hidden-sm-and-down">LOCALES</v-toolbar-title>
              <v-divider class="mx-4 hidden-sm-and-down" inset vertical></v-divider>
              <!-- Impresion local -->
              <v-btn fab x-small color="accent" @click="crearPDF()" class="mr-1">
                <v-icon>print</v-icon>
              </v-btn>
              <!-- Fin Impresion local -->

              <v-spacer />
              <!-- Formulario busqueda de local -->
              <v-text-field class="text-xs-center" v-model.trim="buscar" @keyup="listar()" append-icon="search"
                v-uppercase dense label="Búsqueda" hide-details></v-text-field>
              <!-- Fin Formulario Busqueda de local -->
              <v-spacer />
              <!-- Modal crear y modificar local -->
              <v-dialog v-model="dialog" max-width="600px" persistent>
                <template v-slot:activator="{ on }">
                  <v-btn fab small dark color="primary" v-on="on">
                    <v-icon dark>add</v-icon>
                  </v-btn>
                </template>
                <v-card>
                  <v-toolbar dark dense class="primary">
                    <v-toolbar-title>{{ formTitle }}</v-toolbar-title>
                    <v-spacer />
                    <v-btn icon dark @click="guardar" :loading="desabilitar" :disabled="desabilitar || !isValid">
                      <v-icon>save</v-icon>
                    </v-btn>
                    <v-btn icon dark @click="close">
                      <v-icon>close</v-icon>
                    </v-btn>
                  </v-toolbar>
                  <v-card-text>
                    <v-form v-model="isValid">
                      <v-row class="mt-2">
                        <v-col cols="12" sm="12">
                          <v-text-field dense v-model.trim="locales" label="Local"
                            :rules="[v => !!v || 'requerido', Rules.unespacio]" maxlength="40" v-uppercase
                            hide-details></v-text-field>
                        </v-col>
                        <v-col cols="12" sm="12">
                          <v-text-field dense v-model.trim="direccion" label="Dirección"
                            :rules="[v => !!v || 'requerido', Rules.unespacio]" maxlength="60" v-uppercase
                            hide-details></v-text-field>
                        </v-col>
                        <v-col cols="6" sm="6">
                          <v-select dense :items="selectPropie" v-model="flg_propie" label="Tipo empresa"
                            :rules="[v => !!v || 'Requerido']" required hide-details></v-select>
                        </v-col>
                        <v-col cols="6" sm="6">
                          <v-text-field dense v-model.trim="ubigeo" label="Ubigeo"
                            :rules="[v => !!v || 'requerido', Rules.unespacio]" maxlength="6" v-mask-number v-uppercase
                            hide-details></v-text-field>
                        </v-col>
                        <!-- Mensaje error local -->
                        <v-col cols="12" v-if="mensajeLocal != ''">
                          <v-alert text dense type="error">{{ mensajeLocal }}</v-alert>
                        </v-col>
                        <!-- Mensaje error local -->
                      </v-row>
                    </v-form>
                  </v-card-text>
                </v-card>
              </v-dialog>
              <!-- Fin Modal crear y modificar local -->

              <!-- Modal activar, desactivar local -->
              <v-dialog v-model="adModal" max-width="300">
                <v-card>
                  <v-card-title class="headline justify-center" v-if="adAccion == 1">¿Activar Local?</v-card-title>
                  <v-card-title class="headline justify-center" v-if="adAccion == 2">¿Desactivar Local?</v-card-title>
                  <v-card-text>
                    Estás a punto de
                    <span v-if="adAccion == 1">Activar</span>
                    <span v-if="adAccion == 2">Desactivar</span>
                    el local {{ adNombre }}
                  </v-card-text>
                  <v-card-actions class="py-0 pb-4 justify-center">
                    <v-btn text small color="error" @click="activarDesactivarCerrar">Cancelar</v-btn>
                    <v-btn v-if="adAccion == 1" text small color="success" @click="activar">Activar</v-btn>
                    <v-btn v-if="adAccion == 2" text small color="warning" @click="desactivar">Desactivar</v-btn>
                  </v-card-actions>
                </v-card>
              </v-dialog>
              <!-- Fin Modal activar, desactivar local -->
            </v-toolbar>
          </template>
          <!-- Opciones editar, eliminar local -->

          <template v-slot:item.condicion="{ item }">
            <template v-if="item.condicion">
              <v-btn text small color="success" @click="activarDesactivarMostrar(2, item)">Activo</v-btn>
            </template>
            <template v-else>
              <v-btn text small color="warning" @click="activarDesactivarMostrar(1, item)">Inactivo</v-btn>
            </template>
          </template>

          <template v-slot:item.opciones="{ item }">
            <v-btn small icon>
              <v-icon color="info" @click="editItem(item)">edit</v-icon>
            </v-btn>
            <v-btn small icon>
              <v-icon color="error" @click="deleteItem(item)">delete_forever</v-icon>
            </v-btn>
          </template>
          <!-- Fin Opciones editar, eliminar local -->
        </v-data-table>
        <!-- Fin Listado de local y opciones de titulo, busqueda, nuevo, etc -->
      </v-flex>
    </v-layout>
  </v-container>
</template>

<script>
import axios from "axios";
import jsPDF from "jspdf";
import { mapState } from "vuex";

export default {
  data() {
    return {
      ltLocales: [],
      dialog: false,
      cabLocal: [
        //{ text: "CdLc", value: "idlocal" },
        { text: "Locales", value: "locales", sortable: false },
        { text: "Dirección", value: "direccion", sortable: false },
        { text: "Condición", align: "center", value: "condicion", sortable: false },
        { text: "OPC.", value: "opciones", sortable: false },
      ],

      buscar: "",
      editedIndex: -1,
      idlocal: "",
      locales: "",
      direccion: "",
      flg_propie: "", // selectPropie
      ubigeo: "",

      adModal: 0,
      adAccion: 0,
      adNombre: "",
      adId: "",
      mensajeLocal: "",

      selectPropie: [{ text: "PRIVADO" }, { text: "ESTATAL" }],

      desabilitar: false,
      isValid: true,
      alerta: { snackbar: false, color: "", text: "" },

      Rules: {
        unespacio: (v) =>
          (v || "").indexOf("  ") < 0 || "No se permite mas de 1 espacio",
      },
    };
  },

  computed: {
    formTitle() {
      return this.editedIndex === -1 ? "Nuevo Local" : "Actualizar Local";
    },
    ...mapState(["usuarioState", "empresaState"]),
  },

  watch: {
    dialog(val) {
      val || this.close();
    },
  },

  created() {
    this.listar();
  },

  methods: {
    /* Listar local */
    listar() {
      let me = this;
      me.loading = true;

      axios
        .get("api/Locales/ListarLocalEmpresaFiltro", {
          params: {
            idempresa: me.usuarioState.idempresa,
            buscar: me.buscar == "" ? "-" : me.buscar,
          },
        })
        .then(function (response) {
          me.ltLocales = response.data;
          me.loading = false;
        })
        .catch((err) => {
          //console.log(err.response);
        });
    },
    /* Fin Listar local */

    /* Editar y guardar local */
    guardar() {
      let me = this;
      me.desabilitar = true;

      if (me.editedIndex > -1) {
        //Código para editar
        axios
          .put("api/Locales/Actualizar", {
            idlocal: me.idlocal,
            idempresa: me.idempresa,
            locales: me.locales,
            direccion: me.direccion,
            ubigeo: me.ubigeo,
            flg_propie: me.flg_propie,
          })
          .then(function (response) {
            me.desabilitar = false;
            me.alerta.snackbar = true;
            me.alerta.color = "success";
            me.alerta.text = response.data;
            me.close();
            me.listar();
            me.limpiar();
          })
          .catch(function (error) {
            me.desabilitar = false;
            me.mensajeLocal = error.response.data;
            me.limpiarAlertaError();
          });
      } else {
        //Código para guardar
        axios
          .post("api/Locales/Crear", {
            idempresa: me.usuarioState.idempresa,
            locales: me.locales,
            direccion: me.direccion,
            ubigeo: me.ubigeo,
            flg_propie: me.flg_propie,
          })
          .then(function (response) {
            me.desabilitar = false;
            me.alerta.snackbar = true;
            me.alerta.color = "success";
            me.alerta.text = response.data;
            me.close();
            me.listar();
            me.limpiar();
          })
          .catch(function (error) {
            me.desabilitar = false;
            me.mensajeLocal = error.response.data;
            me.limpiarAlertaError();
          });
      }
    },
    /* Fin Editar y guardar local */

    /* Limpiar mensaje local */
    limpiarAlertaError() {
      setTimeout(() => {
        this.mensajeLocal = "";
      }, 3000);
    },
    /* Fin Limpiar mensaje local */

    /* Editar local item */
    editItem(item) {
      this.idlocal = item.idlocal;
      this.idempresa = item.idempresa;
      this.locales = item.locales;
      this.direccion = item.direccion;
      this.flg_propie = item.flg_propie;
      this.ubigeo = item.ubigeo;
      this.editedIndex = 1;
      this.dialog = true;
    },
    /* Fin Editar local item */

    /* Cerrar ventana local */
    close() {
      this.dialog = false;
      this.limpiar();
    },
    /* Fin Cerrar ventana local */

    /* Limpiar local item */
    limpiar() {
      this.idlocal = "";
      this.idempresa = "";
      this.locales = "";
      this.direccion = "";
      this.flg_propie = "";
      this.ubigeo = "";
      this.mensajeLocal = "";
      this.editedIndex = -1;
    },
    /* Fin Limpiar local item */

    /* Desactivar, activar local */
    desactivar() {
      let me = this;

      axios
        .put("api/Locales/Desactivar/" + this.adId, {})
        .then(function (response) {
          me.adModal = 0;
          me.adAccion = 0;
          me.adNombre = "";
          me.adId = "";
          me.listar();
        })
        .catch(function (error) {
          //console.log(error);
        });
    },

    activarDesactivarMostrar(accion, item) {
      this.adModal = 1;
      this.adNombre = item.locales;
      this.adId = item.idlocal;
      if (accion == 1) {
        this.adAccion = 1;
      } else if (accion == 2) {
        this.adAccion = 2;
      } else {
        this.adModal = 0;
      }
    },

    activarDesactivarCerrar() {
      this.adModal = 0;
    },

    activar() {
      let me = this;
      axios
        .put("api/Locales/Activar/" + this.adId, {})
        .then(function (response) {
          me.adModal = 0;
          me.adAccion = 0;
          me.adNombre = "";
          me.adId = "";
          me.listar();
        })
        .catch(function (error) {
          //console.log(error);
        });
    },
    /* Fin Desactivar, activar local */

    /* Eliminar local */
    deleteItem(item) {
      const respta = confirm(
        "¿Estás seguro de que deseas eliminar el local " + item.locales + "?"
      );
      if (respta) {
        let me = this;
        axios
          .delete("api/Locales/Eliminar/" + item.idlocal)
          .then((response) => {
            me.alerta.snackbar = true;
            me.alerta.color = "success";
            me.alerta.text = response.data;
            this.listar();
          })
          .catch(function (error) {
            me.alerta.snackbar = true;
            me.alerta.color = "error";
            me.alerta.text = error.response.data;
          });
      }
    },
    /* Fin Eliminar local */

    /* Crear PDF Local */
    crearPDF() {
      var columns = [
        { header: "Local", dataKey: "locales" },
        { header: "Dirección", dataKey: "direccion" },
        { header: "Propiedad", dataKey: "flg_propie" },
        { header: "Condicion", dataKey: "condicion" },
      ];
      var body = [];

      this.ltLocales.map(function (x) {
        body.push({
          locales: x.locales,
          direccion: x.direccion,
          flg_propie: x.flg_propie,
          condicion: x.condicion == 1 ? "A" : "I",
        });
      });
      //console.log(body);
      // Agregar Encabezado: titulo y logo
      const addHeaders = (doc) => {
        const pageCount = doc.internal.getNumberOfPages();

        doc.setFont("helvetica", "bold");
        doc.setFontSize(10);
        for (var i = 1; i <= pageCount; i++) {
          doc.setPage(i);
          doc.text("LISTADO DE LOCALES", doc.internal.pageSize.width / 2, 45, {
            align: "center",
          });
        }
      };
      // Agregar pie de pagina: numeración
      const addFooters = (doc) => {
        const pageCount = doc.internal.getNumberOfPages();

        doc.setFont("helvetica", "italic");
        doc.setFontSize(7);
        for (var i = 1; i <= pageCount; i++) {
          doc.setPage(i);
          doc.text(
            "Pagina " + String(i) + " de " + String(pageCount),
            doc.internal.pageSize.width / 2,
            820,
            {
              align: "center",
            }
          );
        }
      };

      // Configurando hoja
      var imgData = "data:image/png;base64," + this.empresaState.logo;
      var doc = new jsPDF("p", "pt");

      doc.addImage(imgData, "JPEG", 40, 17, 35, 40);
      doc.autoTable({
        columns,
        body,
        margin: { top: 70 },
        headStyles: {
          fillColor: [46, 78, 121],
          fontSize: 7,
          halign: "center",
        },
        bodyStyles: { textColor: 0, fontSize: 8 },
        columnStyles: {
          locales: { cellWidth: 190 },
          direccion: { cellWidth: "auto" },
          flg_propie: { cellWidth: 44 },
          condicion: { cellWidth: 45.28, halign: "center" },
        },
      });
      // 515 ancho hoja
      addHeaders(doc);
      addFooters(doc);
      doc.save("Locales.pdf");
    },
    /* Fin Crear PDF Local */
  },
};
</script>
