<template>
  <v-layout justify-center>
    <v-flex>
      <template>
        <v-img
          :src="require('../assets/web/otro.jpg')"
          height="180"
          class="white--text align-center"
        >
          <v-card-title align="center">
            <div class="text-h3 font-weight-light">SERVICIOS</div>
          </v-card-title>
        </v-img>
      </template>

      <v-item-group class="mt-10 mb-10">
        <v-container>
          <v-row class="mb-10">
            <!-- Imagen 1 -->
            <v-card class="mx-auto" max-width="344">
              <v-img :src="require('../assets/web/inventario.jpeg')" height="200px"></v-img>
            </v-card>
            <!-- Servicio 1 -->
            <v-card flat class="mx-auto" max-width="688">
              <v-card-title class="text-h5 justify-center">Inventario</v-card-title>
              <v-card-subtitle>
                Realizamos la toma de inventario de bienes patrimoniales para entidades públicas y privadas, con un personal altamente capacitado.
                <br />El objetivo principal es tomar conocimiento exacto de la existencia de los activos de la entidad.
                <br />- Servicio a entidades Públicas y Privadas.
                <br />- Toma de inventario de bienes muebles e inmuebles.
                <br />- Codificación de activos fijos con códigos de barras.
              </v-card-subtitle>
            </v-card>
          </v-row>
          <v-row class="mb-10">
            <!-- Servicio 2 -->
            <v-card flat class="mx-auto" max-width="688">
              <v-card-title class="text-h5 justify-center">Accesible por Internet</v-card-title>
              <v-card-subtitle>
                Administre y controle sus bienes patrimoniales desde cualquier lugar en el mundo.
                Contamos con un servidor y plataforma virtual, el cual ayuda interactuar entre el Proveedor y el Contratista, mediante el acceso a internet nos permite acceder desde nuestro
                ordenador personal, Tablet o móvil, con el claro objetivo de registrar, controlar, supervisar e imprimir reportes en tiempo real de sus bienes patrimoniales.
              </v-card-subtitle>
            </v-card>
            <!-- Imagen 2 -->
            <v-card class="mx-auto" max-width="344">
              <v-img :src="require('../assets/web/internet.jpg')" height="200px"></v-img>
            </v-card>
          </v-row>
          <v-row class="mb-10">
            <!-- Imagen 3 -->
            <v-card class="mx-auto" max-width="344">
              <v-img :src="require('../assets/web/implementa.jpg')" height="200px"></v-img>
            </v-card>
            <!-- Servicio 3 -->
            <v-card flat class="mx-auto" max-width="688">
              <v-card-title class="text-h5 justify-center">Implementacion Inmediata</v-card-title>
              <v-card-subtitle>
                Permite crear la toma de inventario patrimonial de su entidad de manera rápida y sencilla.
                Se implementó una solución que permite el registro rápido, oportuno, confiable y seguro mediante profesionales
                altamente calificado para el servicio de implementación, registro y codificación de sus activos fijos, utilizando los
                Sistemas Nacionales (SIMI, SINABIP Y SIGA PATRIMONIAL); y como soporte fundamental la carga masiva compatible con
                los Sistemas Nacionales el Sistema de Inventario Empresarial (SIE), propia de la empresa pudiendo generar, verificar y controlar el avance de donde se encuentre mediante nuestros servidor en la web
              </v-card-subtitle>
            </v-card>
          </v-row>
          <v-row class="mb-10">
            <!-- Servicio 4 -->
            <v-card flat class="mx-auto" max-width="688">
              <v-card-title class="text-h5 justify-center">Software de Gestión Institucional</v-card-title>
              <v-card-subtitle>
                El sistema está desarrollado para realizar de forma fácil y eficiente la toma de inventario en entidades del estado y empresas privadas, mejora los procesos en la gestión de activos fijos, bienes muebles y vehículos de la entidad.
                <br />Características y funciones:
                <br />- Registro de toma de inventario por PC, Tablet, Celulares o usando lectores de código de barra.
                <br />- Control de inventario por locales, areas, generacion de reportes y asignacion de bienes al personal.
              </v-card-subtitle>
            </v-card>
            <!-- Imagen 4 -->
            <v-card class="mx-auto" max-width="344">
              <v-img :src="require('../assets/web/sime.png')" height="200px"></v-img>
            </v-card>
          </v-row>
          <v-row class="mb-10">
            <!-- Imagen 5-->
            <v-card class="mx-auto" max-width="344">
              <v-img :src="require('../assets/web/privado.jpg')" height="200px"></v-img>
            </v-card>
            <!-- Servicio 5 -->
            <v-card flat class="mx-auto" max-width="688">
              <v-card-title class="text-h5 justify-center">Inventario a entidades Privadas</v-card-title>
              <v-card-subtitle>
                Administre sus activos fijos cumpliendo con la NIC 16 (Propiedad, Planta y Equipo) y la NIIF 16 (Arrendamiento).
                Realizar en cada una de sus instalaciones la toma de inventario físico de los bienes muebles, a nivel de ítems inventariables por
                IDENTIFICACIÓN ESPECIFICA AL BARRER a nivel de códigos NIIF y CECOS (centros de costo) en las instalaciones de su entidad, en cumplimiento de lo establecido
                por la Norma Internacional de Contabilidad (NIC) y la Normas Internacionales de Información Financiera (NIIF).
              </v-card-subtitle>
            </v-card>
          </v-row>
          <v-row class="mb-10">
            <!-- Servicio 6 -->
            <v-card flat class="mx-auto" max-width="688">
              <v-card-title class="text-h5 justify-center">Inventario a entidades Publicas</v-card-title>
              <v-card-subtitle>
                El artículo 121 del Decreto Supremo N° 007-2008-VIVIENDA, establece que Bajo responsabilidad del Jefe de la Oficina General de Administración o la que haga sus veces,
                 se efectuará un inventario anual en todas las entidades, con fecha de cierre al 31 de diciembre del año inmediato anterior al de su presentación, y deberá ser remitido a 
                 la SBN entre los meses de enero y marzo de cada año. La información deberá remitirse a traves del Sistema de Información Nacional de Bienes Estatales-(SINABIP), la que será acompañada 
                 del Informe Final de Inventario y del Acta de Conciliación.
              </v-card-subtitle>
            </v-card>
            <!-- Imagen 6 -->
            <v-card class="mx-auto" max-width="344">
              <v-img :src="require('../assets/web/sbn.jpg')" height="200px"></v-img>
            </v-card>
          </v-row>
        </v-container>
      </v-item-group>
    </v-flex>
  </v-layout>
</template>

<script>
export default {
  data() {
    return {
      items: [
        {
          src: require("../assets/web/carousel.jpg"),
        },
        {
          src: require("../assets/web/carousel2.jpeg"),
        },
        {
          src: require("../assets/web/carousel3.jpeg"),
        },
      ],

      slides: [
        "Inventario de Bienes",
        "Bien Patrimonial Estatal",
        "Bien Patrimonial Privado",
      ],

      avances: "",
      avanceBienes: [],
      totalBienes: [],

      avancesUsuarios: "",
      usuarios: [],
      numerobienes: [],
    };
  },
};
</script>
