<template>
  <v-container fluid fill-height>
    <v-layout align-start>
      <v-flex>
        <!--         <v-alert border="left" close-text="Close Alert" color="red accent-4" dark dismissible>
          <strong>¡ALERTA DE VENCIMIENTO DE SERVICIO!<br /></strong>
          <br />Estimado/a usuario/a,<br />

          <br />Le informamos que el servicio está próximo a vencer. Asegúrese de tomar las medidas necesarias para
          renovar o extender el servicio antes de la fecha límite, el día 26 de mayo de 2023.<br />

          <br />Es importante que tome acción a la brevedad para evitar cualquier interrupción en el funcionamiento del
          servicio. Recuerde que el vencimiento puede resultar en la suspensión temporal o la terminación definitiva del
          mismo.<br />

          <br />Si tiene alguna pregunta o necesita más información sobre el proceso de renovación, no dude en ponerse en
          contacto con nuestro equipo de atención al cliente. Estaremos encantados de ayudarle y guiarle en el proceso de
          renovación.<br />

          <br />Agradecemos su atención y confianza en nuestros servicios. Esperamos poder continuar brindándole un
          excelente soporte en el futuro.<br />

          <br />Atentamente,<br />

          <br />UrpiSoft

        </v-alert> -->

        <v-row justify="center" class="text-center mt-4" v-if="esAlmacenero">
          <v-col cols="12">
            <h1 class="display-1 primary--text">{{ usuarioState.empresa }}</h1>
          </v-col>
        </v-row>

        <v-row justify="center" class="text-center mt-4" v-if="!esAlmacenero">
          <v-col cols="12">
            <h1 class="display-1 primary--text">BIENES PATRIMONIALES {{ usuarioState.empresa }}</h1>
          </v-col>
          <v-col cols="12" md="4" sm="4">
            <v-card class="mx-auto text-center elevation-5" color="cyan" dark max-width="300">
              <v-card-text>
                <v-sheet color="rgba(0, 0, 0, .12)">
                  <div class="display-1 font-weight-thin">{{ totalBienes[0] }}</div>
                </v-sheet>
              </v-card-text>
              <v-divider></v-divider>
              <v-card-actions class="justify-center">TOTAL BIENES</v-card-actions>
            </v-card>
          </v-col>
        </v-row>

        <v-row class="mt-6" justify="center" v-if="!esAlmacenero">
          <v-col cols="12" md="6" v-if="usuarioState.seguimientoinv == 'SI'">
            <v-card class="elevation-5">
              <v-card-text>
                <div>
                  <canvas id="avanceBienesDoughnut"></canvas>
                </div>
              </v-card-text>
              <v-divider></v-divider>
              <v-card-actions class="justify-center">SEGUIMIENTO INVENTARIO DE BIENES
                {{ usuarioState.anoinv }}</v-card-actions>
            </v-card>
          </v-col>
          <v-col cols="12" md="6">
            <v-card class="elevation-5">
              <v-card-text>
                <div>
                  <canvas id="claseTotalBar"></canvas>
                </div>
              </v-card-text>
              <v-divider></v-divider>
              <v-card-actions class="justify-center">TOTAL BIENES POR CLASE</v-card-actions>
            </v-card>
          </v-col>
          <v-col cols="12" md="6" v-if="usuarioState.seguimientoinv == 'SI'">
            <v-card class="elevation-5">
              <v-card-text>
                <div>
                  <canvas id="avanceTotalBar"></canvas>
                </div>
              </v-card-text>
              <v-divider></v-divider>
              <v-card-actions class="justify-center">BIENES VERIFICADOS POR USUARIO</v-card-actions>
            </v-card>
          </v-col>
        </v-row>
      </v-flex>
    </v-layout>
  </v-container>
</template>

<script>
import axios from "axios";
import Chart from "chart.js";
import { mapState, mapGetters } from "vuex";

export default {
  data() {
    return {
      avance: "",
      avanceBienes: [],
      totalBienes: [],
      condiciones: [],

      clasesBienes: "",
      nomclase: [],
      totclase: [],

      AvancesUsuarios: "",
      verificador: [],
      avances: [],
    };
  },

  computed: {
    ...mapGetters(["esAlmacenero"]),
    ...mapState(["usuarioState"]),
  },

  created() {
    this.datosAvanceBien();
    this.datosClaseBien();
    this.datosAvanceUsuario();
  },

  methods: {
    datosAvanceBien() {
      let me = this;

      axios
        .get("api/Bienes/AvanceBien", {
          params: {
            idempresa: me.usuarioState.idempresa,
          },
        })
        .then(function (response) {
          me.avance = response.data;
          me.cargarGraficoDoughnut();
        })
        .catch(function (error) {
          //console.log(error);
        });
    },

    datosClaseBien() {
      let me = this;

      axios
        .get("api/Bienes/totalClasesBien", {
          params: {
            idempresa: me.usuarioState.idempresa,
          },
        })
        .then(function (response) {
          me.clasesBienes = response.data;
          //console.log(me.clasesBienes);
          me.cargarGraficoBar();
        })
        .catch(function (error) {
          //console.log(error);
        });
    },

    datosAvanceUsuario() {
      let me = this;

      axios
        .get("api/Bienes/totalAvanceUsuario", {
          params: {
            idempresa: me.usuarioState.idempresa,
          },
        })
        .then(function (response) {
          me.AvancesUsuarios = response.data;
          //console.log(me.clasesBienes);
          me.cargarGraficoBarAvance();
        })
        .catch(function (error) {
          //console.log(error);
        });
    },

    cargarGraficoDoughnut() {
      let me = this;
      me.avance.map(function (x) {
        me.avanceBienes.push(x.counts);
        me.totalBienes.push(x.total_counts);
        me.condiciones.push(x.seguimiento);
      });

      // And for a doughnut chart
      var ctx = document.getElementById("avanceBienesDoughnut");
      var avanceBienesDoughnut = new Chart(ctx, {
        type: "doughnut",
        data: {
          labels: ["Avance", "Faltante"],
          datasets: [
            {
              data: me.avanceBienes,
              backgroundColor: [
                "rgba(174, 221, 70, 0.7)",
                "rgba(255, 244, 87, 0.6)",
              ],
            },
          ],
        },
        options: {
          // title: {
          //   display: true,
          //   position: "top",
          //   text: "SEGUIMIENTO DE INVENTARIO",
          // },
          maintainAspectRatio: true,
          responsive: true,
          legend: {
            display: false,
          },
          animation: {
            animateScale: true,
            animateRotate: true,
          },
        },
        plugins: [
          {
            id: "total",
            beforeDraw: function (chart) {
              const width = chart.chart.width;
              const height = chart.chart.height;
              const ctx = chart.chart.ctx;
              ctx.restore();
              const fontSize = (height / 114).toFixed(2);
              ctx.font = fontSize + "em sans-serif";
              ctx.textBaseline = "middle";
              var total =
                // parseInt(
                //   me.totalBienes[1] == undefined
                //     ? 0
                //     : (me.avanceBienes[0] * 100) / me.totalBienes[0]
                // ) + "%";
                parseInt(
                  me.totalBienes[0] == undefined
                    ? 0
                    : ((me.condiciones[0] == false
                      ? me.avanceBienes[1] == undefined
                        ? 0
                        : me.avanceBienes[1]
                      : me.avanceBienes[0]) *
                      100) /
                    me.totalBienes[0]
                ) + "%";

              const text = total;
              const textX = Math.round(
                (width - ctx.measureText(text).width) / 2
              );
              const textY = height / 2;
              ctx.fillText(text, textX, textY);
              ctx.save();
            },
          },
        ],
      });
    },

    cargarGraficoBar() {
      let me = this;

      me.clasesBienes.map(function (x) {
        me.nomclase.push(x.clase);
        me.totclase.push(x.totalclase);
      });
      //console.log(me.nomclase, me.totclase);

      var ctx = document.getElementById("claseTotalBar");
      var claseTotalBar = new Chart(ctx, {
        type: "bar",
        data: {
          labels: me.nomclase,
          datasets: [
            {
              data: me.totclase,
              //label: "Bienes",
              backgroundColor: [
                "rgba(205, 240, 79, 0.7)",
                "rgba(7, 120, 203, 0.7)",
                "rgba(135, 194, 93, 0.7)",
                "rgba(97, 87, 212, 0.7)",
                "rgba(248, 148, 4, 0.7)",
                "rgba(241, 62, 120, 0.7)",
                "rgba(76, 26, 134, 0.7)",
                "rgba(110, 76, 67, 0.7)",
                "rgba(175, 179, 72, 0.7)",
                "rgba(0, 89, 150, 0.7)",
              ],
            },
          ],
        },
        options: {
          // title: {
          //   display: true,
          //   position: "top",
          //   text: " AVANCES POR USUARIO",
          // },
          legend: {
            display: false,
          },
        },
      });
    },

    cargarGraficoBarAvance() {
      let me = this;

      me.AvancesUsuarios.map(function (x) {
        me.verificador.push(x.verificador);
        me.avances.push(x.avances);
      });
      //console.log(me.nomclase, me.totclase);

      var ctx = document.getElementById("avanceTotalBar");
      var avanceTotalBar = new Chart(ctx, {
        type: "bar",
        data: {
          labels: me.verificador,
          datasets: [
            {
              data: me.avances,
              //label: "Verificadores",
              backgroundColor: [
                "rgba(68, 187, 255, 0.7)",
                "rgba(69, 54, 46, 0.7)",
                "rgba(253, 198, 7, 0.7)",
                "rgba(200, 38, 71, 0.7)",
                "rgba(136, 112, 255, 0.7)",
                "rgba(149, 49, 99, 0.7)",
                "rgba(41, 181, 181, 0.7)",
                "rgba(212, 212, 212, 0.7)",
                "rgba(242, 155, 52, 0.7)",
                "rgba(237, 216, 51, 0.7)",
              ],
            },
          ],
        },
        options: {
          // title: {
          //   display: true,
          //   position: "top",
          //   text: " AVANCES POR USUARIO",
          // },
          legend: {
            display: false,
          },
        },
      });
    },
  },
};
</script>
