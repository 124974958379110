<template>
  <v-container fluid fill-height>
    <v-layout alig-start>
      <v-flex>
        <!-- Inicio de barra de alerta -->
        <v-snackbar v-model="alerta.snackbar" :color="alerta.color" @click="alerta.snackbar = false">{{ alerta.text }}
        </v-snackbar>
        <!-- Fin inicio de barra de alerta -->

        <!-- <div id="contenedor"></div> -->
        <div id="contenedor" style="position: absolute; visibility: hidden;"></div>
        <v-data-table :loading="loading" :headers="fvehiculo == false ? asignacionesMobiliario : asignacionesVehiculo"
          :items="reporteAsignacion" :items-per-page="15" class="elevation-3" dense>
          <template v-slot:top>
            <v-form v-model="isValid">
              <v-toolbar flat>
                <!-- Formulario busqueda de bienes activos -->
                <v-toolbar-title class="hidden-sm-and-down">ASIGNACIONES</v-toolbar-title>
                <v-divider class="mx-4 hidden-sm-and-down" inset vertical></v-divider>

                <!-- Formulario busqueda de asignacion -->
                <v-autocomplete dense @keyup="selectAsignacion()" :search-input.sync="buscar_personal"
                  v-model.trim="idasignacion" :items="selectAsignacionArray"
                  label="Selecciona: Personal | Local > Area - Oficina" :rules="[v => !!v || 'Requerido']"
                  @change="reporteAsignacion = [], listar(), detalleAsignacion(idasignacion)"
                  @click:prepend="mostrarFiltro()" prepend-icon="filter_list" clearable hide-details class="mr-1">
                </v-autocomplete>
                <!-- Fin Formulario busqueda de asignacion -->
                <!-- Impresion asignacion -->
                <v-btn fab x-small :loading="desabilitar" :disabled="desabilitar || !isValid" color="accent"
                  @click="mostrarAsignacionImp()" class="mr-1">
                  <v-icon>print</v-icon>
                </v-btn>
                <v-btn fab x-small :loading="desabilitar" :disabled="desabilitar || !isValid" color="accent"
                  @click="mostrarAsignacionInventImp()" class="mr-1" v-if="usuarioState.seguimientoinv == 'SI'">
                  <v-icon>assignment</v-icon>
                </v-btn>
                <v-btn fab x-small :loading="desabilitar" :disabled="desabilitar || !isValid" color="accent"
                  @click="mostrarAsignacionImpBarcode()" class="mr-1">
                  <v-icon>qr_code</v-icon>
                </v-btn>
                <v-btn fab x-small color="accent" @click="crearXLSX()" :loading="desabilitar"
                  :disabled="desabilitar || !isValid">
                  <v-icon>description</v-icon>
                </v-btn>
              </v-toolbar>
            </v-form>
          </template>
          <!-- Formatear detalle -->
          <template v-slot:item.codbien="{ item }">
            <template v-if='item.codbien.length == 12'>
              <span>{{ item.codbien.substr(0, 8) }}</span><strong>{{ item.codbien.substr(8, 12) }}</strong>
            </template>
            <template v-else-if="item.codbien.length == 8">
              <span>{{ item.codbienint + "-" + item.codinterno }}</span>
            </template>
          </template>

          <template v-slot:item.situacion="{ item }">
            <template>
              <span v-for="items in cm.flg_situaciones" text v-if="items.value == item.situacion">{{ items.text }}</span>
            </template>
          </template>
          <template v-slot:item.flg_estado="{ item }">
            <template>
              <span v-for="items in cm.flg_estados" text v-if="items.value == item.flg_estado">{{ items.text }}</span>
            </template>
          </template>
          <template v-slot:item.fec_act="{ item }">
            <span>{{ item.fec_act | formatearFechaHora }}</span>
          </template>
          <!-- Fin Formatear detalle -->
        </v-data-table>

        <!-- Mostrar impresion Modal bien -->
        <v-dialog v-model="verAsignacionModal" max-width="980px" scrollable>
          <v-card>
            <v-toolbar dark dense class="primary">
              <v-toolbar-title>Asignación de Bien Patrimonial</v-toolbar-title>
              <v-spacer />
              <span>{{ codbien }}</span>
              <v-spacer />
              <v-btn icon dark class="hidden-sm-and-down" @click="printExternal()">
                <v-icon>print</v-icon>
              </v-btn>
              <v-btn icon dark @click="savePdf()">
                <v-icon>download</v-icon>
              </v-btn>
              <v-btn icon dark @click="ocultarAsignacion()">
                <v-icon>close</v-icon>
              </v-btn>
            </v-toolbar>
            <v-card-text style="height: 520px;" class="px-0">
              <pdf v-for="i in numPages" :key="i" :src="srcPdf" :page="i"></pdf>
            </v-card-text>
          </v-card>
        </v-dialog>
        <!-- Fin Mostrar impresion Modal bien -->

        <!-- Mostrar Filtro -->
        <v-dialog v-model="verFiltroModal" max-width="260px">
          <v-card>
            <v-toolbar dark dense class="primary">
              <v-toolbar-title>Filtro</v-toolbar-title>
              <v-spacer />
              <v-btn icon dark @click="limpiarFiltro()" :disabled="desabilitar || !isValid">
                <v-icon>clear_all</v-icon>
              </v-btn>
              <v-btn icon dark @click="ocultarFiltro()">
                <v-icon>close</v-icon>
              </v-btn>
            </v-toolbar>
            <v-card-text>
              <v-menu v-model="menuFecha" :close-on-content-click="false" :nudge-right="40" transition="scale-transition"
                offset-y min-width="290px">
                <template v-slot:activator="{ on }">
                  <v-text-field dense v-model="dateRangeText" :disabled="desabilitar || !isValid" readonly v-on="on"
                    label="Fecha Actualizado" hide-details class="mr-1 mt-5">
                  </v-text-field>
                </template>
                <v-date-picker v-model="dates" @input="dates.length == 1 ? menuFecha = true : menuFecha = false"
                  @change="listar()" range>
                </v-date-picker>
              </v-menu>
              <v-checkbox color="internal" v-model="finterno" label="Cód. Interno" :disabled="desabilitar || !isValid"
                @click="listar" dense hide-details></v-checkbox>

              <v-checkbox v-model="fvehiculo" label="Vehiculos" :disabled="desabilitar || !isValid" @click="listar" dense
                hide-details></v-checkbox>
              <v-checkbox color="error" v-model="ffaltante" label="Faltantes" :disabled="desabilitar || !isValid"
                @click="listar" dense hide-details></v-checkbox>
            </v-card-text>
          </v-card>
        </v-dialog>
        <!-- Fin Mostrar Filtro -->
      </v-flex>
    </v-layout>
  </v-container>
</template>

<script>
import axios from "axios";
import jsPDF from "jspdf";
import { mapState } from "vuex"; //Importamos para llamar a los elementos
import moment from "moment";
import pdf from "vue-pdf"; // Visor de pdf
import Barcode from "jsbarcode";
import XLSX from "xlsx";

export default {
  components: {
    pdf,
  },
  data() {
    return {
      reporteAsignacion: [],
      dialog: false,
      asignacionesMobiliario: [
        { text: "C.Patrimonial", value: "codbien", sortable: false, width: 120 },
        { text: "Tipo de Bien", value: "tbdescripcion", sortable: false },
        { text: "Marca", value: "marca", sortable: false },
        { text: "Modelo", value: "modelo", sortable: false },
        { text: "Tipo", value: "tipo", sortable: false },
        { text: "Color", value: "color", sortable: false },
        { text: "SITUACIÓN", value: "situacion", sortable: false },
        { text: "Estado", value: "flg_estado", sortable: false },
        { text: "Usuario", value: "nickname", sortable: false },
        { text: "Fec. Actualizado", value: "fec_act", sortable: false },
      ],
      asignacionesVehiculo: [
        { text: "C.PATRIMONIAL", value: "codbien", sortable: false, width: 120 },
        { text: "Tipo de Bien", value: "tbdescripcion", sortable: false },
        { text: "Marca", value: "marca", sortable: false },
        { text: "Modelo", value: "modelo", sortable: false },
        { text: "Placa", value: "placa", sortable: false },
        { text: "Chasis/Serie", value: "numchasis", sortable: false },
        { text: "Motor", value: "nummotor", sortable: false },
        { text: "Año", value: "ano", sortable: false },
        { text: "SITUACIÓN", value: "situacion", sortable: false },
        { text: "Estado", value: "flg_estado", sortable: false },
        { text: "Usuario", value: "nickname", sortable: false },
        { text: "Fec. Actualizado", value: "fec_act", sortable: false },
      ],
      desabilitar: false,
      isValid: true,
      loading: false,
      alerta: { snackbar: false, color: "", text: "" },

      codbien: "",
      nompersonal: "",
      nrodocpersonal: "",
      nomlocal: "",
      nomarea: "",
      nomoficina: "",
      direclocal: "",

      idasignacion: "", // selectAsignacionArray

      selectAsignacionArray: [],
      buscar_personal: "",

      stringPdf: "",
      srcPdf: "",
      blobPdf: "",
      numPages: undefined,
      verAsignacionModal: 0,
      verFiltroModal: 0,

      dates: [
        moment("2000-01-01").format("YYYY-MM-DD"),
        moment().endOf("day").format("YYYY-MM-DD"),
      ],
      menuFecha: false,

      fechaActual: moment().startOf("day").format("YYYY-MM-DD"),

      ffaltante: false,
      fvehiculo: false,
      finterno: false
    };
  },

  computed: {
    ...mapState(["usuarioState", "empresaState"]), // Llamamos a state

    dateRangeText() {
      let fechaIni;
      let fechaFin;
      for (var i = 0; i < this.dates.length; i++) {
        fechaIni = this.formatFecha(this.dates[0]);
        fechaFin = this.formatFecha(this.dates[1]);
      }
      let arrayFechas = [fechaIni, fechaFin];
      return arrayFechas.join(" ~ ");
    },
  },

  created() {
    this.selectAsignacion();
  },

  methods: {
    formatFecha(date) {
      if (!date) return null;
      const [year, month, day] = date.split("-");
      return `${day}/${month}/${year}`;
    },

    // Para descargar pdf de blob
    savePdf() {
      var link = document.createElement("a");
      link.href = window.URL.createObjectURL(this.blobPdf);
      var fileName = "Ficha de bienes asignados";
      link.download = fileName;
      link.click();
    },

    // Llamamos de main.js printJS
    printExternal() {
      printJS({
        printable: this.stringPdf.replace("data:application/pdf;filename=generated.pdf;base64,", ""),
        type: "pdf",
        base64: true,
      });
    },

    /* Listar bienes activos */
    listar() {
      let me = this;
      me.desabilitar = true;
      me.loading = true;

      axios
        .get("api/Bienes/ListarAsignacionBienFiltro", {
          params: {
            idempresa: me.usuarioState.idempresa,
            idasignacion: me.idasignacion,
            ffaltante: me.ffaltante,
            fvehiculo: me.fvehiculo,
            finterno: me.finterno,
            fechainicio: me.dates[0],
            fechafin: me.dates[1] == undefined ? me.dates[0] + " 23:59:59" : me.dates[1] + " 23:59:59",
          },
        })
        .then(function (response) {
          me.reporteAsignacion = response.data;
          me.desabilitar = false;
          me.loading = false;
          //console.log(me.reporteAsignacion);
        })
        .catch((err) => {
          me.desabilitar = false;
          me.loading = false;
          me.alerta.snackbar = true;
          me.alerta.color = "error";
          me.alerta.text = "Ocurrio un error";
          //console.log(err.response);
        });
    },
    /* Fin Listar bienes activos */

    // Seleccionar motivos
    detalleAsignacion(valor) {
      let me = this;
      if (valor != null) {
        const selectId = me.selectAsignacionArray.find(
          (elemento) => elemento.value === valor
        );
        me.nompersonal = selectId.nom;
        me.nrodocpersonal = selectId.nrodoc;
        me.nomlocal = selectId.local;
        me.nomarea = selectId.area;
        me.nomoficina = selectId.oficina;
        me.direclocal = selectId.direccion;
      }
    },

    selectAsignacion(id) {
      let me = this;
      var asignacionArray = [];

      if (id) {
        // Select idasignacion
        axios
          .get("api/Asignaciones/SelectAsignacion?idasignacion=" + (id == "" ? "-" : id))
          .then(function (response) {
            asignacionArray = response.data;
            asignacionArray.map(function (x) {
              me.selectAsignacionArray.push({
                text: x.nompersonal + " | " + x.nomlocal.substring(0, 20) + " > " + x.nomarea.substring(0, 20) + " - " + x.nomoficina.substring(0, 20),
                nom: x.nompersonal,
                nrodoc: x.nrodocpersonal,
                local: x.nomlocal,
                area: x.nomarea,
                oficina: x.nomoficina,
                direccion: x.direclocal,
                value: x.idasignacion,
              });
            });
          })
          .catch(function (error) {
            //console.log(url);
          });
      } else {
        // Select asignacion para area de la asignacion
        axios
          .get("api/Asignaciones/SelectAsignacionFiltro", {
            params: {
              idempresa: me.usuarioState.idempresa,
              buscar: me.buscar_personal == "" ? "-" : me.buscar_personal == null ? "-" : me.buscar_personal,
            },
          })

          .then(function (response) {
            asignacionArray = response.data;
            asignacionArray.map(function (x) {
              me.selectAsignacionArray.push({
                text: x.nompersonal + " | " + x.nomlocal + " > " + x.nomarea + " - " + x.nomoficina,
                nom: x.nompersonal,
                nrodoc: x.nrodocpersonal,
                local: x.nomlocal,
                area: x.nomarea,
                oficina: x.nomoficina,
                direccion: x.direclocal,
                value: x.idasignacion,
              });
            });
          })
          .catch((err) => {
            //console.log(err.response);
          });
      }
    },

    ocultarAsignacion() {
      this.verAsignacionModal = 0;
      this.stringPdf = "";
      this.blobPdf = "";
      this.numPages = undefined;
      document.getElementById("contenedor").innerHTML = ""; // Limpiamos comtenedor de codigo de barras
    },

    mostrarAsignacionImp() {
      this.verAsignacionModal = 1;
      this.crearPDF();
    },

    mostrarAsignacionInventImp() {
      this.verAsignacionModal = 1;
      this.crearPDFInvent();
    },

    mostrarAsignacionImpBarcode() {
      this.verAsignacionModal = 1;
      this.crearBarcode();
    },

    mostrarFiltro() {
      this.verFiltroModal = 1;
    },

    ocultarFiltro() {
      this.verFiltroModal = 0;
    },

    limpiarFiltro() {
      this.dates = [
        moment("2000-01-01").format("YYYY-MM-DD"),
        moment().endOf("day").format("YYYY-MM-DD"),
      ],
        this.dateRangeText,
        this.ffaltante = false,
        this.fvehiculo = false,
        this.finterno = false,
        this.listar()
    },

    /* Crear PDF Codigo de barras */
    async crearBarcode() {
      let me = this;
      const contenedor = document.querySelector("#contenedor");

      // Por cada producto, crear un SVG y adjuntarlo
      me.reporteAsignacion.forEach((producto) => {
        const elemento = document.createElement("img");
        //elemento.dataset.format = "CODE39";
        elemento.dataset.value = producto.codbien;
        elemento.dataset.text = producto.tbdescripcion;
        elemento.classList.add("codbien");
        contenedor.appendChild(elemento);
      });

      Barcode(".codbien")
        .options({
          format: "CODE39", // El formato
          width: 3, // La anchura de cada barra
          height: 100, // La altura del código
          displayValue: false,
          marginTop: 10, // Margen superior
          marginRight: 10, // Margen derecho
          marginBottom: 10, // Margen inferior
          marginLeft: 10, // Margen izquierdo
        })
        .init();

      const nodeMap = await document.getElementById("contenedor").childNodes;

      var arrayBien = [];
      var item = 0;
      for (let i = 0; i < nodeMap.length; i++) {
        arrayBien.push({
          img: nodeMap[i].currentSrc,
          codbien: nodeMap[i].dataset.value,
          descripcion: nodeMap[i].dataset.text,
          item: item + i,
        });
      }

      // Creamos array de pares e impares
      var par = [];
      var impar = [];

      for (let i = 0; i < arrayBien.length; i++) {
        if (arrayBien[i].item % 2 == 0)
          par.push({
            img: arrayBien[i].img,
            codbien: arrayBien[i].codbien,
            descripcion: arrayBien[i].descripcion,
            item: arrayBien[i].item,
          });
        else
          impar.push({
            img: arrayBien[i].img,
            codbien: arrayBien[i].codbien,
            descripcion: arrayBien[i].descripcion,
            item: arrayBien[i].item,
          });
      }
      var alturaHoja = par.length;
      //console.log(alturaHoja)

      // Agregar Encabezado: titulo y logo
      const addBody = (doc) => {
        const logo = me.empresaState.logo;
        const medio = doc.internal.pageSize.width / 2;
        const medio1 = medio / 2;
        const medio2 = 54 + medio / 2;
        const mensaje = "No tocar ni despegar el stickers que identifica el bien del estado";
        const comision = me.empresaState.detalle_inv;
        const ano = me.empresaState.ano_inv;

        doc.setFont("helvetica", "bold");
        doc.setFontSize(3);

        // doc.text("M", medio, 2, {
        //   align: "center",
        // });

        var salto = 6;
        var salto2 = 6;

        // CONTENIDO PAR
        for (let i = 0; i < par.length; i++) {
          // Titulo
          doc.setFontSize(5);
          doc.text(me.empresaState.nombre, medio1, salto, { align: "center" });
          // Logo
          doc.addImage(logo, "PNG", 3, salto + 1, 6, 9.05);
          // Imagen Codigo Barra
          doc.addImage(par[i].img, "PNG", 10, salto + 1, 40, 6.94);
          // Codigo Bien
          doc.setFontSize(10);
          //doc.text(par[i].codbien + "-" + par[i].item, medio1 + 3, salto + 11, {
          doc.text(par[i].codbien, medio1 + 3, salto + 11, { align: "center" });
          // Comision
          doc.setFontSize(5);
          doc.text(comision + " " + ano, 4, salto + 14);
          // Descripcion Bien
          doc.setFontSize(4);
          doc.text(par[i].descripcion.substr(0, 50), 4, salto + 16.5);
          // Mensaje
          doc.line(4, salto + 18, 50, salto + 18);
          doc.text(mensaje, medio1, salto + 20, { align: "center" });
          salto = salto + 28;
        }

        // CONTENIDO IMPAR
        for (let i = 0; i < impar.length; i++) {
          //Titulo
          doc.setFontSize(5);
          doc.text(me.empresaState.nombre, medio2, salto2, { align: "center" });
          // Logo
          doc.addImage(logo, "PNG", 57, salto2 + 1, 6, 9.05);
          // Imagen Codigo Barra
          doc.addImage(impar[i].img, "PNG", 64, salto2 + 1, 40, 6.94);
          // Codigo Bien
          doc.setFontSize(10);
          //doc.text( impar[i].codbien + "-" + impar[i].item, medio2 + 3,salto2 + 11,{
          doc.text(impar[i].codbien, medio2 + 3, salto2 + 11, { align: "center" });
          // Comision
          doc.setFontSize(5);
          doc.text(comision + " " + ano, 58, salto2 + 14);
          // Descripcion Bien
          doc.setFontSize(4);
          doc.text(impar[i].descripcion.substr(0, 50), 58, salto2 + 16.5);
          // Mensaje
          doc.line(58, salto2 + 18, 104, salto2 + 18);
          doc.text(mensaje, medio2, salto2 + 20, { align: "center" });
          salto2 = salto2 + 28;
        }

        // Fin Contenido de Codigo de Barra
      };
      var doc = new jsPDF("p", "mm", [
        108,
        alturaHoja > 5 ? alturaHoja * 28 : 140,
      ]); // el tamaño de altura de hoja se ajusta segun el nro de bienes

      addBody(doc);
      // Exportar
      me.stringPdf = doc.output("datauristring");
      me.blobPdf = doc.output("blob");
      // Para visualizar todas las paginas del pdf
      this.srcPdf = pdf.createLoadingTask(this.stringPdf);
      this.srcPdf.promise.then((pdf) => {
        this.numPages = pdf.numPages;
      });

      //console.log(bcBien);
    },
    /* Fin Crear PDF Codigo de barras */

    /* Crear PDF asignacion */
    crearPDF() {
      let me = this;

      var columns = [
        { header: "Nº", dataKey: "item" },
        { header: "C.PATRIMONIAL", dataKey: "codbien" },
        { header: "DENOMINACIÓN", dataKey: "tbdescripcion" },
        { header: "MARCA", dataKey: "marca" },
        { header: "MODELO", dataKey: "modelo" },
        //{ header: "TIPO", dataKey: "tipo" },
        { header: "COLOR", dataKey: "color" },
        { header: "SERIE", dataKey: "serie" },
        { header: "OTROS", dataKey: "otras_caract" },
        //{ header: "DIMENSION", dataKey: "dimension" },
        { header: "ESTADO", dataKey: "estado" },
        { header: "OBSERVACIONES", dataKey: "observacion" },
      ];
      var body = [];

      for (var i = 0; i < me.reporteAsignacion.length; i++) {
        var f_estado = "";
        for (var e = 0; e < me.cm.flg_estados.length; e++) {
          f_estado = f_estado + (me.reporteAsignacion[i].flg_estado == me.cm.flg_estados[e].value ? me.cm.flg_estados[e].text : "");
        }

        body.push({
          item: i + 1,
          codbien: me.reporteAsignacion[i].codbien.length == 12 ? me.reporteAsignacion[i].codbien : me.reporteAsignacion[i].codbienint + "-" + me.reporteAsignacion[i].codinterno,
          tbdescripcion: me.reporteAsignacion[i].tbdescripcion,
          marca: me.reporteAsignacion[i].marca,
          modelo: me.reporteAsignacion[i].modelo,
          //tipo: me.reporteAsignacion[i].tipo,
          color: me.reporteAsignacion[i].color,
          serie: me.reporteAsignacion[i].serie,
          otras_caract: me.reporteAsignacion[i].otras_caract,
          //dimension: me.reporteAsignacion[i].dimension,
          estado: f_estado,
          observacion: me.reporteAsignacion[i].observacion,
        });
      }

      // Agregar Encabezado: titulo y logo
      const addHeaders = (doc) => {
        const pageCount = doc.internal.getNumberOfPages();
        for (i = 1; i <= pageCount; i++) {
          doc.setPage(i);
          doc.addImage(imgData, "JPEG", 20, 10, 11, 14); // Logo
          doc.setFont("helvetica", "bold");
          doc.setFontSize(12);
          doc.text("FICHA DE ASIGNACION EN USO Y DEVOLUCIÓN DE BIENES MUEBLES PATRIMONIALES", doc.internal.pageSize.width / 2, 15, { align: "center" });
          // doc.text(
          //   "Mobiliario, Equipo y Maquinaria",
          //   doc.internal.pageSize.width / 2,
          //   15,
          //   {
          //     align: "center",
          //   }
          // );
          doc.setFontSize(10);
          doc.text(me.empresaState.nombre, doc.internal.pageSize.width / 2, 19, { align: "center" });
          doc.text("DATOS DEL USUARIO", 40, 25);
          if (me.ffaltante) { doc.text("BIENES FALTANTES", 250, 25); }
          doc.setFont("helvetica", "normal");
          doc.setFontSize(9);
          doc.text("Nombres y apellidos: " + me.nompersonal, 40, 30);
          doc.text("Nº DNI: " + me.nrodocpersonal, 160, 30);
          doc.text("Correo electrónico: ", 40, 35);
          doc.text("Local: " + me.nomlocal, 40, 40);
          doc.text("Area: " + me.nomarea, 40, 45);
          doc.text("Oficina: " + me.nomoficina, 160, 45);
          doc.text("Dirección: " + me.direclocal, 40, 50);
          doc.setFontSize(6);
          doc.text("Pag. " + i + " de " + pageCount, 250, 13);
          doc.setFont("helvetica", "bold");
          doc.text("Fecha: " + me.formatFecha(me.fechaActual), 250, 17);
        }
      };
      // Agregar pie de pagina: numeración
      const addFooters = (doc) => {
        const pageCount = doc.internal.getNumberOfPages();
        doc.setFont("helvetica", "normal");
        doc.setFontSize(8);
        for (i = 1; i <= pageCount; i++) {
          doc.setPage(i);
          doc.setDrawColor(0, 0, 0);
          //doc.setLineWidth(0.5)
          var pageWidth = doc.internal.pageSize.width; // Ancho de la hoja
          // Firmas
          var line = 196;
          var linlong = 50;
          var linMargin = 20;

          var startL1 = (pageWidth - linlong * 3 - linMargin * 2) / 2;
          var startL2 = startL1 + linlong + linMargin;
          var startL3 = startL2 + linlong + linMargin;

          doc.line(startL1, line, startL1 + linlong, line);
          doc.text(startL1 + 20, 200, "Usuario");

          doc.line(startL2, line, startL2 + linlong, line);
          doc.text(startL2 + 13, 200, "Personal de la OCP");

          doc.line(startL3, line, startL3 + linlong, line);
          doc.text(startL3 + 13, 200, "Registro SIME OCP");

          // Consideraciones
          doc.setFont("helvetica", "bold");
          doc.text(10, 170, "CONSIDERACIONES:");
          doc.setFont("helvetica", "normal");
          doc.text(15, 175, "- El usuario es responsable de la permanencia y conservación de cada uno de los bienes descritos, recomendánse tomar las precaciones del caso para evitar sustracciones, deterioros, etc.");
          doc.text(15, 180, "- Cualquier necesidad de traslado del bien mueble patrimonial dentro o fuera del local de la Entidad u Organizacion de la Entidad, es previamente comunicado al encargado de la OCP.");
        }
      };

      var imgData = "data:image/png;base64," + this.empresaState.logo;
      var doc = new jsPDF("l", "mm");

      doc.autoTable({
        theme: "grid",
        columns,
        body,
        margin: { top: 53, bottom: 45, left: 10, right: 10 },
        styles: { cellPadding: 1, overflow: "ellipsize", cellWidth: "wrap" },
        headStyles: {
          fillColor: [46, 79, 120],
          fontSize: 6,
          halign: "center",
          fontStyle: "bold",
        },
        bodyStyles: { textColor: 0, fontSize: 6 },
        columnStyles: {
          item: { cellWidth: 8, halign: "center" },
          codbien: { cellWidth: 20, halign: "center" },
          tbdescripcion: { cellWidth: 44 },
          marca: { cellWidth: 35 },
          modelo: { cellWidth: 30 },
          //tipo: { cellWidth: 28 },
          color: { cellWidth: 28 },
          serie: { cellWidth: 28 },
          otras_caract: { cellWidth: 33 },
          //dimension: { cellWidth: 28 },
          estado: { cellWidth: 16 },
          observacion: { cellWidth: 35 },
        },
      });
      // 515.28 ancho hoja
      addHeaders(doc);
      addFooters(doc);
      // Exportar
      me.stringPdf = doc.output("datauristring");
      me.blobPdf = doc.output("blob");

      // Para visualizar todas las paginas del pdf
      this.srcPdf = pdf.createLoadingTask(this.stringPdf);
      this.srcPdf.promise.then((pdf) => {
        this.numPages = pdf.numPages;
      });
    },
    /* Crear PDF asignacion */

    /* Crear PDF asignacion */
    crearPDFInvent() {
      let me = this;

      var columns = [
        { header: "Nº", dataKey: "item" },
        { header: "C.PATRIMONIAL", dataKey: "codbien" },
        { header: "DENOMINACIÓN", dataKey: "tbdescripcion" },
        { header: "MARCA", dataKey: "marca" },
        { header: "MODELO", dataKey: "modelo" },
        { header: "TIPO", dataKey: "tipo" },
        { header: "COLOR", dataKey: "color" },
        { header: "SERIE", dataKey: "serie" },
        { header: "DIMENSIONES", dataKey: "dimension" },
        { header: "OTROS", dataKey: "otras_caract" },
        { header: "SITUACIÓN", dataKey: "situacion" },
        { header: "ESTADO", dataKey: "estado" },
        { header: "OBSERVACIONES", dataKey: "observacion" },
      ];
      var body = [];

      for (var i = 0; i < me.reporteAsignacion.length; i++) {
        var f_estado = "";
        var f_situacion = "";
        for (var e = 0; e < me.cm.flg_estados.length; e++) {
          f_estado = f_estado + (me.reporteAsignacion[i].flg_estado == me.cm.flg_estados[e].value ? me.cm.flg_estados[e].text : "");
        }
        for (var e = 0; e < me.cm.flg_situaciones.length; e++) {
          f_situacion = f_situacion + (me.reporteAsignacion[i].situacion == me.cm.flg_situaciones[e].value ? me.cm.flg_situaciones[e].text : "");
        }

        body.push({
          item: i + 1,
          codbien: me.reporteAsignacion[i].codbien.length == 12 ? me.reporteAsignacion[i].codbien : me.reporteAsignacion[i].codbienint + "-" + me.reporteAsignacion[i].codinterno,
          tbdescripcion: me.reporteAsignacion[i].tbdescripcion,
          marca: me.reporteAsignacion[i].marca,
          modelo: me.reporteAsignacion[i].modelo,
          tipo: me.reporteAsignacion[i].tipo,
          color: me.reporteAsignacion[i].color,
          serie: me.reporteAsignacion[i].serie,
          dimension: me.reporteAsignacion[i].dimension,
          otras_caract: me.reporteAsignacion[i].otras_caract,
          situacion: f_situacion,
          estado: f_estado,
          observacion: me.reporteAsignacion[i].observacion,
        });
      }

      // Agregar Encabezado: titulo y logo
      const addHeaders = (doc) => {
        const pageCount = doc.internal.getNumberOfPages();
        for (i = 1; i <= pageCount; i++) {
          doc.setPage(i);
          doc.addImage(imgData, "JPEG", 20, 10, 11, 14); // Logo
          doc.setFont("helvetica", "bold");
          doc.setFontSize(12);
          doc.text("FORMATO DE FICHA DE LEVANTAMIENTO DE INFORMACIÓN", doc.internal.pageSize.width / 2, 15, { align: "center", });
          doc.setFontSize(11);
          doc.text("INVENTARIO PATRIMONIAL " + me.usuarioState.anoinv, doc.internal.pageSize.width / 2, 19, { align: "center", });
          // doc.text(
          //   "Mobiliario, Equipo y Maquinaria",
          //   doc.internal.pageSize.width / 2,
          //   15,
          //   {
          //     align: "center",
          //   }
          // );
          doc.setFontSize(10);
          doc.text(me.empresaState.nombre, doc.internal.pageSize.width / 2, 23, { align: "center" });
          if (me.ffaltante) { doc.text("BIENES FALTANTES", 250, 23); }

          doc.text("USUARIO:", 40, 30);
          doc.text("PERSONAL INVENTARIADOR:", 170, 30);
          doc.setFont("helvetica", "normal");
          doc.setFontSize(9);
          doc.text("Nombres y apellidos: " + me.nompersonal, 40, 35);
          //doc.text("Nº DNI: " + me.nrodocpersonal, 160, 30);
          //doc.text("Correo electrónico: ", 40, 35);
          doc.text("Local: " + me.nomlocal, 40, 40);
          doc.text("Area: " + me.nomarea, 40, 45);
          doc.text("Oficina: " + me.nomoficina, 40, 50);
          //doc.text("Dirección: " + me.direclocal, 40, 50);

          doc.text("Nombres y apellidos: " + me.usuarioState.nomape, 170, 35);
          doc.text(me.empresaState.detalle_inv + " " + me.empresaState.ano_inv, 170, 40);

          doc.setFontSize(6);
          doc.text("Pag. " + i + " de " + pageCount, 250, 13);
          doc.setFont("helvetica", "bold");
          doc.text("Fecha: " + me.formatFecha(me.fechaActual), 250, 17);
        }
      };
      // Agregar pie de pagina: numeración
      const addFooters = (doc) => {
        const pageCount = doc.internal.getNumberOfPages();
        doc.setFont("helvetica", "normal");
        doc.setFontSize(8);
        for (i = 1; i <= pageCount; i++) {
          doc.setPage(i);
          doc.setDrawColor(0, 0, 0);
          //doc.setLineWidth(0.5)

          var pageWidth = doc.internal.pageSize.width; // Ancho de la hoja

          // Firmas
          var line = 201;
          var linlong = 50;
          var linMargin = 20;

          var startL1 = (pageWidth - linlong * 2 - linMargin) / 2;
          var startL2 = startL1 + linlong + linMargin;
          var startL3 = startL2 + linlong + linMargin;

          doc.line(startL1, line, startL1 + linlong, line);
          doc.text(startL1 + 20, 205, "Usuario");

          doc.line(startL2, line, startL2 + linlong, line);
          doc.text(startL2 + 12, 205, "Personal Inventariador");

          // Consideraciones
          doc.setFont("helvetica", "bold");
          doc.text(10, 170, "CONSIDERACIONES:");
          doc.setFont("helvetica", "normal");
          doc.text(15, 175, "- El usuario declara haber mostrado todos los bienes muebles que se encuentran bajo su responsabilidad y no contar con más bienes muebles materia de inventario.");
          doc.text(15, 180, "- El usuario es responsable de la permanencia y conservación de cada uno de los bienes muebles descritos, recomendánse tomar las precauciones del caso para evitar sustracciones, deterioro, etc.");
          doc.text(15, 185, "- Cualquier necesidad de traslado del bien mueble patrimonial dentro o fuera del local de la Entidad u Organizacion de la Entidad, es previamnete comunicado al encargado de la OCP.");
        }
      };

      var imgData = "data:image/png;base64," + this.empresaState.logo;
      var doc = new jsPDF("l", "mm");

      doc.autoTable({
        theme: "grid",
        columns,
        body,
        margin: { top: 53, bottom: 45, left: 10, right: 10 },
        styles: { cellPadding: 1, overflow: "ellipsize", cellWidth: "wrap" },
        headStyles: {
          fillColor: [46, 79, 120],
          fontSize: 6,
          halign: "center",
          fontStyle: "bold",
        },
        bodyStyles: { textColor: 0, fontSize: 6 },
        columnStyles: {
          item: { cellWidth: 8, halign: "center" },
          codbien: { cellWidth: 20, halign: "center" },
          tbdescripcion: { cellWidth: 35 },
          marca: { cellWidth: 20 },
          modelo: { cellWidth: 20 },
          tipo: { cellWidth: 15 },
          color: { cellWidth: 20 },
          serie: { cellWidth: 20 },
          dimension: { cellWidth: 20 },
          otras_caract: { cellWidth: 30 },
          situacion: { cellWidth: 20 },
          estado: { cellWidth: 16 },
          observacion: { cellWidth: 33 },
        },
      });
      // 515.28 ancho hoja
      addHeaders(doc);
      addFooters(doc);
      // Exportar
      me.stringPdf = doc.output("datauristring");
      me.blobPdf = doc.output("blob");

      // Para visualizar todas las paginas del pdf
      this.srcPdf = pdf.createLoadingTask(this.stringPdf);
      this.srcPdf.promise.then((pdf) => {
        this.numPages = pdf.numPages;
      });
    },
    /* Crear PDF asignacion */

    /* Crear excel stock */
    crearXLSX() {
      let me = this;
      // On Click Excel download button
      var rows = [];

      for (var i = 0; i < me.reporteAsignacion.length; i++) {
        var f_estado = "";
        for (var e = 0; e < me.cm.flg_estados.length; e++) {
          f_estado = f_estado + (me.reporteAsignacion[i].flg_estado == me.cm.flg_estados[e].value ? me.cm.flg_estados[e].text : "");
        }
        rows.push({
          Codbien: me.reporteAsignacion[i].codbien.length == 12 ? me.reporteAsignacion[i].codbien : me.reporteAsignacion[i].codbienint + "-" + me.reporteAsignacion[i].codinterno,
          Descripción: this.reporteAsignacion[i].tbdescripcion,
          Locales: this.reporteAsignacion[i].nomlocales,
          Areas: this.reporteAsignacion[i].nomareas,
          Oficinas: this.reporteAsignacion[i].nomoficinas,
          Personal: this.reporteAsignacion[i].pnombres,
          Marca: this.reporteAsignacion[i].marca,
          Modelo: me.reporteAsignacion[i].modelo,
          Color: me.reporteAsignacion[i].color,
          Serie: me.reporteAsignacion[i].serie,
          Otros: me.reporteAsignacion[i].otras_caract,
          Estado: f_estado,
          Usuario: me.reporteAsignacion[i].nickname,
          "Fecha Actualizado": moment(me.reporteAsignacion[i].fec_act).format("DD/MM/YYYY HH:mm:ss"),
          Observaciones: me.reporteAsignacion[i].observacion,
        });
      }
      // export json to Worksheet of Excel
      // only array possible
      var Detalle = XLSX.utils.json_to_sheet(rows);

      // A workbook is the name given to an Excel file
      var wb = XLSX.utils.book_new(); // make Workbook of Excel

      // add Worksheet to Workbook
      // Workbook contains one or more worksheets
      XLSX.utils.book_append_sheet(wb, Detalle, "Bienes Asignados"); // sheetAName is name of Worksheet

      // export Excel file
      XLSX.writeFile(wb, "Reporte de Bienes Asignados.xlsx"); // name of the file is 'book.xlsx'
    },
    /* Fin Crear excel stock */
  },
};
</script>

