<template>
  <v-layout justify-center>
    <v-flex>
      <template>
        <v-img
          :src="require('../assets/web/otro.jpg')"
          height="180"
          class="white--text align-center"
        >
          <v-card-title align="center">
            <div class="text-h3 font-weight-light">CONTACTANOS</div>
          </v-card-title>
        </v-img>
      </template>

      <v-container class="mb-6 mt-6">
        <v-row>
          <v-col cols="12">
            <v-card flat>
              <v-card-title class="text-h4 justify-center">Detalles de Contacto</v-card-title>
              <v-card-subtitle class="justify-center">
                Si usted tiene alguna pregunta sobre nuestros servicios, puede enviarnos un correo electrónico o
                llámenos a través de los detalles a continuación. Le responderemos dentro de las 24 horas.
              </v-card-subtitle>
            </v-card>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12" sm="6">
            <v-card flat tile class="text-center">
              <v-card-title class="justify-center">
                <v-icon x-large>phone_in_talk</v-icon>Llámanos
              </v-card-title>
              <v-card-text>
                <strong>+51 930984158</strong>
              </v-card-text>
            </v-card>
          </v-col>
          <v-col cols="12" sm="6">
            <v-card flat tile class="text-center">
              <v-card-title class="justify-center">
                <v-icon x-large>schedule</v-icon>Horario de Atención
              </v-card-title>
              <v-card-text>
                <strong>Lunes a Viernes 8:30AM - 5:30PM</strong>
                <br />
                <strong>Sabados 8:30AM - 1:00PM</strong>
              </v-card-text>
            </v-card>
          </v-col>
          <v-col cols="12" sm="6">
            <v-card flat tile class="text-center">
              <v-card-title class="justify-center">
                <v-icon x-large>email</v-icon>Email
              </v-card-title>
              <v-card-text>
                <strong>efrain@urpisoft.pe</strong>
              </v-card-text>
            </v-card>
          </v-col>
          <v-col cols="12" sm="6">
            <v-card flat tile class="text-center">
              <v-card-title class="justify-center">
                <v-icon x-large>share</v-icon>Síguenos
              </v-card-title>
              <v-card-text>
                <v-btn icon>
                  <v-icon large>facebook</v-icon>
                </v-btn>
                <!-- <v-btn
                  icon
                  href="https://www.facebook.com/HP-Servicio-de-Toma-de-Inventario-y-Asesor%C3%ADa-Contable-102288222155767"
                  target="_blank"
                >
                  <v-icon large>facebook</v-icon>
                </v-btn>-->
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>
      </v-container>
    </v-flex>
  </v-layout>
</template>

<script>
export default {
  data() {
    return {
      items: [
        {
          src: require("../assets/web/carousel.jpg"),
        },
        {
          src: require("../assets/web/carousel2.jpeg"),
        },
        {
          src: require("../assets/web/carousel3.jpeg"),
        },
      ],

      slides: [
        "Inventario de Bienes",
        "Bien Patrimonial Estatal",
        "Bien Patrimonial Privado",
      ],

      avances: "",
      avanceBienes: [],
      totalBienes: [],

      avancesUsuarios: "",
      usuarios: [],
      numerobienes: [],
    };
  },
};
</script>
