<template>
  <v-container fluid fill-height>
    <v-layout alig-start>
      <v-flex>
        <!-- Inicio de barra de alerta -->
        <v-snackbar v-model="alerta.snackbar" :color="alerta.color" @click="alerta.snackbar = false">{{ alerta.text }}
        </v-snackbar>
        <!-- Fin inicio de barra de alerta -->

        <v-data-table :loading="loading" :headers="ventas" :items="reporteBien" :items-per-page="15" class="elevation-3"
          :search="buscar" dense>
          <template v-slot:top>
            <v-form v-model="isValid">
              <v-toolbar flat>
                <!-- Formulario busqueda de bienes activos -->
                <v-toolbar-title class="hidden-sm-and-down">REPORTE BIENES ACTIVOS</v-toolbar-title>
                <v-divider class="mx-4 hidden-sm-and-down" inset vertical></v-divider>
                <v-spacer />
                <!-- Formulario busqueda de movimiento caja -->
                <v-text-field class="text-xs-center" v-model.trim="buscar" append-icon="search" dense clearable
                  label="Búsqueda" single-line hide-details></v-text-field>
                <v-spacer />
                <v-checkbox color="internal" v-model="finterno" label="Cód. Interno" :disabled="desabilitar || !isValid"
                  @click="listar" dense hide-details></v-checkbox>
                <!-- Fin Formulario busqueda de movimiento caja -->
                <v-spacer />

                <v-btn x-small fab color="info" @click="listar" :loading="desabilitar" :disabled="desabilitar || !isValid"
                  class="mr-1">
                  <v-icon>update</v-icon>
                </v-btn>
                <v-btn fab x-small color="success" @click="crearXLSX()" :disabled="desabilitar || !isValid">
                  <v-icon>description</v-icon>
                </v-btn>
                <!-- Fin Formulario Busqueda de bienes activos-->
              </v-toolbar>
            </v-form>
          </template>
          <!-- Formatear detalle -->
          <template v-slot:item.codbien="{ item }">
            <template v-if='item.codbien.length == 12'>
              <span>{{ item.codbien.substr(0, 8) }}</span><strong>{{ item.codbien.substr(8, 12) }}</strong>
            </template>
            <template v-else-if="item.codbien.length == 8">
              <span>{{ item.codbienint + "-" + item.codinterno }}</span>
            </template>
          </template>

          <template v-slot:item.fec_act="{ item }">
            <span>{{ item.fec_act | formatearFecha }}</span>
          </template>
          <!-- Fin Formatear detalle -->
        </v-data-table>
      </v-flex>
    </v-layout>
  </v-container>
</template>

<script>
import axios from "axios";
import { mapState } from "vuex"; //Importamos para llamar a los elementos
import moment from "moment";
import XLSX from "xlsx";

export default {
  data() {
    return {
      reporteBien: [],
      dialog: false,
      ventas: [
        { text: "C. Patrimonial", value: "codbien", sortable: false, width: 120 },
        { text: "C/Int.", value: "codinterno", sortable: false },
        { text: "Tipo Bien", value: "tbdescripcion", sortable: false },
        { text: "Local", value: "nomlocales", sortable: false },
        { text: "Area", value: "nomareas", sortable: false },
        { text: "Oficina", value: "nomoficinas", sortable: false },
        { text: "Usuario", value: "nickname", sortable: false },
        { text: "FEC. ACTUALIZADO", value: "fec_act", sortable: false },
      ],

      buscar: "",

      desabilitarBuscar: true,
      desabilitar: false,
      isValid: true,
      loading: false,
      alerta: { snackbar: false, color: "", text: "" },

      finterno: false,
    };
  },

  computed: {
    ...mapState(["usuarioState", "empresaState"]), // Llamamos a state
  },

  created() {
    this.listar();
  },

  methods: {
    /* Listar bienes activos */
    listar() {
      let me = this;
      me.desabilitar = true;
      me.loading = true;

      axios.get("api/Bienes/ReporteBien", {
        params: {
          idempresa: me.usuarioState.idempresa,
          finterno: me.finterno
        },
      })
        .then(function (response) {
          me.reporteBien = response.data;
          me.desabilitar = false;
          me.loading = false;
          //console.log(me.reporteBien);
        })
        .catch((err) => {
          me.desabilitar = false;
          me.loading = false;
          me.alerta.snackbar = true;
          me.alerta.color = "error";
          me.alerta.text = "Ocurrio un error";
          //console.log(err.response);
        });
    },
    /* Fin Listar bienes activos */

    /* Crear excel */
    crearXLSX() {
      // On Click Excel download button
      var rows = [];

      for (var i = 0; i < this.reporteBien.length; i++) {
        rows.push({
          ITEM: i + 1,
          "RUC ENTIDAD": this.empresaState.ruc,
          "CÓDIGO PATRIMONIAL": this.reporteBien[i].codbien.length == 12 ? this.reporteBien[i].codbien : this.reporteBien[i].codbienint + "-" + this.reporteBien[i].codinterno,
          DESCRIPCIÓN: this.reporteBien[i].tbdescripcion,
          "ACTOS DE ADQUISICIÓN QUE GENERAN ALTA": this.reporteBien[i].flg_cond,
          "NRO DOC ADQUISICION": this.reporteBien[i].doc_alta,
          "FECHA ADQUISICIÓN": moment(this.reporteBien[i].fec_reg).format("DD/MM/YYYY"),
          "VALOR ADQUISICIÓN": this.reporteBien[i].valordq,
          "TIPO USO CUENTA": "DE USO " + this.usuarioState.tipoempresa,
          "TIPO CUENTA": this.reporteBien[i].tipo_cta,
          "NRO CUENTA CONTABLE": this.reporteBien[i].cuenta,
          DENOMINACIÓN: this.reporteBien[i].cdenominacion,
          ASEGURADO: this.reporteBien[i].asegurado == "S" ? "SI" : "NO",
          "ESTADO DE CONSERVACIÓN": this.reporteBien[i].flg_estado,
          CONDICIÓN: this.reporteBien[i].est_bien,
          MARCA: this.reporteBien[i].marca,
          MODELO: this.reporteBien[i].modelo,
          TIPO: this.reporteBien[i].tipo,
          COLOR: this.reporteBien[i].color,
          SERIE: this.reporteBien[i].serie,
          "DIMENSIÓN LONG-ALT-ANCHO": this.reporteBien[i].dimension,
          "PLACA/MATRICULAS": this.reporteBien[i].placa,
          "NRO MOTOR": this.reporteBien[i].nummotor,
          "NRO CHASIS": this.reporteBien[i].numchasis,
          "AÑO FABRICACIÓN": this.reporteBien[i].ano,

          CARROCERIA: this.reporteBien[i].carroceria,
          "NRO EJES": this.reporteBien[i].nro_ejes,
          COMBUSTIBLE: this.reporteBien[i].combustible,
          TRANSMISION: this.reporteBien[i].transmision,
          CILINDRADA: this.reporteBien[i].cilindrada,
          KILOMETRAJE: this.reporteBien[i].kilometraje,
          "TARJETA PROPIEDAD": this.reporteBien[i].tarjeta_propiedad,

          "OTRAS CARACTERISTICAS": this.reporteBien[i].otras_caract,
          OBSERVACIÓN: this.reporteBien[i].observacion,
          SITUACIÓN: this.reporteBien[i].situacion,
          "FECHA ACTUALIZADO": moment(this.reporteBien[i].fec_act).format("DD/MM/YYYY HH:mm:ss"),
          // FUERA DE FORMATO
          LOCALES: this.reporteBien[i].nomlocales,
          AREAS: this.reporteBien[i].nomareas,
          OFICINAS: this.reporteBien[i].nomoficinas,
          'NRO DOC.': this.reporteBien[i].pnrodoc,
          PERSONAL: this.reporteBien[i].pnombres,
          USUARIO: this.reporteBien[i].nickname,
          SEGUIMIENTO: this.reporteBien[i].seguimiento == true ? "Verificado" : "No verficado",
          "CÓDIGO INTERNO": this.reporteBien[i].codinterno,
        });
      }

      var Detalle = XLSX.utils.json_to_sheet(rows);
      if (!Detalle['!cols']) Detalle['!cols'] = [];
      Detalle['!cols'][3] = { wch: 40 };

      var wb = XLSX.utils.book_new();

      XLSX.utils.book_append_sheet(wb, Detalle, "Reporte Bienes " + (this.finterno ? "Internos" : "Patrimoniales"));

      // export Excel file
      XLSX.writeFile(wb, "Reporte Bienes " + (this.finterno ? "Internos" : "Patrimoniales") + ".xlsx");
    },
    /* Fin Crear excel */
  },
};
</script>

