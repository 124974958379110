import Vue from 'vue'
import Vuex from 'vuex'
import decode from 'jwt-decode'
import router from '../router'

import axios from "axios"; // empresaState

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    token: '',
    refreshtoken: '',
    usuarioState: '',
    empresaState: [], // empresaState array
  },
  mutations: {
    //decodificamos payload/token datos del usuarioState
    setToken(state, payload) {
      state.token = payload;
      if (payload === '') {
        state.usuarioState = ''
      } else {
        state.usuarioState = decode(payload)
        //router.push({ name: 'home' })
      }
    },
    setRefreshToken(state, refreshpayload) {
      state.refreshtoken = refreshpayload;
    },
 
    setEmpresas(state, empresaState) {
      state.empresaState = empresaState
    }
  },
  actions: {
    //Guardamos token, refreshtoken, guardaravatar, autologin y salir
    guardarToken({ commit }, payload) {
      localStorage.setItem("token", payload);
      commit("setToken", payload) //ejecutamos el payload=token
    },
    guardarRefreshToken({ commit }, refreshpayload) {
      localStorage.setItem("refreshtoken", refreshpayload);
      commit("setRefreshToken", refreshpayload)
    },
    // Verificamos si tiene token y si no salimos del sistema
    autoLogin({ commit }) {
      let token = localStorage.getItem("token")
      let refreshtoken = localStorage.getItem("refreshtoken")
      if (token) {
        commit("setToken", token)
        commit("setRefreshToken", refreshtoken)

        this.dispatch("cargarEmpresa"); // Llamamos a cargar empresa si se actualiza la pagina o usuario tiene token gracias a autologin
      } else {
        commit('setToken', '');
        commit("setRefreshToken", '')
      }
    },
    salir({ commit }) {
      commit("setToken", '')
      commit("setRefreshToken", '')
      localStorage.removeItem("token")
      localStorage.removeItem("refreshtoken")
      router.push({ name: 'Home' })
    },
    cargarEmpresa({ commit }) {
      axios
        .get("api/Empresas/Mostrar", {
          params: {
            idempresa: parseInt(this.state.usuarioState.idempresa),
          },
        })
        .then(response => response.data)
        .then(empresaState => {
          commit('setEmpresas', empresaState)
          //console.log(empresaState);
        })
        .catch(function (error) {
          //console.log(error);
        });
    }
  },
  getters: {
    estaActivo: state => !!state.token,
    esSuperadministrador: state => state.usuarioState.rol == "Superadministrador",
    esAdministrador: state => state.usuarioState.rol == "Administrador",
    esSupervisor: state => state.usuarioState.rol == "Supervisor",
    esVerificador: state => state.usuarioState.rol == "Verificador",
    esAlmacenero: state => state.usuarioState.rol == "Almacenero",

    // captador empresaState
    empresaState: state => {
      return state.empresaState;
    }
  }
  // modules: {
  // }
})
