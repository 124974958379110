<template>
  <v-layout justify-center>
    <v-flex>
      <v-carousel cycle hide-delimiter-background show-arrows-on-hover height="380">
        <v-carousel-item
          hide-delimiter-background
          v-for="(item,i) in items"
          :key="i"
          :src="item.src"
        >
          <v-row class="fill-height" align="center">
            <v-card-text align="center">
              <div class="text-h2 font-weight-bold white--text shadow-grey">{{ slides[i] }}</div>
              <div class="text-h4 font-weight-bold primary--text shadow-white">{{ slides2[i] }}</div>
            </v-card-text>
          </v-row>
        </v-carousel-item>
      </v-carousel>
      <v-item-group class="mt-10 mb-10">
        <v-container>
          <v-row>
            <!-- Servicio 1 -->
            <v-card class="mx-auto" max-width="344">
              <v-img :src="require('../assets/web/inventario.jpeg')" height="200px"></v-img>

              <v-card-title class="text-h5 justify-center">Inventario</v-card-title>
              <v-card-subtitle>Realizamos la toma de inventario de bienes patrimoniales para entidades públicas y empresas privadas, con un personal altamente</v-card-subtitle>
              <v-btn class="ml-3 mb-3" text color="primary" :to="{ name: 'Servicios' }">Leer más ...</v-btn>
            </v-card>

            <!-- Servicio 2 -->
            <v-card class="mx-auto" max-width="344">
              <v-img :src="require('../assets/web/implementa.jpg')" height="200px"></v-img>
              <v-card-title class="text-h5 justify-center">Implementación Inmediata</v-card-title>
              <v-card-subtitle>Permite crear la toma de inventario patrimonial de su entidad o empresa de manera rápida y sencilla</v-card-subtitle>
              <v-btn class="ml-3 mb-3" text color="primary" :to="{ name: 'Servicios' }">Leer más ...</v-btn>
            </v-card>

            <!-- Servicio 3 -->
            <v-card class="mx-auto" max-width="344">
              <v-img :src="require('../assets/web/internet.jpg')" height="200px"></v-img>
              <v-card-title class="text-h5 justify-center">Accesible por Internet</v-card-title>
              <v-card-subtitle>Administre y controle sus bienes patrimoniales desde cualquier lugar en el mundo.</v-card-subtitle>
              <v-btn class="ml-3 mb-3" text color="primary" :to="{ name: 'Servicios' }">Leer más ...</v-btn>
            </v-card>
          </v-row>
        </v-container>
      </v-item-group>
      <v-item-group class="mt-10 mb-10">
        <v-container>
          <v-row>
            <!-- Servicio 4 -->
            <v-card class="mx-auto" max-width="344">
              <v-img :src="require('../assets/web/sime.png')" height="200px"></v-img>

              <v-card-title class="text-h5 justify-center">Software</v-card-title>
              <v-card-subtitle>Ofrecemos un sistema de inventario de bienes patrimoniales para empresas estatales y privadas.</v-card-subtitle>
              <v-btn class="ml-3 mb-3" text color="primary" :to="{ name: 'Servicios' }">Leer más ...</v-btn>
            </v-card>

            <!-- Servicio 5 -->
            <v-card class="mx-auto" max-width="344">
              <v-img :src="require('../assets/web/privado.jpg')" height="200px"></v-img>
              <v-card-title class="text-h5 justify-center">Entidades Privadas</v-card-title>
              <v-card-subtitle>Administre sus activos fijos cumpliendo con la NIC 16 (Propiedad, Planta y Equipo) y la NIIF 16 (Arrendamiento).</v-card-subtitle>
              <v-btn class="ml-3 mb-3" text color="primary" :to="{ name: 'Servicios' }">Leer más ...</v-btn>
            </v-card>

            <!-- Servicio 6 -->
            <v-card class="mx-auto" max-width="344">
              <v-img :src="require('../assets/web/sbn.jpg')" height="200px"></v-img>
              <v-card-title class="text-h5 justify-center">Entidades Publicas</v-card-title>
              <v-card-subtitle>Cumplimiento con el Decreto Supremo N° 007-2008-VIVIENDA y su reglamento de la Ley General del Sistema Nacional de Bienes</v-card-subtitle>
              <v-btn class="ml-3 mb-3" text color="primary" :to="{ name: 'Servicios' }">Leer más ...</v-btn>
            </v-card>
          </v-row>
        </v-container>
      </v-item-group>
    </v-flex>
  </v-layout>
</template>

<script>
export default {
  data() {
    return {
      items: [
        {
          src: require("../assets/web/carousel.jpg"),
        },
        {
          src: require("../assets/web/carousel2.jpeg"),
        },
        {
          src: require("../assets/web/carousel3.jpeg"),
        },
      ],

      slides: [
        "Inventario de Bienes Patrimoniales",
        "Bien Patrimonial Público",
        "Bien Patrimonial Privado",
      ],
      slides2: [
        "Controle su patrimonio de manera facil",
        "Seguimiento a la toma de invetario anual",
        "Seguimiento a la toma de invetario",
      ],

      avances: "",
      avanceBienes: [],
      totalBienes: [],

      avancesUsuarios: "",
      usuarios: [],
      numerobienes: [],
    };
  },
};
</script>
<style>
.shadow-grey {
  text-shadow: 2px 2px 2px grey;
}
.shadow-white {
  text-shadow: 0.3px 0.3px 0.3px rgb(255, 255, 255);
}
</style>