<template>
  <v-container fluid fill-height>
    <v-layout alig-start>
      <v-flex>
        <!-- Inicio de barra de alerta -->
        <v-snackbar v-model="alerta.snackbar" :color="alerta.color" @click="alerta.snackbar = false">{{ alerta.text }}
        </v-snackbar>
        <!-- Fin inicio de barra de alerta -->

        <v-data-table :loading="loading" :headers="ventas" :items="reporteBien" :items-per-page="15" class="elevation-3"
          :search="buscar" dense>
          <template v-slot:top>
            <v-form v-model="isValid">
              <v-toolbar flat>
                <!-- Formulario busqueda baja de bienes-->
                <v-toolbar-title class="hidden-sm-and-down">REPORTE BAJA DE BIENES</v-toolbar-title>
                <v-divider class="mx-4 hidden-sm-and-down" inset vertical></v-divider>
                <v-spacer />
                <!-- Formulario busqueda de baja de bienes -->
                <v-text-field class="text-xs-center" v-model.trim="buscar" append-icon="search" dense clearable
                  label="Búsqueda" single-line hide-details></v-text-field>
                <!-- Fin Formulario busqueda de baja de bienes -->
                <v-spacer />

                <v-btn x-small fab color="info" @click="listar" :loading="desabilitar" :disabled="desabilitar || !isValid"
                  class="mr-1">
                  <v-icon>update</v-icon>
                </v-btn>
                <v-btn fab x-small color="success" @click="crearXLSX()" :disabled="desabilitar || !isValid">
                  <v-icon>description</v-icon>
                </v-btn>
                <!-- Fin Formulario Busqueda de venta usuario-->
              </v-toolbar>
            </v-form>
          </template>
          <!-- Formatear detalle -->
          <template v-slot:item.codbien="{ item }">
            <span>{{ item.codbien.substr(0, 8) }}</span><strong>{{ item.codbien.substr(8, 12) }}</strong>
          </template>

          <template v-slot:item.fec_act="{ item }">
            <span>{{ item.fec_act | formatearFecha }}</span>
          </template>
          <!-- Fin Formatear detalle -->
        </v-data-table>
      </v-flex>
    </v-layout>
  </v-container>
</template>

<script>
import axios from "axios";
import { mapState } from "vuex"; //Importamos para llamar a los elementos
import moment from "moment";
import XLSX from "xlsx";

export default {
  data() {
    return {
      reporteBien: [],
      dialog: false,
      ventas: [
        { text: "C. Patrimonial", value: "codbien", sortable: false },
        { text: "Tipo Bien", value: "tbdescripcion", sortable: false },
        { text: "Local", value: "nomlocales", sortable: false },
        { text: "Area", value: "nomareas", sortable: false },
        { text: "Oficina", value: "nomoficinas", sortable: false },
        { text: "Usuario", value: "nickname", sortable: false },
        { text: "FEC. ACTUALIZADO", value: "fec_act", sortable: false },
      ],

      buscar: "",

      desabilitarBuscar: true,
      desabilitar: false,
      isValid: true,
      loading: false,
      alerta: { snackbar: false, color: "", text: "" },
    };
  },

  computed: {
    ...mapState(["usuarioState", "empresaState"]), // Llamamos a state
  },

  created() {
    this.listar();
  },

  methods: {
    /* Listar baja de bienes */
    listar() {
      let me = this;
      me.desabilitar = true;
      me.loading = true;

      axios
        .get("api/Bienes/ReporteBajaBien", {
          params: {
            idempresa: me.usuarioState.idempresa,
          },
        })
        .then(function (response) {
          me.reporteBien = response.data;
          me.desabilitar = false;
          me.loading = false;
          //console.log(me.reporteBien);
        })
        .catch((err) => {
          me.desabilitar = false;
          me.loading = false;
          me.alerta.snackbar = true;
          me.alerta.color = "error";
          me.alerta.text = "Ocurrio un error";
          //console.log(err.response);
        });
    },
    /* Fin Listar baja de bienes */

    /* Crear excel stock */
    crearXLSX() {
      var rows = [];
      // ESTATAL
      for (var i = 0; i < this.reporteBien.length; i++) {
        var t_cta = "";
        var f_condicion = "";
        var f_estado = "";
        var f_causal = "";

        for (var t = 0; t < this.cm.tipo_ctas.length; t++) {
          t_cta = t_cta + (this.reporteBien[i].tipo_cta == this.cm.tipo_ctas[t].value ? this.cm.tipo_ctas[t].text : "");
        }

        for (var c = 0; c < this.cm.flg_conds.length; c++) {
          f_condicion = f_condicion + (this.reporteBien[i].flg_cond == this.cm.flg_conds[c].value ? this.cm.flg_conds[c].text : "");
        }

        for (var e = 0; e < this.cm.flg_estados.length; e++) {
          f_estado = f_estado + (this.reporteBien[i].flg_estado == this.cm.flg_estados[e].value ? this.cm.flg_estados[e].text : "");
        }

        for (var e = 0; e < this.cm.flg_causales.length; e++) {
          f_causal = f_causal + (this.reporteBien[i].flg_causal == this.cm.flg_causales[e].value ? this.cm.flg_causales[e].text : "");
        }

        rows.push({
          ITEM: i + 1,
          "RUC ENTIDAD": this.empresaState.ruc,
          "CÓDIGO PATRIMONIAL": this.reporteBien[i].codbien.length == 12 ? this.reporteBien[i].codbien : this.reporteBien[i].codbienint + "-" + this.reporteBien[i].codinterno,
          DESCRIPCIÓN: this.reporteBien[i].tbdescripcion,
          "ACTOS DE ADQUISICIÓN QUE GENERAN ALTA": this.reporteBien[i].flg_cond,
          "NRO DOC ADQUISICION": this.reporteBien[i].doc_alta,
          "FECHA ADQUISICIÓN": moment(this.reporteBien[i].fec_reg).format("DD/MM/YYYY"),
          "VALOR ADQUISICIÓN": this.reporteBien[i].valordq,
          "TIPO USO CUENTA": "DE USO " + this.usuarioState.tipoempresa,
          "TIPO CUENTA": this.reporteBien[i].tipo_cta,
          "NRO CUENTA CONTABLE": this.reporteBien[i].cuenta,
          DENOMINACIÓN: this.reporteBien[i].cdenominacion,
          ASEGURADO: this.reporteBien[i].asegurado == "S" ? "SI" : "NO",
          "ESTADO DE CONSERVACIÓN": this.reporteBien[i].flg_estado,
          CONDICIÓN: this.reporteBien[i].est_bien,
          MARCA: this.reporteBien[i].marca,
          MODELO: this.reporteBien[i].modelo,
          TIPO: this.reporteBien[i].tipo,
          COLOR: this.reporteBien[i].color,
          SERIE: this.reporteBien[i].serie,
          "DIMENSIÓN LONG-ALT-ANCHO": this.reporteBien[i].dimension,
          "PLACA/MATRICULAS": this.reporteBien[i].placa,
          "NRO MOTOR": this.reporteBien[i].nummotor,
          "NRO CHASIS": this.reporteBien[i].numchasis,
          "AÑO FABRICACIÓN": this.reporteBien[i].ano,

          CARROCERIA: this.reporteBien[i].carroceria,
          "NRO EJES": this.reporteBien[i].nro_ejes,
          COMBUSTIBLE: this.reporteBien[i].combustible,
          TRANSMISION: this.reporteBien[i].transmision,
          CILINDRADA: this.reporteBien[i].cilindrada,
          KILOMETRAJE: this.reporteBien[i].kilometraje,
          "TARJETA PROPIEDAD": this.reporteBien[i].tarjeta_propiedad,

          "OTRAS CARACTERISTICAS": this.reporteBien[i].otras_caract,
          OBSERVACIÓN: this.reporteBien[i].observacion,
          SITUACIÓN: this.reporteBien[i].situacion,
          "FECHA ACTUALIZADO": moment(this.reporteBien[i].fec_act).format("DD/MM/YYYY HH:mm:ss"),
          // Baja
          "CAUSAL BAJA": this.reporteBien[i].flg_causal,
          "DESCRIP. CAUSAL BAJA": f_causal,
          "NRO RESOLUCION BAJA": this.reporteBien[i].resol_baja,
          "FECHA BAJA": moment(this.reporteBien[i].fec_baja).format("DD/MM/YYYY"),
          // FUERA DE FORMATO
          LOCALES: this.reporteBien[i].nomlocales,
          AREAS: this.reporteBien[i].nomareas,
          OFICINAS: this.reporteBien[i].nomoficinas,
          'NRO DOC.': this.reporteBien[i].pnrodoc,
          PERSONAL: this.reporteBien[i].pnombres,
          USUARIO: this.reporteBien[i].nickname,
          SEGUIMIENTO: this.reporteBien[i].seguimiento == true ? "Verificado" : "No verficado",
          "CÓDIGO INTERNO": this.reporteBien[i].codinterno,
        });
      }
      // export json to Worksheet of Excel
      // only array possible
      var Detalle = XLSX.utils.json_to_sheet(rows);

      // A workbook is the name given to an Excel file
      var wb = XLSX.utils.book_new(); // make Workbook of Excel

      // add Worksheet to Workbook
      // Workbook contains one or more worksheets
      XLSX.utils.book_append_sheet(wb, Detalle, "Reporte Baja de Bienes"); // sheetAName is name of Worksheet

      // export Excel file
      XLSX.writeFile(wb, "Reporte Baja de Bienes.xlsx"); // name of the file is 'book.xlsx'
    },
    /* Fin Crear excel stock */
  },
};
</script>

