<template>
  <v-container fluid fill-height>
    <v-layout alig-start>
      <v-flex>
        <!-- Inicio de barra de alerta -->
        <v-snackbar v-model="alerta.snackbar" :color="alerta.color" @click="alerta.snackbar = false">{{ alerta.text
        }}</v-snackbar>
        <!-- Fin inicio de barra de alerta -->

        <!-- Listado de area y opciones de titulo, busqueda, nuevo, etc -->
        <v-data-table :loading="loading" :headers="cabArea" :items="ltAreas" :items-per-page="15" class="elevation-3"
          dense>
          <template v-slot:top>
            <v-toolbar flat>
              <v-toolbar-title class="hidden-sm-and-down">AREAS</v-toolbar-title>
              <v-divider class="mx-4 hidden-sm-and-down" inset vertical></v-divider>

              <!-- Impresion area -->
              <v-btn fab x-small color="accent" @click="crearPDF()" class="mr-1">
                <v-icon>print</v-icon>
              </v-btn>
              <!-- Fin Impresion area -->

              <v-spacer />
              <!-- Formulario busqueda de area -->
              <v-text-field class="text-xs-center" v-model.trim="buscar" @keyup="listar()" append-icon="search"
                v-uppercase dense label="Búsqueda" hide-details></v-text-field>
              <!-- Fin Formulario Busqueda de area -->
              <v-spacer />
              <!-- Modal crear y modificar area -->
              <v-dialog v-model="dialog" max-width="600px" persistent>
                <template v-slot:activator="{ on }">
                  <v-btn fab small dark color="primary" v-on="on">
                    <v-icon dark>add</v-icon>
                  </v-btn>
                </template>
                <v-card>
                  <v-toolbar dark dense class="primary">
                    <v-toolbar-title>{{ formTitle }}</v-toolbar-title>
                    <v-spacer />
                    <v-btn icon dark @click="guardar" :loading="desabilitar" :disabled="desabilitar || !isValid">
                      <v-icon>save</v-icon>
                    </v-btn>
                    <v-btn icon dark @click="close">
                      <v-icon>close</v-icon>
                    </v-btn>
                  </v-toolbar>
                  <v-card-text>
                    <v-form v-model="isValid">
                      <v-row class="mt-2">
                        <v-col cols="12" sm="12">
                          <v-autocomplete dense @keyup="selectLocal()" :search-input.sync="buscar_local"
                            v-model.trim="idlocal" :items="selectLocalArray" label="Local"
                            :rules="[v => !!v || 'Requerido']" clearable hide-details></v-autocomplete>
                        </v-col>
                        <v-col cols="12" sm="2">
                          <v-text-field dense v-model.trim="siglas" label="Siglas"
                            :rules="[v => !!v || 'requerido', Rules.sinespacio]" maxlength="5" v-uppercase
                            hide-details></v-text-field>
                        </v-col>
                        <v-col cols="12" sm="10">
                          <v-text-field dense v-model.trim="areas" label="Area"
                            :rules="[v => !!v || 'requerido', Rules.unespacio]" maxlength="40" v-uppercase
                            hide-details></v-text-field>
                        </v-col>

                        <!-- Mensaje error area -->
                        <v-col cols="12" v-if="mensajeArea != ''">
                          <v-alert text dense type="error">{{ mensajeArea }}</v-alert>
                        </v-col>
                        <!-- Mensaje error area -->
                      </v-row>
                    </v-form>
                  </v-card-text>
                </v-card>
              </v-dialog>
              <!-- Fin Modal crear y modificar area -->

              <!-- Modal listar oficina -->
              <v-dialog v-model="dialogOficina" max-width="450" persistent>
                <v-data-table :loading="loadingOficina" :headers="cabOficina" :items="ltOficinas" class="elevation-3"
                  :items-per-page="15" dense>
                  <template v-slot:top>
                    <v-toolbar dark dense class="primary">
                      <v-toolbar-title>Siglas Area: {{ siglas }}</v-toolbar-title>
                      <v-spacer />
                      <!-- Modal crear/modificar oficina -->
                      <v-dialog v-model="dialogOfi" max-width="400px" persistent>
                        <template v-slot:activator="{ on }">
                          <v-btn icon dark v-on="on" @click="close">
                            <v-icon>close</v-icon>
                          </v-btn>
                          <v-btn icon v-on="on">
                            <v-icon>add</v-icon>
                          </v-btn>
                        </template>
                        <v-card>
                          <v-toolbar dark dense class="primary">
                            <v-toolbar-title>{{ formTitleOfi }}</v-toolbar-title>
                            <v-spacer />
                            <v-btn icon dark @click="guardarOficina" :loading="desabilitar"
                              :disabled="desabilitar || !isValid">
                              <v-icon>save</v-icon>
                            </v-btn>
                            <v-btn icon dark @click="closeOfi">
                              <v-icon>close</v-icon>
                            </v-btn>
                          </v-toolbar>
                          <v-card-text>
                            <v-form v-model="isValid">
                              <v-row class="mt-2">
                                <v-col cols="12" sm="12">
                                  <v-text-field dense v-model.trim="oficinas" label="Oficina" maxlength="15"
                                    :rules="[v => !!v || 'requerido', v => (!!v && v.length >= 1 && v.length <= 15) || 'Oficina debe tener entre 1 a 15 caracteres']"
                                    v-uppercase hide-details></v-text-field>
                                </v-col>
                                <!-- Mensaje error asignacion -->
                                <v-col cols="12" v-if="mensajeOficina != ''">
                                  <v-alert text dense type="error">{{ mensajeOficina }}</v-alert>
                                </v-col>
                                <!-- Mensaje error asignacion -->
                              </v-row>
                            </v-form>
                          </v-card-text>
                        </v-card>
                      </v-dialog>
                      <!-- Fin Modal crear/modificar oficina -->

                      <!-- Modal activar, desactivar asignacion -->
                      <v-dialog v-model="adModalOfi" max-width="320">
                        <v-card>
                          <v-card-title class="headline" v-if="adAccionOfi == 1">¿Activar Oficina?</v-card-title>
                          <v-card-title class="headline" v-if="adAccionOfi == 2">¿Desactivar Oficina?</v-card-title>
                          <v-card-text>
                            Estás a punto de
                            <span v-if="adAccionOfi == 1">Activar</span>
                            <span v-if="adAccionOfi == 2">Desactivar</span>
                            la Oficina {{ adNombreOfi }}
                          </v-card-text>
                          <v-card-actions class="py-0 pb-4 justify-center">
                            <v-btn text small color="error" @click="activarDesactivarCerrarOfi">Cancelar</v-btn>
                            <v-btn v-if="adAccionOfi == 1" text small color="success" @click="activarOfi">Activar</v-btn>
                            <v-btn v-if="adAccionOfi == 2" text small color="warning"
                              @click="desactivarOfi">Desactivar</v-btn>
                          </v-card-actions>
                        </v-card>
                      </v-dialog>
                      <!-- Fin Modal activar, desactivar asignacion -->
                    </v-toolbar>
                  </template>
                  <!-- Opciones editar, eliminar asignacion -->
                  <template v-slot:item.condicion="{ item }">
                    <template v-if="item.condicion">
                      <v-btn text small color="success" @click="activarDesactivarMostrarOfi(2, item)">Activo</v-btn>
                    </template>
                    <template v-else>
                      <v-btn text small color="warning" @click="activarDesactivarMostrarOfi(1, item)">Inactivo</v-btn>
                    </template>
                  </template>
                  <template v-slot:item.opciones="{ item }">
                    <v-btn small icon>
                      <v-icon color="info" @click="editOfiItem(item)">edit</v-icon>
                    </v-btn>
                    <v-btn small icon>
                      <v-icon color="error" @click="deleteItemOfi(item)">delete_forever</v-icon>
                    </v-btn>
                  </template>
                  <!-- Fin Opciones editar, eliminar asignacion -->
                </v-data-table>
              </v-dialog>
              <!-- Fin Modal listar oficina -->

              <!-- Modal activar, desactivar area -->
              <v-dialog v-model="adModal" max-width="300">
                <v-card>
                  <v-card-title class="headline justify-center" v-if="adAccion == 1">¿Activar Area?</v-card-title>
                  <v-card-title class="headline justify-center" v-if="adAccion == 2">¿Desactivar Area?</v-card-title>
                  <v-card-text>
                    Estás a punto de
                    <span v-if="adAccion == 1">Activar</span>
                    <span v-if="adAccion == 2">Desactivar</span>
                    el area {{ adNombre }}
                  </v-card-text>
                  <v-card-actions class="py-0 pb-4 justify-center">
                    <v-btn text small color="error" @click="activarDesactivarCerrar">Cancelar</v-btn>
                    <v-btn v-if="adAccion == 1" text small color="success" @click="activar">Activar</v-btn>
                    <v-btn v-if="adAccion == 2" text small color="warning" @click="desactivar">Desactivar</v-btn>
                  </v-card-actions>
                </v-card>
              </v-dialog>
              <!-- Fin Modal activar, desactivar area -->
            </v-toolbar>
          </template>
          <!-- Opciones editar, eliminar area -->

          <template v-slot:item.oficinavent="{ item }">
            <template>
              <v-btn text small color="info" @click="mostrarListaOfiItem(item)">Oficinas</v-btn>
            </template>
          </template>

          <template v-slot:item.condicion="{ item }">
            <template v-if="item.condicion">
              <v-btn text small color="success" @click="activarDesactivarMostrar(2, item)">Activo</v-btn>
            </template>
            <template v-else>
              <v-btn text small color="warning" @click="activarDesactivarMostrar(1, item)">Inactivo</v-btn>
            </template>
          </template>

          <template v-slot:item.opciones="{ item }">
            <v-btn small icon>
              <v-icon color="info" @click="editItem(item)">edit</v-icon>
            </v-btn>
            <v-btn small icon>
              <v-icon color="error" @click="deleteItem(item)">delete_forever</v-icon>
            </v-btn>
          </template>
          <!-- Fin Opciones editar, eliminar area -->
        </v-data-table>
        <!-- Fin Listado de area y opciones de titulo, busqueda, nuevo, etc -->
      </v-flex>
    </v-layout>
  </v-container>
</template>

<script>
import axios from "axios";
import jsPDF from "jspdf";
import { mapState } from "vuex";

export default {
  data() {
    return {
      ltAreas: [],
      ltOficinas: [],
      dialog: false,
      dialogOficina: 0,
      dialogOfi: 0,

      cabArea: [
        //{ text: "CdAr", value: "idarea" },
        { text: "Local", value: "nomlocal", sortable: false },
        { text: "Siglas", value: "siglas", sortable: false },
        { text: "Areas", value: "areas", sortable: false },
        { text: "Oficinas", value: "oficinavent", sortable: false },
        { text: "Condición", align: "center", value: "condicion", sortable: false },
        { text: "OPC.", value: "opciones", sortable: false },
      ],

      cabOficina: [
        //{ text: "Area", value: "oficinas", sortable: false },
        { text: "Oficina", value: "oficinas", sortable: false },
        { text: "Condición", align: "center", value: "condicion", sortable: false },
        { text: "OPC.", value: "opciones", sortable: false },
      ],

      buscar: "",
      editedIndex: -1,
      editedIndexOfi: -1,
      idarea: "",
      idlocal: "",
      siglas: "",
      areas: "",
      locales: "",

      selectLocalArray: [],
      buscar_local: "",

      adModal: 0,
      adAccion: 0,
      adNombre: "",
      adId: "",
      mensajeArea: "",

      // Oficina
      idoficina: "",
      oficinas: "",
      adModalOfi: 0,
      adAccionOfi: 0,
      adNombreOfi: "",
      adIdOfi: "",
      mensajeOficina: "",
      loadingOficina: "",
      // Fin Oficina

      desabilitar: false,
      isValid: true,
      alerta: { snackbar: false, color: "", text: "" },

      Rules: {
        unespacio: (v) =>
          (v || "").indexOf("  ") < 0 || "No se permite mas de 1 espacio",
        sinespacio: (v) =>
          (v || "").indexOf(" ") < 0 || "No se permite espacios",
      },
    };
  },

  computed: {
    formTitle() {
      return this.editedIndex === -1 ? "Nueva Area" : "Actualizar Area";
    },

    formTitleOfi() {
      return this.editedIndexOfi === -1
        ? "Nueva Oficina"
        : "Actualizar Oficina";
    },
    ...mapState(["usuarioState", "empresaState"]),
  },

  watch: {
    dialog(val) {
      val || this.close();
    },
  },

  created() {
    this.listar();
    this.selectLocal();
  },

  methods: {
    /* Listar area */
    listar() {
      let me = this;
      me.loading = true;

      axios
        .get("api/Areas/ListarAreaEmpresaFiltro", {
          params: {
            idempresa: me.usuarioState.idempresa,
            buscar: me.buscar == "" ? "-" : me.buscar,
          },
        })
        .then(function (response) {
          me.ltAreas = response.data;
          me.loading = false;
        })
        .catch((err) => {
          //console.log(err.response);
        });
    },
    /* Fin Listar area */

    /* Select local, personal */
    selectLocal(id) {
      let me = this;
      var localArray = [];

      if (id) {
        axios
          .get("api/Locales/SelectLocal?idlocal=" + (id == "" ? "-" : id))
          .then(function (response) {
            localArray = response.data;
            localArray.map(function (x) {
              me.selectLocalArray.push({
                text: x.locales,
                value: x.idlocal,
              });
            });
          })
          .catch(function (error) {
            //console.log(url);
          });
      } else {
        axios
          .get("api/Locales/SelectLocalesFiltro", {
            params: {
              idempresa: me.usuarioState.idempresa,
              buscar: me.buscar_local == "" ? "-" : me.buscar_local,
            },
          })
          .then(function (response) {
            localArray = response.data;
            localArray.map(function (x) {
              me.selectLocalArray.push({
                text: x.locales,
                value: x.idlocal,
              });
            });
          })
          .catch((err) => {
            //console.log(err.response);
          });
      }
    },

    /* Editar y guardar area */
    guardar() {
      let me = this;
      me.desabilitar = true;

      if (me.editedIndex > -1) {
        //Código para editar
        axios
          .put("api/Areas/Actualizar", {
            idarea: me.idarea,
            idlocal: me.idlocal,
            siglas: me.siglas,
            areas: me.areas,
            idempresa: me.usuarioState.idempresa,
            locales: me.buscar_local,
          })
          .then(function (response) {
            me.desabilitar = false;
            me.alerta.snackbar = true;
            me.alerta.color = "success";
            me.alerta.text = response.data;
            me.close();
            me.listar();
            me.limpiar();
          })
          .catch(function (error) {
            me.desabilitar = false;
            me.mensajeArea = error.response.data;
            me.limpiarAlertaError();
          });
      } else {
        //Código para guardar
        axios
          .post("api/Areas/Crear", {
            idlocal: me.idlocal,
            siglas: me.siglas,
            areas: me.areas,
            idempresa: me.usuarioState.idempresa,
            locales: me.buscar_local,
          })
          .then(function (response) {
            me.desabilitar = false;
            me.alerta.snackbar = true;
            me.alerta.color = "success";
            me.alerta.text = response.data;
            me.close();
            me.listar();
            me.limpiar();
          })
          .catch(function (error) {
            me.desabilitar = false;
            me.mensajeArea = error.response.data;
            me.limpiarAlertaError();
          });
      }
    },
    /* Fin Editar y guardar area */

    editItem(item) {
      this.idarea = item.idarea;
      this.idlocal = item.idlocal;
      this.selectLocal(item.idlocal);
      this.siglas = item.siglas;
      this.areas = item.areas;
      this.editedIndex = 1;
      this.dialog = true;
    },

    close() {
      this.dialog = false;
      this.dialogOficina = 0;
      this.limpiar();
    },

    limpiar() {
      this.idarea = "";
      this.idlocal = "";
      this.siglas = "";
      this.areas = ""; // Validar en backend
      this.locales = ""; // Validar en backend
      this.ltOficinas = [];
      this.mensajeArea = "";
      this.editedIndex = -1;
    },

    desactivar() {
      let me = this;

      axios
        .put("api/Areas/Desactivar/" + this.adId, {})
        .then(function (response) {
          me.adModal = 0;
          me.adAccion = 0;
          me.adNombre = "";
          me.adId = "";
          me.listar();
        })
        .catch(function (error) {
          //console.log(error);
        });
    },

    activarDesactivarMostrar(accion, item) {
      this.adModal = 1;
      this.adNombre = item.areas;
      this.adId = item.idarea;
      if (accion == 1) {
        this.adAccion = 1;
      } else if (accion == 2) {
        this.adAccion = 2;
      } else {
        this.adModal = 0;
      }
    },

    activarDesactivarCerrar() {
      this.adModal = 0;
    },

    activar() {
      let me = this;
      axios
        .put("api/Areas/Activar/" + this.adId, {})
        .then(function (response) {
          me.adModal = 0;
          me.adAccion = 0;
          me.adNombre = "";
          me.adId = "";
          me.listar();
        })
        .catch(function (error) {
          //console.log(error);
        });
    },

    deleteItem(item) {
      const respta = confirm(
        "¿Estás seguro de que deseas eliminar el area " + item.areas + "?"
      );
      if (respta) {
        let me = this;
        axios
          .delete("api/Areas/Eliminar/" + item.idarea)
          .then((response) => {
            me.alerta.snackbar = true;
            me.alerta.color = "success";
            me.alerta.text = response.data;
            this.listar();
          })
          .catch(function (error) {
            me.alerta.snackbar = true;
            me.alerta.color = "error";
            me.alerta.text = error.response.data;
          });
      }
    },

    limpiarAlertaError() {
      setTimeout(() => {
        this.mensajeArea = "";
        this.mensajeOficina = "";
      }, 3000);
    },

    /* Listar oficina */
    listarOficina(id) {
      let me = this;
      me.loadingOficina = true;

      // Busqueda para administrador
      axios
        .get("api/Oficinas/ListarOficinaEmpresaFiltro", {
          params: {
            idempresa: me.usuarioState.idempresa,
            idarea: id,
          },
        })
        .then(function (response) {
          me.ltOficinas = response.data;
          me.loadingOficina = false;
        })
        .catch((err) => {
          //console.log(err.response);
        });
    },
    /* Fin Listar oficina */

    /* Editar y guardar oficina */
    guardarOficina() {
      let me = this;
      me.desabilitar = true;

      if (me.editedIndexOfi > -1) {
        //Código para editar
        axios
          .put("api/oficinas/Actualizar", {
            idempresa: me.usuarioState.idempresa,
            idoficina: me.idoficina,
            idarea: me.idarea,
            oficinas: me.oficinas,
            areas: me.areas,
            locales: me.locales,
          })
          .then(function (response) {
            me.desabilitar = false;
            me.alerta.snackbar = true;
            me.alerta.color = "success";
            me.alerta.text = response.data;
            me.closeOfi();
            me.listarOficina(me.idarea);
            me.limpiarOfi();
          })
          .catch(function (error) {
            me.desabilitar = false;
            me.mensajeOficina = error.response.data;
            me.limpiarAlertaError();
          });
      } else {
        //Código para guardar
        axios
          .post("api/oficinas/Crear", {
            idempresa: me.usuarioState.idempresa,
            idarea: me.idarea,
            oficinas: me.oficinas,
            areas: me.areas,
            locales: me.locales,
          })
          .then(function (response) {
            me.desabilitar = false;
            me.alerta.snackbar = true;
            me.alerta.color = "success";
            me.alerta.text = response.data;
            me.closeOfi();
            me.listarOficina(me.idarea);
            me.limpiarOfi();
          })
          .catch(function (error) {
            me.desabilitar = false;
            me.mensajeOficina = error.response.data;
            me.limpiarAlertaError();
          });
      }
    },
    /* Fin Editar y guardar oficina */

    closeOfi() {
      this.dialogOfi = 0;
      this.idoficina = ""; // no mover
      this.oficinas = ""; // no mover
      this.editedIndexOfi = -1;
    },

    desactivarOfi() {
      let me = this;

      axios
        .put("api/oficinas/Desactivar/" + this.adIdOfi, {})
        .then(function (response) {
          me.adModalOfi = 0;
          me.adAccionOfi = 0;
          me.adNombreOfi = "";
          me.adIdOfi = "";
          me.listarOficina(me.idarea);
        })
        .catch(function (error) {
          //console.log(error);
        });
    },

    activarDesactivarMostrarOfi(accion, item) {
      this.adModalOfi = 1;
      this.adNombreOfi = item.oficinas;
      this.adIdOfi = item.idoficina;
      if (accion == 1) {
        this.adAccionOfi = 1;
      } else if (accion == 2) {
        this.adAccionOfi = 2;
      } else {
        this.adModalOfi = 0;
      }
    },

    activarDesactivarCerrarOfi() {
      this.adModalOfi = 0;
    },

    activarOfi() {
      let me = this;
      axios
        .put("api/Oficinas/Activar/" + this.adIdOfi, {})
        .then(function (response) {
          me.adModalOfi = 0;
          me.adAccionOfi = 0;
          me.adNombreOfi = "";
          me.adIdOfi = "";
          me.listarOficina(me.idarea);
        })
        .catch(function (error) {
          //console.log(error);
        });
    },

    mostrarListaOfiItem(item) {
      this.idarea = item.idarea;
      this.siglas = item.siglas;
      this.areas = item.areas;
      this.locales = item.nomlocal;

      this.listarOficina(item.idarea);
      this.dialogOficina = 1;
    },

    editOfiItem(item) {
      this.idoficina = item.idoficina;
      this.oficinas = item.oficinas;

      this.dialogOfi = 1;
      this.editedIndexOfi = 1;
    },

    limpiarOfi() {
      this.idoficina = "";
      this.oficinas = "";

      this.editedIndexOfi = -1;
      this.mensajeOficina = "";
    },

    deleteItemOfi(item) {
      const respta = confirm(
        "¿Estás seguro de que deseas eliminar la oficina " + item.oficinas + "?"
      );
      if (respta) {
        let me = this;
        axios
          .delete("api/Oficinas/Eliminar/" + item.idoficina)
          .then((response) => {
            me.alerta.snackbar = true;
            me.alerta.color = "success";
            me.alerta.text = response.data;
            me.listarOficina(me.idarea);
          })
          .catch(function (error) {
            me.alerta.snackbar = true;
            me.alerta.color = "error";
            me.alerta.text = error.response.data;
          });
      }
    },

    crearPDF() {
      var columns = [
        { header: "Local", dataKey: "nomlocal" },
        { header: "Siglas", dataKey: "siglas" },
        { header: "Areas", dataKey: "areas" },
        { header: "Condicion", dataKey: "condicion" },
      ];
      var body = [];

      this.ltAreas.map(function (x) {
        body.push({
          nomlocal: x.nomlocal,
          siglas: x.siglas,
          areas: x.areas,
          condicion: x.condicion == 1 ? "A" : "I",
        });
      });

      // Agregar Encabezado: titulo y logo
      const addHeaders = (doc) => {
        const pageCount = doc.internal.getNumberOfPages();

        doc.setFont("helvetica", "bold");
        doc.setFontSize(10);
        for (var i = 1; i <= pageCount; i++) {
          doc.setPage(i);
          doc.text("LISTADO DE AREAS", doc.internal.pageSize.width / 2, 45, {
            align: "center",
          });
        }
      };

      const addFooters = (doc) => {
        const pageCount = doc.internal.getNumberOfPages();

        doc.setFont("helvetica", "italic");
        doc.setFontSize(7);
        for (var i = 1; i <= pageCount; i++) {
          doc.setPage(i);
          doc.text(
            "Pagina " + String(i) + " de " + String(pageCount),
            doc.internal.pageSize.width / 2,
            820,
            {
              align: "center",
            }
          );
        }
      };

      var imgData = "data:image/png;base64," + this.empresaState.logo;
      var doc = new jsPDF("p", "pt");

      doc.addImage(imgData, "JPEG", 40, 17, 35, 40);
      doc.autoTable({
        columns,
        body,
        margin: { top: 70 },
        headStyles: {
          fillColor: [46, 78, 121],
          fontSize: 7,
          halign: "center",
        },
        bodyStyles: { textColor: 0, fontSize: 8 },
        columnStyles: {
          nomlocal: { cellWidth: 230 },
          siglas: { cellWidth: 40 },
          areas: { cellWidth: "auto" },
          condicion: { cellWidth: 45.28, halign: "center" },
        },
      });
      addHeaders(doc);
      addFooters(doc);
      doc.save("Areas.pdf");
    },
  },
};
</script>
