<template>
  <HelloNosotros />
</template>

<script>
  import HelloNosotros from '../components/HelloNosotros'

  export default {
    components: {
      HelloNosotros,
    },
  }
</script>