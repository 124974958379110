import Vue from 'vue'
import VueRouter from 'vue-router'
import decode from 'jwt-decode'
//Componentes
import Home from '../views/Home.vue'
import Dashboard from '../views/Dashboard.vue'
import Nosotros from '../views/Nosotros.vue'
import Servicios from '../views/Servicios.vue'
import Contacto from '../views/Contacto.vue'
import Bien from '../components/Bien.vue'
import BajaBien from '../components/BajaBien.vue'
import TrasladoBien from '../components/TrasladoBien.vue'
import TipoBien from '../components/TipoBien.vue'
import Personal from '../components/Personal.vue'
import ReporteAsignacion from '../components/ReporteAsignacion.vue'
import ReporteUsuario from '../components/ReporteUsuario.vue'
import ReporteBien from '../components/ReporteBien.vue'
import ReporteBajaBien from '../components/ReporteBajaBien.vue'
import Usuario from '../components/Usuario.vue'
import Empresa from '../components/Empresa.vue'
import Local from '../components/Local.vue'
import Area from '../components/Area.vue'
import Orden from '../components/Orden.vue'
import Login from '../components/Login.vue'


Vue.use(VueRouter)

const routes = [
  // Login
  {
    path: '*',
    redirect: '/home',
  },
  {
    path: '/',
    redirect: '/home',
  },
  {
    path: '/home',
    name: 'Home',
    component: Home,
  },
  {
    path: '/nosotros',
    name: 'Nosotros',
    component: Nosotros,
  },
  {
    path: '/servicios',
    name: 'Servicios',
    component: Servicios,
  },
  {
    path: '/contacto',
    name: 'Contacto',
    component: Contacto,
  },
  {
    path: '/login',
    name: 'Login',
    component: Login,
  },
  // Fin Login

  // Privado
  {
    path: '/dashboard',
    name: 'Dashboard',
    component: Dashboard,
    meta: {
      superadministradorAuth: true,
      administradorAuth: true,
      supervisorAuth: true,
      verificadorAuth: true,
      almaceneroAuth: true,
    }
  },
  {
    path: '/bien',
    name: 'Bien',
    component: Bien,
    meta: {
      superadministradorAuth: true,
      administradorAuth: true,
      supervisorAuth: true,
      verificadorAuth: true
    }
  },
  {
    path: '/bajabien',
    name: 'BajaBien',
    component: BajaBien,
    meta: {
      superadministradorAuth: true,
      administradorAuth: true,
      supervisorAuth: true
    }
  },
  {
    path: '/trasladobien',
    name: 'TrasladoBien',
    component: TrasladoBien,
    meta: {
      superadministradorAuth: true,
      administradorAuth: true,
      supervisorAuth: true,
      verificadorAuth: true
    }
  },
  {
    path: '/tipobien',
    name: 'TipoBien',
    component: TipoBien,
    meta: {
      superadministradorAuth: true,
      administradorAuth: true
    }
  },
  {
    path: '/personal',
    name: 'Personal',
    component: Personal,
    meta: {
      superadministradorAuth: true,
      administradorAuth: true,
      supervisorAuth: true,
      verificadorAuth: true
    }
  },
  {
    path: '/reporteasignacion',
    name: 'ReporteAsignacion',
    component: ReporteAsignacion,
    meta: {
      superadministradorAuth: true,
      administradorAuth: true,
      supervisorAuth: true,
      verificadorAuth: true
    }
  },
  {
    path: '/reporteusuario',
    name: 'ReporteUsuario',
    component: ReporteUsuario,
    meta: {
      superadministradorAuth: true,
      administradorAuth: true,
      supervisorAuth: true,
      verificadorAuth: true
    }
  },
  {
    path: '/reportebien',
    name: 'ReporteBien',
    component: ReporteBien,
    meta: {
      superadministradorAuth: true,
      administradorAuth: true,
      supervisorAuth: true,
    }
  },
  {
    path: '/reportebajabien',
    name: 'ReporteBajaBien',
    component: ReporteBajaBien,
    meta: {
      superadministradorAuth: true,
      administradorAuth: true,
      supervisorAuth: true,
    }
  },
  {
    path: '/usuario',
    name: 'Usuario',
    component: Usuario,
    meta: {
      superadministradorAuth: true,
      administradorAuth: true
    }
  },
  {
    path: '/empresa',
    name: 'Empresa',
    component: Empresa,
    meta: {
      superadministradorAuth: true,
      administradorAuth: true
    }
  },
  {
    path: '/local',
    name: 'Local',
    component: Local,
    meta: {
      superadministradorAuth: true,
      administradorAuth: true,
      supervisorAuth: true,
      //verificadorAuth: true
    }
  },
  {
    path: '/area',
    name: 'Area',
    component: Area,
    meta: {
      superadministradorAuth: true,
      administradorAuth: true,
      supervisorAuth: true,
      //verificadorAuth: true
    }
  },
  {
    path: '/orden',
    name: 'Orden',
    component: Orden,
    meta: {
      superadministradorAuth: true,
      administradorAuth: true,
      supervisorAuth: true,
      almaceneroAuth: true,
    }
  },
]

var router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

router.beforeEach((to, from, next) => {

  let token = localStorage.getItem("token")
  let usuario = token == null ? "" : decode(token)

  let ruta = false;
  let expr = usuario.rol;
  switch (expr) {
    case 'Superadministrador':
      ruta = to.matched.some(record => record.meta.superadministradorAuth)
      break;
    case 'Administrador':
      ruta = to.matched.some(record => record.meta.administradorAuth)
      break;
    case 'Supervisor':
      ruta = to.matched.some(record => record.meta.supervisorAuth)
      break;
    case 'Verificador':
      ruta = to.matched.some(record => record.meta.verificadorAuth)
      break;
    case 'Almacenero':
      ruta = to.matched.some(record => record.meta.almaceneroAuth)
      break;
  }

  if (ruta && !token) {
    next('home')
  } else if (!ruta && token) {
    next('dashboard')
  } else {
    next();
  }

})

export default router
